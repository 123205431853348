import axios from 'axios';
import { invalidateAreaSelection, requestAuthSecret } from '@actions/map-actions';
import {
  FILTERS_FETCH_SUCCESS,
  DATE_RANGE_FETCH_SUCCESS,
  TOGGLE_FILTER,
  SELECT_DATE_RANGE,
  SET_CUSTOM_DATE_RANGE,
  SET_INITIAL_URL_FILTER,
  CLEAR_INITIAL_URL_FILTER
} from '@constants/action-types';
import { getApiRequestUrl } from '@constants/endpoints';
import { getDevSecretParams } from '@selectors/map';
import { layerDataConfig } from '@utils/map-data-config';
import { getMapIcon } from '@utils/map-utils';

const fetchFilterStructureSuccess = payload => ({
  type: FILTERS_FETCH_SUCCESS,
  payload
});

const processMapIcons = filters => Promise.all(filters.map(filter => Promise.all([
  getMapIcon(filter.uiStyle.marker).then(
    icon => (filter.mapIcon = icon)
  ),
  getMapIcon(filter.uiStyle.activeMarker, true).then(
    icon => (filter.activeMapIcon = icon)
  ),
  filter.children && processMapIcons(filter.children)
])));

export const fetchFilterStructure = () => (dispatch, getState) => {
  axios.get(
    getApiRequestUrl('layer'),
    {...layerDataConfig, params: getDevSecretParams(getState())}
  ).then(({ data }) => {
    processMapIcons(data).then(
      () => {
        dispatch(fetchFilterStructureSuccess(data));
      }
    );
  })
    .catch(error => {
      if (error.response.status === 403) {
        dispatch(requestAuthSecret());
      } else {
        throw (error);
      }
    });
};

const fetchDateRangesSuccess = payload => ({
  type: DATE_RANGE_FETCH_SUCCESS,
  payload
});

export const fetchDateRanges = () => (dispatch, getState) => {
  axios.get(
    getApiRequestUrl('daterange'),
    {...layerDataConfig, params: getDevSecretParams(getState())}
  ).then(({ data }) => {
    dispatch(fetchDateRangesSuccess(data));
  });
};

const toggleFilterAction = id => ({
  type: TOGGLE_FILTER,
  id
});

export const toggleFilter = id => (dispatch) => {
  dispatch(invalidateAreaSelection());
  dispatch(toggleFilterAction(id));
};

const selectDateRangeAction = index => ({
  type: SELECT_DATE_RANGE,
  index
});

const setCustomDateRangeAction = (start, end) => ({
  type: SET_CUSTOM_DATE_RANGE,
  start,
  end
});

export const selectDateRange = index => (dispatch) => {
  dispatch(selectDateRangeAction(index));
};

export const setCustomDateRange = (start, end) => (dispatch) => {
  dispatch(setCustomDateRangeAction(start, end));
};

export const setInitialUrlFilter = () => dispatch => dispatch({type: SET_INITIAL_URL_FILTER});
export const clearInitialUrlFilter = () => dispatch => dispatch({ type: CLEAR_INITIAL_URL_FILTER });
