/* global google */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-depth */
/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { selectArea } from '@actions/map-actions';
import { PROXIMITY_CLUSTER_MAX_ZOOM } from '@constants/google-maps';
import { getStyle } from '@selectors/site';
import { getDrawingActive } from '@selectors/map';
import { useGoogleMap, OverlayView } from '@react-google-maps/api';
import { remToPx } from '@utils/dom-utils';
import { hexToRgb } from '@utils/cluster-utils';
import { googleMapsBoundsToBbox, bboxToGoogleMapsBounds } from '@utils/geometry-utils';
import styles from './overlap-count-marker.scss';

const OverlapCountMarker = ({
  cluster,
  selectionCount
}) => {
  const dispatch = useDispatch();
  const style = useSelector(getStyle);
  const drawingActive = useSelector(getDrawingActive);
  const map = useGoogleMap();

  const onClick = () => {
    if (cluster.overlaps.length > 1) {
      const mapBbox = googleMapsBoundsToBbox(map.getBounds());
      const mapWidth = mapBbox[2] - mapBbox[0];
      const mapHeight = mapBbox[3] - mapBbox[1];
      const clusterWidth = cluster.bbox[2] - cluster.bbox[0];
      const clusterHeight = cluster.bbox[3] - cluster.bbox[1];
      // Compare relative sizes of bboxes to ensure google maps uses an
      // eased transition instead of snapping to position
      if (clusterWidth < (0.2 * mapWidth) && clusterHeight < (0.2 * mapHeight) && map.getZoom() < 21) {
        const intermediateBbox = [
          (mapBbox[0] * 0.2 + cluster.bbox[0] * 0.8),
          (mapBbox[1] * 0.2 + cluster.bbox[1] * 0.8),
          (mapBbox[2] * 0.2 + cluster.bbox[2] * 0.8),
          (mapBbox[3] * 0.2 + cluster.bbox[3] * 0.8)
        ];
        map.fitBounds(bboxToGoogleMapsBounds(intermediateBbox));
        setTimeout(() => onClick(), 250);  // eslint-disable-line prefer-rest-params
      } else if (clusterWidth > (0.4 * mapWidth) || clusterHeight > (0.4 * mapHeight)) {
        const currentZoom = map.getZoom();
        if (currentZoom <= PROXIMITY_CLUSTER_MAX_ZOOM) {
          map.setZoom(currentZoom + 1);
        }
      } else {
        map.fitBounds(bboxToGoogleMapsBounds(cluster.bbox));
      }
    } else {
      dispatch(selectArea(
        new google.maps.LatLng(cluster.marker.lat, cluster.marker.lng),
        map,
        remToPx(1.25)
      ));
    }
  };

  const { center, marker, count, classes, overlaps } = cluster;
  const isMax = classes.indexOf('max') >= 0;
  const isCluster = overlaps.length > 1;
  const selected = selectionCount > 1;
  const displayCount = selected && !isCluster ? selectionCount : count;
  const { r, g, b } = hexToRgb(style.root.secondaryColor);  // eslint-disable-line id-length
  const clusterStyle = classes.indexOf('cluster') >= 0 ? {
    background: style.root.secondaryColor,
    boxShadow: `0 0 0 .625em rgba(${r},${g},${b},0.2)`
  } : {};

  return (
    <OverlayView
      mapPaneName={OverlayView.FLOAT_PANE}
      position={isCluster ? center : marker}
      zIndex={(selected ? 20000 : 20) + Math.min(displayCount, 10000)}
    >
      <div
        className={classNames(styles.overlay, {[styles.overlapSelected]: (!isCluster && selected) || drawingActive })}
      >
        {!isCluster &&
          <div className={classNames(...classes, styles.markerOverlap, {[styles.selected]: selected}, styles.stackedOverlap)} />
        }
        <div onClick={onClick}
          className={classNames(...classes, styles.markerOverlap, {[styles.selected]: selected})}
          style={clusterStyle}
          role="presentation"
        >
          <div className={styles.count}>
            {isMax ? `${(displayCount / 1000).toFixed()}K` : displayCount}
          </div>
        </div>
      </div>
    </OverlayView>
  );
};

OverlapCountMarker.propTypes = {
  cluster: PropTypes.object,
  selectionCount: PropTypes.number
};

export default memo(OverlapCountMarker);
