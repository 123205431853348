import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';

const SmallMedia = ({ children }) => (
  <MediaQuery minDeviceWidth={768}>
    {matches => matches ? null : (children || null)}
  </MediaQuery>
);

SmallMedia.propTypes = {
  children: PropTypes.node.isRequired
};

export default SmallMedia;
