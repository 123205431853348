import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLegend } from '../../../selectors/ui';
import { getNotificationsVisible } from '../../../selectors/notifications';
import Icon from '@material-ui/core/Icon';
import ThemeStyle, {PRIMARY_TEXT, INHERIT} from '../../theme-style';
import styles from './mobile-legend-button.scss';

class MobileLegendButton extends Component {
  render() {
    if (this.props.notificationsVisible) {
      return null;
    }
    return (
      <div className={styles.button} onClick={this.props.legendButtonAction} role="button" tabIndex={0}>
        <ThemeStyle style={this.props.legend.visible ? PRIMARY_TEXT : INHERIT}>
          <Icon>list</Icon>
        </ThemeStyle>
      </div>
    );
  }
}

MobileLegendButton.propTypes = {
  legend: PropTypes.object,
  legendButtonAction: PropTypes.func,
  notificationsVisible: PropTypes.bool
};

const mapStateToProps = state => ({
  legend: getLegend(state),
  notificationsVisible: getNotificationsVisible(state)
});

export default connect(mapStateToProps, {getNotificationsVisible})(MobileLegendButton);
