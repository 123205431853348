import { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  clearHighlight,
  closeAreaSelection,
  setHighlight,
  setLinkedElementInactive
} from '@actions/map-actions';
import {
  closeMobileMaxDetails,
  openSubscribeDialog
} from '@actions/ui-actions';
import { getMapData } from '@selectors/map';
import { getSelectionElementsCount } from '@selectors/map-selection';
import { getTray } from '@selectors/site';
import { getMobileMaxDetails } from '@selectors/ui';
import { getAllLocations } from '@utils/map-data-utils';
import { zoom } from '@utils/map-utils';
import { parseClassNames, renderTemplate } from '@utils/template-utils';

const InfoDetail = ({ detailData, mapRef }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const areaCount = useSelector(getSelectionElementsCount);
  const mapData = useSelector(getMapData);
  const mobileMaxDetails = useSelector(getMobileMaxDetails);
  const trayConfig = useSelector(getTray);

  const onSetHighlight = useCallback((name, layerId, itemId) => dispatch(setHighlight(name, layerId, itemId)), [dispatch]);
  const onClearHighlight = useCallback(name => dispatch(clearHighlight(name)), [dispatch]);
  const onCloseMobileMaxDetails = () => dispatch(closeMobileMaxDetails());

  const { data, layerData } = detailData;

  useEffect(() => {
    onSetHighlight('detail', layerData.id, data.id);
    return () => onClearHighlight('detail');
  }, [areaCount, data.id, detailData, layerData.id, mapData, mapRef, onClearHighlight, onSetHighlight, mobileMaxDetails, trayConfig]);

  const onCloseDetails = () => {
    dispatch(closeAreaSelection());
    dispatch(setLinkedElementInactive());
    // Navigate to /map to clear the query params:
    if (window.location.search) {
      history.push('/map');
    }
  };

  const onSubscribe = () => {
    dispatch(openSubscribeDialog());
  };

  const onOpenStreetView = () => {
    if (data.center) {
      const panorama = mapRef.getStreetView();
      const {lng, lat} = data.center;
      panorama.setPosition({lng, lat});
      panorama.setVisible(true);
    }
  };

  const onZoomToDetail = event => {
    const { bbox } = data;
    zoom(event, mapRef, bbox);
  };

  // Log event in Google Analytics:
  // eslint-disable-next-line no-undef
  gtag('event', 'view_entity', {
    entity_remote_id: data.remote_id,
    entity_type: layerData.name
  });

  const allLocations = getAllLocations(mapData, data.remote_id);
  const streetView = layerData.uiStyle.marker;
  const { classNames, components, layout } = trayConfig;
  const options = {
    data,
    props: {
      areaCount,
      allLocations,
      mapData,
      mobileMaxDetails,
      detailData,
      closeDetails: onCloseDetails,
      closeMobileDetails: onCloseMobileMaxDetails,
      subscribe: onSubscribe,
      openStreetView: onOpenStreetView,
      zoomToDetail: onZoomToDetail
    },
    streetView,
    classNames: parseClassNames(classNames),
    components
  };

  return renderTemplate(layout, options);
};

InfoDetail.propTypes = {
  detailData: PropTypes.object.isRequired,
  mapRef: PropTypes.object
};

export default memo(InfoDetail);
