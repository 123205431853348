import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './global-popup.scss';

// Popup component to be used by Javascript code that can't work with React.
// (i.e. it's manually shown/hidden with Javascript).
class GlobalPopup extends Component {
  render() {
    return (
      <div id="global-popup" className={styles.popup}>
        <div id="global-popup-content" className={styles.popupContent} />
        <div className={styles.progressBackground}>
          <CircularProgress
            classes={{circle: styles.progressCircle}}
            thickness={6}
            size={75} />
        </div>
      </div>
    );
  }
}

export default GlobalPopup;
