import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateLabelGrid } from '@actions/map-actions';
import { getViewportData } from '@selectors/clusters';
import { getViewport } from '@selectors/map';
import { getLabeledPolygonData, getLabels } from '@selectors/map-labels';
import { OverlayView } from '@react-google-maps/api';
import { resolveFields } from '@utils/map-data-utils';
import styles from './map-labels.scss';

class MapLabels extends Component {
  constructor() {
    super();
    this.state = {};
    this.generateLabelGrid = this.generateLabelGrid.bind(this);
  }

  componentDidMount() {
    this.generateLabelGrid();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.labeledPolygonData !== this.props.labeledPolygonData) {
      this.generateLabelGrid();
    }
  }

  generateLabelGrid() {
    const {labeledPolygonData, viewport: {bbox}} = this.props;
    this.props.updateLabelGrid(bbox, labeledPolygonData);
  }

  render() {
    const {layerLabels, viewportData} = this.props;
    if (!layerLabels || !viewportData) {
      return null;
    }

    const labels = [];
    Object.entries(viewportData).forEach(([layerId, layer]) => {
      if (layerLabels[layerId]) {
        const labelLocations = layerLabels[layerId];
        const {uiStyle: {polygonLabel}, mapStyle: {strokeColor} } = layer;
        layer.list.forEach(element => {
          const {mapElement: {id, attrs}} = element;
          if (id in labelLocations) {
            const lngLats = labelLocations[id];
            lngLats.forEach((lngLat, index) => {
              labels.push({
                key: `${layerId}-${id}-${index}`,
                color: strokeColor,
                label: polygonLabel.label,
                value: resolveFields(polygonLabel.value, attrs),
                position: {
                  lng: lngLat[0],
                  lat: lngLat[1]
                }
              });
            });
          }
        });
      }
    });

    return labels.map(({key, color, label, value, position}) => (
      <OverlayView
        key={key}
        mapPaneName={OverlayView.FLOAT_PANE}
        position={position}
      >
        <div style={{color}} className={styles.labelContainer}>
          <div className={styles.label}>{label}</div>
          <div className={styles.value}>{value}</div>
        </div>
      </OverlayView>
    ));
  }
}

MapLabels.propTypes = {
  labeledPolygonData: PropTypes.array,
  layerLabels: PropTypes.object,
  updateLabelGrid: PropTypes.func,
  viewport: PropTypes.object,
  viewportData: PropTypes.object
};

const mapStateToProps = state => ({
  labeledPolygonData: getLabeledPolygonData(state),
  layerLabels: getLabels(state),
  viewport: getViewport(state),
  viewportData: getViewportData(state)
});

export default connect(mapStateToProps, {updateLabelGrid})(MapLabels);
