// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-notifications-notification-icon-___notification-icon__default g{fill:rgba(0,0,0,0.4)}.src-components-notifications-notification-icon-___notification-icon__selected g{fill:#4284F4}.src-components-notifications-notification-icon-___notification-icon__mobile g{fill:#fff}
`, "",{"version":3,"sources":["webpack://./src/components/notifications/notification-icon/notification-icon.scss"],"names":[],"mappings":"AAGA,gFACK,oBAAwB,CAAE,iFAI1B,YAAa,CAAE,+EAIb,SAAU","sourcesContent":["@import '../../../styles/mixins';\n@import '../../../styles/vars';\n\n.default {\n  g {fill: rgba(0, 0, 0, 0.4)};\n}\n\n.selected {\n  g {fill: #4284F4};\n}\n\n.mobile {\n    g {fill: #fff};\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"default": `src-components-notifications-notification-icon-___notification-icon__default`,
	"selected": `src-components-notifications-notification-icon-___notification-icon__selected`,
	"mobile": `src-components-notifications-notification-icon-___notification-icon__mobile`
};
export default ___CSS_LOADER_EXPORT___;
