/* eslint-disable no-use-before-define */
import sizeOf from 'image-size';
import axios from 'axios';
import * as R from 'ramda';
import distance from '@turf/distance';
import { convertArea, convertLength, polygon } from '@turf/helpers';
import { isOldIE } from '@utils/browser-detect';
import { bboxToGoogleMapsBounds } from '@utils/geometry-utils';

export const zipCoordinatePair = R.zipObj(['lng', 'lat']);
// zipCoordinatePair([-141, 35]) will return { lat: 35, lng: -141}

export const generatePath = coordinates => coordinates.map(zipCoordinatePair);

export const getPointShapeCoords = shape => {
  return zipCoordinatePair(shape.coordinates);
};

const optimizePoint = ({ center, shape, ...data }) => ({
  ...data,
  shape,
  center: center ? getPointShapeCoords(center) : null,
  position: getPointShapeCoords(shape)
});

const optimizePath = ({ center, shape, ...data }) => ({
  ...data,
  shape,
  center: center ? getPointShapeCoords(center) : null,
  path: generatePath(shape.coordinates)
});

const optimizePolygon = ({ center, shape, ...data }) => ({
  ...data,
  shape,
  center: center ? getPointShapeCoords(center) : null,
  paths: shape.coordinates.map(generatePath)
});

const optimizeMulti = ({ id, shape, ...data }) => {
  const type = shape.type.slice(5);
  return {
    id,
    ...data,
    shape,
    childElements: shape.coordinates.map(
      coordinates => ({
        id,
        shape: {type},
        ...optimizeDataForMap({shape: {type, coordinates}})
      })
    )
  };
};

const optimizeCollection = ({id, shape, ...data}) => ({
  id,
  ...data,
  shape,
  childElements: shape.geometries.map(
    (type, coordinates) => ({
      id,
      shape: {type},
      ...optimizeDataForMap({shape: {type, coordinates}})
    })
  )
});

export const optimizeDataForMap = R.cond([
  [R.pathEq('Point', ['shape', 'type']), optimizePoint],
  [R.pathEq('LineString', ['shape', 'type']), optimizePath],
  [R.pathEq('Polygon', ['shape', 'type']), optimizePolygon],
  [R.pathEq('GeometryCollection', ['shape', 'type']), optimizeCollection],
  [R.pathSatisfies(type => type.startsWith('Multi'), ['shape', 'type']), optimizeMulti]
]);

export const getMapIcon = (url, active = false) => {
  if (url) {
    return axios.get(url).then(
      ({data}) => {
        try {
          const dimensions = sizeOf(new Buffer(data));
          if (dimensions) {
            const icon = {
              // eslint-disable-next-line id-length
              anchor: { x: dimensions.width / 2.0, y: active ? dimensions.height : dimensions.height / 2.0 },
              url
            };

            if (isOldIE()) {
              // Specify the scaled size to avoid the 'InvalidStateError' on Google map's marker.js,
              // see this for more information:
              //
              //     https://stackoverflow.com/questions/27261346/custom-svg-markers-wont-display-in-ie-11
              //
              // We must also set the optimized property, but that's part of the Marker options,
              // not the icon ones (I added that where we define each Marker).
              //
              // It's better to only do this for IE, since this changes the Marker icon size slightly
              // (only 1px or 2), and it's better to not change anything for Chrome/Safari.
              icon.scaledSize = { width: dimensions.width, height: dimensions.height };
            }
            return icon;
          }
        } catch (error) {
          return null;
        }
        return null;
      },
      () => null
    );
  }
  return Promise.resolve(null);
};

export const centerOnBoundingBox = (bbox, mapRef) => {
  const bounds = bboxToGoogleMapsBounds(bbox);
  mapRef.panToBounds(bounds);
  mapRef.fitBounds(bounds, 0);
};

export const zoom = (event, mapRef, bbox) => {
  if (event) {
    event.stopPropagation();
  }
  const bounds = bboxToGoogleMapsBounds(bbox);
  mapRef.panToBounds(bounds);
  mapRef.fitBounds(bounds);
};

export const pixelDistanceToMeters = (latLng, googleMap, px) => {
  const zoomLevel = Math.max(13, googleMap.getZoom()); // Treat zoom 13 as minimum for pixelRadius
  const radiusOffset = px / Math.pow(2, zoomLevel); // 1 point per pixel at zoom 0 halving for each zoom level higher.
  const projection = googleMap.getProjection();
  if (projection) {
    const offsetPixels = projection.fromLatLngToPoint(latLng);
    // eslint-disable-next-line id-length
    offsetPixels.x = offsetPixels.x + radiusOffset;
    const offsetLatLng = projection.fromPointToLatLng(offsetPixels);
    const {lng, lat} = latLng.toJSON();
    const {lng: offLng, lat: offLat} = offsetLatLng.toJSON();
    return distance([lng, lat], [offLng, offLat], {units: 'meters'});
  }
  return 0;
};

// eslint-disable-next-line no-undefined
const distanceFormat = Intl.NumberFormat(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
// eslint-disable-next-line no-undefined
const largeDistanceFormat = Intl.NumberFormat(undefined, {minimumSignificantDigits: 7, maximumSignificantDigits: 7});
// eslint-disable-next-line no-undefined
const veryLargeDistanceFormat = Intl.NumberFormat(undefined, {maximumFractionDigits: 0});
const formatDistance = number => {
  if (number >= 10000.0) {
    if (number >= 10000000.0) {
      return veryLargeDistanceFormat.format(number);
    }
    return largeDistanceFormat.format(number);
  }
  return distanceFormat.format(number);
};

export const getAreaDisplay = squareMeters => {
  const kilometers = convertArea(squareMeters, 'meters', 'kilometers');
  const feet = convertArea(squareMeters, 'meters', 'feet');
  const miles = convertArea(squareMeters, 'meters', 'miles');
  return (
    `${miles > 0.25 ? `${formatDistance(miles)} mi²` : `${formatDistance(feet)} ft²`} ` +
    `(${kilometers > 0.25 ? `${formatDistance(kilometers)} km²` : `${formatDistance(squareMeters)} m²`})`
  );
};

export const getLengthDisplay = meters => {
  const kilometers = convertLength(meters, 'meters', 'kilometers');
  const feet = convertLength(meters, 'meters', 'feet');
  const miles = convertLength(meters, 'meters', 'miles');
  return (
    `${miles > 0.25 ? `${formatDistance(miles)} mi` : `${formatDistance(feet)} ft`} ` +
    `(${kilometers > 0.25 ? `${formatDistance(kilometers)} km` : `${formatDistance(meters)} m`})`
  );
};

const closeRing = points => [...points, points[0]];

export const pointToArray = point => [point.lng(), point.lat()];

export const mapsPolygonToFeature = shape => polygon([closeRing(shape.map(pointToArray))]);
