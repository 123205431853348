import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { camelCase } from 'lodash';
import { fetchMapData, deepLinkDataFetch } from '@actions/map-actions';
import { searchedHighlightStyle } from '@constants/google-maps';
import { getViewportData } from '@selectors/clusters';
import { getMapData, getHighlightedElements, getDevSecretParams } from '@selectors/map';
import { getHighlightedOrSelectedElements } from '@selectors/map-selection';
import { getselectedLayerElement } from '@selectors/search';
import { getInitialUrlFilter } from '@selectors/filters';
import MapFigure from './map-figure';
import WmsLayer from './wms-layer';
import WfsLoader from './wfs-loader';

class MapData extends Component {
  componentWillMount() {
    const { mapData, id, devSecretParams, mapRef } = this.props;
    if (!mapData[id].list.length && mapData[id].url) {
      if (mapData[id].uiStyle.linkable) {
        const params = new URLSearchParams(window.location.search);
        if (params.get('link_type') && params.get('link_type') === mapData[id].name) {
          this.props.deepLinkDataFetch(id, mapData[id].url, params.get('link_value'), mapRef, devSecretParams);
        }
      }
      // Load searchable layers immediately to prioritize them
      if (mapData[id].uiStyle.searchable) {
        this.props.fetchMapData(id, mapData[id].url, devSecretParams);
      } else {
        // Load other layers after timeout
        setTimeout(() => {
          this.props.fetchMapData(id, mapData[id].url, devSecretParams);
        }, 0);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { id, mapData, initialUrlFilter, devSecretParams } = this.props;
    const { next } = nextProps.mapData[id];
    if (next && mapData[id].next !== next) {
      this.props.fetchMapData(id, next);
    }
    if (initialUrlFilter && !nextProps.initialUrlFilter) {
      if (mapData[id].url) {
        this.props.fetchMapData(id, mapData[id].url, devSecretParams);
      }
    }
  }

  getMapStyle = (mapStyle, styleCondition, element) => {
    if (styleCondition) {
      const key = element.attrs[styleCondition];
      return mapStyle[key] || mapStyle[camelCase(key)];
    }
    return mapStyle || {};
  };

  render() {
    const {
      id, mapData, clusteredData, highlightedElements, higlightedOrSelected, searchable, selectedLayerElement
    } = this.props;
    const layerData = mapData && mapData[id];
    if (layerData) {
      if (layerData.url) {
        if (searchable && selectedLayerElement && layerData && selectedLayerElement.layerId === id) {
          const { list, mapStyle, styleCondition } = layerData;
          return (
            <Fragment>
              {list.map(mapElement => {
                if (mapElement.id === selectedLayerElement.id) {
                  return (
                    <MapFigure
                      key={mapElement.id}
                      mapElement={mapElement}
                      mapStyle={{...this.getMapStyle(mapStyle, styleCondition, mapElement), ...searchedHighlightStyle}}
                    />
                  );
                }
                return null;
              })}
            </Fragment>
          );
        }
        if (id in clusteredData) {
          const { list, mapStyle, styleCondition, activeMapIcon, mapIcon, uiStyle } = clusteredData[id];
          const layerHighlights = highlightedElements[id] || {};
          const layerSelections = higlightedOrSelected[id] || {};
          return (list.map(({ mapElement, clusterSelection }) => (
            <MapFigure
              key={mapElement.id}
              layerId={id}
              mapElement={mapElement}
              clusterSelection={clusterSelection}
              mapStyle={this.getMapStyle(mapStyle, styleCondition, mapElement)}
              highlightable={Boolean(uiStyle.highlightable)}
              activeMarker={activeMapIcon}
              marker={mapIcon}
              highlighted = {Boolean(layerHighlights[mapElement.id])}
              selected = {Boolean(layerSelections[mapElement.id])}
            />
          )));
        }
      } else if (layerData.services && layerData.services.wms) {
        const { list, mapStyle, styleCondition, activeMapIcon, mapIcon, uiStyle } = clusteredData[id];
        const layerHighlights = highlightedElements[id] || {};
        const layerSelections = higlightedOrSelected[id] || {};
        return (
          <Fragment>
            {layerData.services.wms &&
              <WmsLayer
                url={layerData.services.wms.url}
                params={layerData.services.wms.params}
              />
            }
            {layerData.services.wfs &&
              <WfsLoader
                layerId={id}
                url={layerData.services.wfs.url}
                params={layerData.services.wfs.params}
              />
            }
            {list.map(({ mapElement, clusterSelection }) => {
              if (layerSelections[mapElement.id]) {
                return (
                  <MapFigure
                    key={mapElement.id}
                    layerId={id}
                    mapElement={mapElement}
                    clusterSelection={clusterSelection}
                    mapStyle={this.getMapStyle(mapStyle, styleCondition, mapElement)}
                    highlightable={Boolean(uiStyle.highlightable)}
                    activeMarker={activeMapIcon}
                    marker={mapIcon}
                    highlighted = {Boolean(layerHighlights[mapElement.id])}
                    selected = {Boolean(layerSelections[mapElement.id])}
                  />
                );
              }
              return null;
            })}
          </Fragment>
        );
      }
    }
    return null;
  }
}

MapData.propTypes = {
  clusteredData: PropTypes.object,
  deepLinkDataFetch: PropTypes.func,
  devSecretParams: PropTypes.object,
  fetchMapData: PropTypes.func,
  highlightedElements: PropTypes.object,
  higlightedOrSelected: PropTypes.object,
  id: PropTypes.number,
  initialUrlFilter: PropTypes.bool,
  mapData: PropTypes.object,
  mapRef: PropTypes.object,
  searchable: PropTypes.bool,
  selectedLayerElement: PropTypes.object
};

const mapStateToProps = state => {
  return {
    mapData: getMapData(state),
    clusteredData: getViewportData(state),
    highlightedElements: getHighlightedElements(state),
    higlightedOrSelected: getHighlightedOrSelectedElements(state),
    selectedLayerElement: getselectedLayerElement(state),
    initialUrlFilter: getInitialUrlFilter(state),
    devSecretParams: getDevSecretParams(state)
  };
};

export default connect(mapStateToProps, { fetchMapData, deepLinkDataFetch })(MapData);
