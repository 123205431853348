// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-info-snackbar-___info-snackbar__snackbar{font-size:.875rem;letter-spacing:0.2px;border-radius:.25rem;color:#ebebeb;background-color:#444444}
`, "",{"version":3,"sources":["webpack://./src/components/info-snackbar/info-snackbar.scss"],"names":[],"mappings":"AAGA,yDACE,iBAAkB,CAClB,oBAAqB,CACrB,oBAAqB,CACrB,aAAc,CACd,wBAAyB","sourcesContent":["@import '../../styles/mixins';\n@import '../../styles/vars';\n\n.snackbar {\n  font-size: .875rem;\n  letter-spacing: 0.2px;\n  border-radius: .25rem;\n  color: #ebebeb;\n  background-color: #444444;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"snackbar": `src-components-info-snackbar-___info-snackbar__snackbar`
};
export default ___CSS_LOADER_EXPORT___;
