// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-map-location-button-location-error-___location-error__snackbar{font-size:.875rem;letter-spacing:0.2px;border-radius:.25rem;color:#ebebeb;background-color:#444444}@media only screen and (min-device-width: 768px){.src-components-map-location-button-location-error-___location-error__snackbar{margin:0 0 3rem 0rem;width:21.5rem}}.src-components-map-location-button-location-error-___location-error__retry-button span{font-weight:500;color:#fff}
`, "",{"version":3,"sources":["webpack://./src/components/map/location-button/location-error/location-error.scss"],"names":[],"mappings":"AAGA,+EACI,iBAAkB,CAClB,oBAAqB,CACrB,oBAAqB,CACrB,aAAc,CACd,wBAAyB,CACzB,iDANJ,+EAOQ,oBAAqB,CACrB,aAAc,CAErB,CAED,wFAEQ,eAAgB,CAChB,UACJ","sourcesContent":["@import '../../../../styles/mixins';\n@import '../../../../styles/vars';\n\n.snackbar {\n    font-size: .875rem;\n    letter-spacing: 0.2px;\n    border-radius: .25rem;\n    color: #ebebeb;\n    background-color: #444444;\n    @media only screen and (min-device-width: $desktop-device-width) {\n        margin: 0 0 3rem 0rem;\n        width: 21.5rem;\n    }\n}\n\n.retry-button {\n    span {\n        font-weight: 500;\n        color: #fff\n    };\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"snackbar": `src-components-map-location-button-location-error-___location-error__snackbar`,
	"retry-button": `src-components-map-location-button-location-error-___location-error__retry-button`,
	"retryButton": `src-components-map-location-button-location-error-___location-error__retry-button`
};
export default ___CSS_LOADER_EXPORT___;
