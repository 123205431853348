import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './error-404.scss';
import { getError4040Logo } from '../../selectors/site';

class Error404 extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.title}>Error</div>
        <div><img src={this.props.error404Logo} alt="" /></div>
        <div className={styles.content}>We’re sorry, but the page you’re looking for could not be found.</div>
        <div><a href="/map">Go to Homepage</a></div>
      </div>
    );
  }
}

Error404.propTypes = {
  error404Logo: PropTypes.string
};

const mapStateToProps = state => ({ error404Logo: getError4040Logo(state) });

export default connect(mapStateToProps, {})(Error404);
