import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import { getLegend } from '../../../../selectors/ui';
import { getNotificationsVisible } from '../../../../selectors/notifications';
import {
  getDrawingComplete,
  getMeasureToolActive,
  getDrawingSizeDisplay,
  getMeasureToolLengthDisplay
} from '../../../../selectors/map';
import { getSelectionDrawingMode } from '../../../../selectors/map-selection';
import ThemeStyle, { SECONDARY_TEXT } from '../../../theme-style';
import { clearAreaSelection, clearDrawingMode, clearMeasureActive } from '../../../../actions/map-actions';
import styles from './map-tools-info.scss';

class MapToolsInfo extends Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose = () => {
    this.props.clearDrawingMode();
    this.props.clearMeasureActive();
  };

  render() {
    const { legend, notificationsVisible, drawingMode, measureActive } = this.props;
    if (legend.visible || notificationsVisible || (!drawingMode && !measureActive)) {
      return null;
    }

    if (measureActive) {
      return (
        <div className={styles.measureContainer}>
          <div className={styles.header}>
            <div>Measure</div>
            <Icon onClick={this.handleClose}>close</Icon>
          </div>
          { !this.props.lengthDisplay ?
            <div>Click on the map to trace a path you want to measure.</div> :
            <div>
              <div>Total Distance: {this.props.lengthDisplay}</div>
              <ThemeStyle
                style={SECONDARY_TEXT}
                className={styles.clear}
                onClick={this.props.resetMeasurement}
              >Clear</ThemeStyle>
            </div>
          }
        </div>
      );
    }

    if (drawingMode === 'circle') {
      return (
        <div className={styles.circleContainer}>
          <div className={styles.header}>
            <div>Radius Search</div>
            <Icon onClick={this.handleClose}>close</Icon>
          </div>
          { !this.props.sizeDisplay ?
            <div>Draw a circle on the map to search within boundaries.</div> :
            <div>
              <div>Total Area: {this.props.sizeDisplay}</div>
              <ThemeStyle
                style={SECONDARY_TEXT}
                className={styles.clear}
                onClick={this.props.clearAreaSelection}
              >Clear boundaries</ThemeStyle>
            </div>
          }
        </div>
      );
    }

    if (drawingMode === 'polygon') {
      return (
        <div className={styles.polygonContainer}>
          <div className={styles.header}>
            <div>Area Search</div>
            <Icon onClick={this.handleClose}>close</Icon>
          </div>
          { !this.props.sizeDisplay ?
            <div>Draw an area on the map to search within boundaries.</div> :
            <div>
              <div>Total Area: {this.props.sizeDisplay}</div>
              <ThemeStyle
                style={SECONDARY_TEXT}
                className={styles.clear}
                onClick={this.props.clearAreaSelection}
              >Clear boundaries</ThemeStyle>
            </div>
          }
        </div>
      );
    }
    return null;
  }
}

MapToolsInfo.propTypes = {
  clearAreaSelection: PropTypes.func,
  clearDrawingMode: PropTypes.func,
  clearMeasureActive: PropTypes.func,
  drawingComplete: PropTypes.bool,
  drawingMode: PropTypes.string,
  legend: PropTypes.object,
  lengthDisplay: PropTypes.string,
  mapData: PropTypes.array,
  measureActive: PropTypes.bool,
  notificationsVisible: PropTypes.bool,
  resetMeasurement: PropTypes.func,
  sizeDisplay: PropTypes.string
};

const mapStateToProps = state => ({
  drawingMode: getSelectionDrawingMode(state),
  drawingComplete: getDrawingComplete(state),
  legend: getLegend(state),
  lengthDisplay: getMeasureToolLengthDisplay(state),
  measureActive: getMeasureToolActive(state),
  notificationsVisible: getNotificationsVisible(state),
  sizeDisplay: getDrawingSizeDisplay(state)
});

export default connect(mapStateToProps, { clearAreaSelection, clearDrawingMode, clearMeasureActive })(MapToolsInfo);
