import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { getVisibleClusters } from '@selectors/clusters';
import OverlapCountMarker from './overlap-count-marker';

const MarkerOverlaps = () => {
  const clusters = useSelector(getVisibleClusters);
  return Object.entries(clusters).map(([prefix, { selectionCount, cluster }]) => (
    <OverlapCountMarker
      key={prefix}
      cluster={cluster}
      selectionCount={selectionCount}
    />
  ));
};

export default memo(MarkerOverlaps);
