import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import LargeMedia from '@shared/large-media';
import SmallMedia from '@shared/small-media';
import { getNotificationsVisible, getNotificationsShapeError } from '../../../selectors/notifications';
import { setNotificationDrawingShape } from '../../../actions/notification-actions';

import styles from './shape-error.scss';

class ShapeError extends Component {
  clearShape = () => {
    this.props.setNotificationDrawingShape(null);
  };

  render() {
    if (!this.props.notificationsVisible || !this.props.notificationsShapeError) {
      return null;
    }
    return (
      <Fragment>
        <LargeMedia>
          <Snackbar
            open={this.props.notificationsVisible}
            classes={{anchorOriginBottomCenter: styles.shapeError}}
            ContentProps={{
              'aria-describedby': 'message-id',
              classes: {root: styles.content}
            }}
            message={<span id="message-id">The area is a little too large. Select a smaller area.</span>}
            action={
              <Button
                classes={{sizeSmall: styles.clearButton}}
                key="retry"
                size="small"
                onClick={this.clearShape}
              >
                CLEAR
              </Button>
            }
          />
        </LargeMedia>
        <SmallMedia>
          <Snackbar
            open={this.props.notificationsVisible}
            classes={{anchorOriginBottomCenter: styles.shapeError}}
            ContentProps={{
              'aria-describedby': 'message-id',
              classes: {root: styles.content}
            }}
            message={<span id="message-id">The area is a little too large. Select a smaller area.</span>}
          />
        </SmallMedia>
      </Fragment>
    );
  }
}

ShapeError.propTypes = {
  notificationsShapeError: PropTypes.bool,
  notificationsVisible: PropTypes.bool,
  setNotificationDrawingShape: PropTypes.func
};

const mapStateToProps = state => {
  return {
    notificationsVisible: getNotificationsVisible(state),
    notificationsShapeError: getNotificationsShapeError(state)
  };
};

export default connect(mapStateToProps, {setNotificationDrawingShape})(ShapeError);
