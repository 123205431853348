/* eslint-disable consistent-this */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Div100vh from 'react-div-100vh';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Icon from '@material-ui/core/Icon';
import Radio from '@material-ui/core/Radio';
import FormHelperText from '@material-ui/core/FormHelperText';
import MobileDrawingTools from './mobile-drawing-tools';
import ConfirmationPage from '../confirmation-page';
import ThemeStyle, { SECONDARY_TEXT, INHERIT} from '../../theme-style';
import {
  getNotificationsVisible,
  getNotificationsDrawingShape,
  getNotificationsStep,
  getNotificationsDataTypes,
  getNotificationsFrequency,
  getNotificationsEmail,
  getNotificationsEmailError,
  getNotificationsName,
  getNotificationsSuccess,
  getNotificationsShapeError
} from '../../../selectors/notifications';
import { getNotificationFrequencyOptions, getNotificationEntityTypes } from '../../../selectors/site';
import {
  closeNotifications,
  setNotificationDrawingShape,
  setNotificationStep,
  setNotificationDataTypes,
  setNotificationFrequency,
  setNotificationEmail,
  setNotificationName,
  saveNotification,
  resetNotificationForm,
  TYPE_AREA
} from '../../../actions/notification-actions';

import styles from './mobile-notifications.scss';

class MobileNotifications extends Component {
  state = {emailTimeout: null, email: null, emailValidated: false, overlayVisible: true, shape: null};

  nextStep = () => {
    const { step } = this.props;
    if (step === 1) {
      this.setState({overlayVisible: true});
    }
    this.props.setNotificationStep(step + 1);
  };

  prevStep = () => {
    const { step } = this.props;
    if (step === 2 || step === 3) {
      this.setState({overlayVisible: true});
    }
    this.props.setNotificationStep(step - 1);
  };

  setFrequency = event => this.props.setNotificationFrequency(event.target.value);
  setName = event => this.props.setNotificationName(event.target.value);

  setEmail = event => {
    const self = this;
    const email = event.target.value;
    if (this.state.emailTimeout) {
      clearTimeout(self.state.emailTimeout);
    }
    this.setState({
      email,
      emailValidated: false,
      emailTimeout: setTimeout(() => {
        self.setState({emailValidated: true});
        self.props.setNotificationEmail(email);
      }, 500)});
  };

  save = () => {
    const { shape, dataTypes, frequency, email, name } = this.props;
    this.props.saveNotification(TYPE_AREA, shape, dataTypes, frequency, email, name, null);
  };

  handleClose = () => {
    this.setState({
      overlayVisible: true
    });
    this.props.closeNotifications();
    this.props.resetNotificationForm();
  };

  setType = event => {
    const { value, checked } = event.target;
    const { dataTypes } = this.props;
    let types = [...dataTypes];
    if (checked && dataTypes.indexOf(value) < 0) {
      types.push(value);
    } else if (!checked && dataTypes.indexOf(value) > -1) {
      types = dataTypes.filter(dataType => dataType !== value);
    }
    this.props.setNotificationDataTypes(types);
  };

  checkStepComplete = () => {
    const { overlayVisible } = this.state;
    const { step, shape, dataTypes, frequency, email, emailError, shapeError } = this.props;
    if (step === 1 && overlayVisible) {
      return false;
    } else if (step === 2 && (!shape || shapeError)) {
      return false;
    } else if (step === 3 && dataTypes.length === 0) {
      return false;
    } else if (step === 4 && (frequency === null || email === null || emailError)) {
      return false;
    }
    return true;
  };

  hideDrawingOverlay = () => {
    this.setState({overlayVisible: false});
  };

  clearShape = () => {
    this.props.setNotificationDrawingShape(null);
  };

  getDataTypesList = () => {
    const [...dataTypes] = this.props.dataTypes;
    const last = dataTypes.pop();
    if (dataTypes.length) {
      let types = [];
      if (dataTypes.length > 1) {
        types = dataTypes.map(dataType => <div key={dataType}><span>{dataType}</span>,&nbsp;</div>);
      } else {
        types.push(<div key={dataTypes[0]}><span>{dataTypes[0]}</span>&nbsp;</div>);
      }
      types.push(<div key={last}>and&nbsp;<span>{last}</span></div>);
      return types;
    }
    return <div key={last}><span>{last}</span></div>;
  };

  render() {
    if (!this.props.visible) {
      return null;
    }
    const disabled = !this.checkStepComplete();
    const { shape, step, dataTypes, frequency, email, name, emailError, shapeError } = this.props;
    if (!this.props.success) {
      return (
        <div className={styles.container}>
          <div className={styles.header}>
            <Icon onClick={this.handleClose}>close</Icon>
            <div>Receive Activity Updates</div>
          </div>
          <div className={styles.subheader}>
            {step === 2 && (shape || shapeError) &&
              <Button className={styles.left} onClick={this.clearShape}>Clear</Button>
            }
            {(step > 2 || step === 2 && !shape && !shapeError) &&
              <Button className={styles.left} onClick={this.prevStep}>Back</Button>
            }
            <div className={styles.steps}>Step {step} of 5</div>
            {step < 5 &&
              <Button
                classes={{disabled: styles.disabled}}
                className={styles.right}
                disabled={disabled}
                onClick={this.nextStep}
              >
                <ThemeStyle style={!disabled ? SECONDARY_TEXT : INHERIT}>Next</ThemeStyle>
              </Button>
            }
          </div>
          {step === 1 && this.state.overlayVisible &&
            <Div100vh style={{height: 'calc(100rvh - 6.5rem'}} className={styles.overlayContainer}>
              <div className={styles.overlay} onClick={this.hideDrawingOverlay} role="button" tabIndex={0}>
                <Icon>pan_tool</Icon>
                <label>Pan and zoom to an area you care about.</label>
              </div>
            </Div100vh>
          }
          {step === 1 && !this.state.overlayVisible &&
            <Div100vh style={{height: 'calc(100rvh - 6.5rem'}} className={styles.outlineContainer}>
              <div className={styles.outline} />
            </Div100vh>
          }
          {step === 2 && !shape && this.state.overlayVisible &&
            <Div100vh style={{height: 'calc(100rvh - 6.5rem'}} className={styles.overlayContainer}>
              <div className={styles.overlay} onClick={this.hideDrawingOverlay} role="button" tabIndex={0}>
                <Icon>touch_app</Icon>
                <label>Draw an area on the map to see results.</label>
              </div>
            </Div100vh>
          }
          {step === 3 &&
            <div className={styles.content}>
              <div className={styles.title}>Notify me about:</div>
              <div className={styles.selectionBlock}>
                {this.props.entityTypes && this.props.entityTypes.map(entityType => {
                  return (
                    <div key={entityType.value} className={styles.selectionRow}>
                      <label>{entityType.label}</label>
                      <Switch
                        onChange={event => this.setType(event)}
                        value={entityType.value}
                        checked={dataTypes.indexOf(entityType.value) > -1} />
                    </div>
                  );
                })}
              </div>
            </div>
          }
          {step === 4 &&
            <div className={styles.content}>
              <div className={styles.title}>Email me</div>
              <div className={styles.selectionBlock}>
                {this.props.frequencyOptions && this.props.frequencyOptions.map(item => (
                  <div key={item.value} className={styles.selectionRow}>
                    <label>{item.label}</label>
                    <Radio
                      checked={frequency === item.value}
                      onChange={event => this.setFrequency(event)}
                      value={item.value}
                      name="frequency-radio"
                    />
                  </div>
                ))}
              </div>
              <div className={styles.userInfo}>
                <div className={styles.userRow}>
                  <label>Email address</label>
                  <OutlinedInput
                    classes={{root: styles.input}}
                    onChange={event => this.setEmail(event)}
                    value={this.state.email}
                    placeholder="example@domain.com"
                    error={emailError}
                    labelWidth={0}
                    fullWidth />
                  {emailError &&
                    <FormHelperText className={styles.error} id="email-error-text">Enter a valid email</FormHelperText>
                  }
                </div>
                <div className={styles.userRow}>
                  <label>Name this area <span>(Optional)</span></label>
                  <OutlinedInput
                    classes={{root: styles.input}}
                    onChange={event => this.setName(event)}
                    value={name}
                    placeholder="Ex. Near home, near work..."
                    labelWidth={0}
                    fullWidth />
                </div>
              </div>
            </div>
          }
          {step === 5 &&
            <div className={styles.content}>
              <div className={styles.summary}>
                <div className={styles.list}>
                  <div>In summary, you&apos;ll receive</div>
                  <ul>
                    <li><div>
                      <span>{R.find(R.propEq(frequency, 'value'))(this.props.frequencyOptions).label}</span>
                      &nbsp;notifications
                    </div></li>
                    <li>
                      <div>
                        About&nbsp;{this.getDataTypesList()}&nbsp;in this area
                        {name && <div>&nbsp;(<span>{name}</span>)</div>}
                      </div>
                    </li>
                    <li><div>At&nbsp;<span>{email}</span></div></li>
                  </ul>
                </div>
                <Button variant="contained" color="secondary" onClick={this.save}>Save</Button>
              </div>
            </div>
          }
          <MobileDrawingTools mapRef={this.props.mapRef} active={step === 2 && !shape} />
        </div>
      );
    }
    return <ConfirmationPage />;
  }
}

MobileNotifications.propTypes = {
  closeNotifications: PropTypes.func,
  dataTypes: PropTypes.array,
  email: PropTypes.string,
  emailError: PropTypes.bool,
  entityTypes: PropTypes.array,
  frequency: PropTypes.number,
  frequencyOptions: PropTypes.array,
  mapRef: PropTypes.object,
  name: PropTypes.string,
  resetNotificationForm: PropTypes.func,
  saveNotification: PropTypes.func,
  setNotificationDataTypes: PropTypes.func,
  setNotificationDrawingShape: PropTypes.func,
  setNotificationEmail: PropTypes.func,
  setNotificationFrequency: PropTypes.func,
  setNotificationName: PropTypes.func,
  setNotificationStep: PropTypes.func,
  shape: PropTypes.array,
  shapeError: PropTypes.bool,
  step: PropTypes.number,
  success: PropTypes.bool,
  visible: PropTypes.bool
};

const mapStateToProps = state => ({
  visible: getNotificationsVisible(state),
  shape: getNotificationsDrawingShape(state),
  step: getNotificationsStep(state),
  dataTypes: getNotificationsDataTypes(state),
  frequency: getNotificationsFrequency(state),
  email: getNotificationsEmail(state),
  emailError: getNotificationsEmailError(state),
  name: getNotificationsName(state),
  frequencyOptions: getNotificationFrequencyOptions(state),
  success: getNotificationsSuccess(state),
  shapeError: getNotificationsShapeError(state),
  entityTypes: getNotificationEntityTypes(state)
});

export default connect(mapStateToProps, {
  closeNotifications,
  setNotificationDrawingShape,
  setNotificationStep,
  setNotificationDataTypes,
  setNotificationFrequency,
  setNotificationEmail,
  setNotificationName,
  saveNotification,
  resetNotificationForm
})(MobileNotifications);
