import {
  SITE_DATA_FETCH
} from '../constants/action-types';
import initialState from '../store/initial-state';

const siteReducer = (state = initialState.site, action) => {
  switch (action.type) {
  case SITE_DATA_FETCH: {
    return {...state, ...action.config};
  }
  default:
    return state;
  }
};

export default siteReducer;
