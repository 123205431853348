import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ThemeStyle, {PRIMARY_BACKGROUND} from '../theme-style';

import { getLogo, getNotes, getDisclaimerText } from '../../selectors/site';
import { openDisclaimer } from '../../actions/map-actions';

import styles from './footer.scss';

class Footer extends Component {
  render() {
    const { logo, notes, disclaimer } = this.props;
    return (
      <ThemeStyle className={styles.footer} style={PRIMARY_BACKGROUND}>
        <div className={styles.logo}>
          { logo && logo.imageUrl && !logo.linkUrl &&
            <img src={logo.imageUrl} alt="" />
          }
          { logo && logo.imageUrl && logo.linkUrl &&
            <a href={logo.linkUrl} target="_blank" rel="noopener noreferrer"><img src={logo.imageUrl} alt="" /></a>
          }
        </div>
        <div className={styles.spacer}>&nbsp;</div>
        {disclaimer && <div className={styles.links}><div onClick={this.props.openDisclaimer} role="button" tabIndex={0}>Disclaimer</div></div>}
        <div className={styles.links}>
          {notes && notes.links &&
            notes.links.map(link => (
              <div key={link.noteType}>
                <a href={`/notes?type=${link.noteType}`} target="_blank" rel="noopener noreferrer">{link.label}</a>
              </div>
            ))
          }
        </div>
      </ThemeStyle>
    );
  }
}

Footer.propTypes = {
  disclaimer: PropTypes.string,
  logo: PropTypes.object,
  notes: PropTypes.object,
  openDisclaimer: PropTypes.func
};

const mapStateToProps = state => ({
  notes: getNotes(state),
  logo: getLogo(state),
  disclaimer: getDisclaimerText(state)
});

export default connect(mapStateToProps, {openDisclaimer})(Footer);
