import {
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR
} from '../constants/action-types';
import initialState from '../store/initial-state';

const snackbarReducer = (state = initialState.snackbar, action) => {
  switch (action.type) {
  case OPEN_SNACKBAR: {
    return { message: action.message };
  }
  case CLOSE_SNACKBAR: {
    return { message: null };
  }
  default:
    return state;
  }
};

export default snackbarReducer;
