import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import { getNotificationsVisible } from '../../../selectors/notifications';

import styles from './drawing-mode.scss';

class DrawingMode extends Component {
  render() {
    if (!this.props.notificationsVisible) {
      return null;
    }
    return (
      <Fragment>
        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'left'}}
          open={this.props.notificationsVisible}
          classes={{anchorOriginTopLeft: styles.drawingMode}}
          ContentProps={{
            'aria-describedby': 'message-id',
            classes: {root: styles.content}
          }}
          message={<span id="message-id">You're in drawing mode.</span>}
        />
      </Fragment>
    );
  }
}

DrawingMode.propTypes = {
  notificationsVisible: PropTypes.bool
};

const mapStateToProps = state => ({ notificationsVisible: getNotificationsVisible(state) });

export default connect(mapStateToProps, {})(DrawingMode);
