export const getNotificationsVisible = state => state.notifications.visible;
export const getNotificationsDrawingActive = state => state.notifications.drawingActive;
export const getNotificationsDrawingShape = state => state.notifications.drawingShape;
export const getNotificationsStep = state => state.notifications.step;
export const getNotificationsDataTypes = state => state.notifications.dataTypes;
export const getNotificationsFrequency = state => state.notifications.frequency;
export const getNotificationsEmail = state => state.notifications.email;
export const getNotificationsName = state => state.notifications.name;
export const getNotificationsSuccess = state => state.notifications.success;
export const getNotificationsShapeError = state => state.notifications.shapeError;
export const getNotificationsEmailError = state => state.notifications.emailError;
export const getNotificationsPaused = state => state.notifications.paused;
export const getNotificationsTooltipVisible = state => state.notifications.tooltipVisible;
