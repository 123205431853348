/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import classNames from 'classnames';
import { debounce } from 'lodash';
import highlightMatch from 'autosuggest-highlight/match';
import highlightParse from 'autosuggest-highlight/parse';

import Icon from '@material-ui/core/Icon';
import Select, { components } from 'react-select';

import ThemeStyle, {PRIMARY_TEXT, INHERIT} from '../../theme-style';
import LocationButton from '../../map/location-button';

import {
  fetchGeocodeSuggestions,
  setSearchTerm,
  setSearchSelection,
  clearSearchSuggestions,
  setSearchFocused,
  clearSearchSelection
} from '../../../actions/search-actions';
import { clearInitialUrlFilter } from '../../../actions/filter-actions';
import {
  getSelection,
  getSuggestions,
  getSearchFocused,
  getLinkedElement,
  getIsLoading,
  getMenuIsOpen
} from '../../../selectors/search';
import {
  getInitialUrlFilter
} from '../../../selectors/filters';

import { centerOnBoundingBox } from '@utils/map-utils';

import styles from './map-search-autocomplete.scss';

const ident = value => value;

class MapSearchAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {input: null, select: null};
    this.getOptions = debounce(this.getOptions.bind(this), 500);

    this.changeValues = this.changeValues.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.onClose = this.onClose.bind(this);
    this.nullRenderer = this.nullRenderer.bind(this);
    this.controlRenderer = this.controlRenderer.bind(this);
    this.dropDownRenderer = this.dropDownRenderer.bind(this);
    this.clearRenderer = this.clearRenderer.bind(this);
    this.optionsRenderer = this.optionsRenderer.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.blurSearch = this.blurSearch.bind(this);
    this.focusSearch = this.focusSearch.bind(this);
    this.setSearchRef = this.setSearchRef.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { linkedElement, mapRef, selection } = this.props;

    if (prevProps.linkedElement !== linkedElement) {
      this.changeValues(linkedElement, '');
    }

    if (!prevProps.mapRef && mapRef && selection) {
      this.changeValues(selection, {action: ''});
    }
  }

  changeValues = (selected, { action }) => {
    this.setState({input: null});
    const { mapRef } = this.props;
    this.props.clearSearchSelection();
    if ((action === 'clear')) {
      this.props.clearSearchSuggestions();
    }

    if ((action === 'clear' || action === 'select-option') && this.props.initialUrlFilter) {
      this.props.clearInitialUrlFilter();
    }
    if (selected) {
      if (selected.length !== null && typeof selected.length !== 'undefined') {
        return;
      }
      this.props.setSearchSelection(selected);
      if (selected.bbox && mapRef) {
        centerOnBoundingBox(selected.bbox, mapRef);
      }
    }
  };

  inputChange = (value, { action }) => { // eslint-disable-line consistent-return
    if (action === 'input-change') {
      this.setState({input: value});
      this.props.clearSearchSuggestions();
      if (value) {
        this.getOptions(value);
        return value;
      }
      this.props.clearSearchSelection();
    }
    if (action === 'input-blur') {
      this.setState({input: null});
    }
  };

  getOptions = value => {
    this.props.fetchGeocodeSuggestions(value);
    this.props.setSearchTerm(value);
  };

  onClose = () => {
    this.props.clearSearchSuggestions();
  };

  nullRenderer = () => {
    return null;
  };

  controlRenderer = ({children, ...rest}) => {
    const {collapsed, mobile, selection} = this.props;
    const isBackButton = mobile && !collapsed;
    return (
      <div className={styles.controlWrap}>
        <div className={classNames(styles.searchIcon, {[styles.collapsedSearchIcon]: collapsed})}>
          { isBackButton ?
            <Icon className={styles.backIcon} onClick={this.blurSearch}>arrow_back</Icon> :
            <ThemeStyle style={selection && !mobile ? PRIMARY_TEXT : INHERIT}>
              <Icon onClick={this.focusSearch} >search</Icon>
            </ThemeStyle>
          }
        </div>
        <components.Control {...rest}>
          {children}
        </components.Control>
      </div>
    );
  };

  dropDownRenderer = () => {
    if (this.props.showMyLocation) {
      return <div className={styles.locationPad}/>;
    }
    return null;
  };

  clearRenderer = ({innerProps}) => {
    const {collapsed} = this.props;
    return (
      <div
        {...innerProps}
        className={classNames(styles.clearIcon, {[styles.clearIconCollapsed]: collapsed})}
      >
        <Icon>close</Icon>
      </div>
    );
  };

  optionsRenderer = ({children, ...rest}) => {
    const { input = '' } = this.state;
    const parsedChildren = highlightParse(children, highlightMatch(children, input));
    return (
      <components.Option {...rest}>
        {parsedChildren.map(({text, highlight}, index) => (
          <span key={index} className={highlight ? styles.highlightedOptionPart : null}>{text}</span>
        ))}
      </components.Option>
    );
  };

  onBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
    this.props.setSearchFocused(false);
  };

  onFocus = () => {
    if (this.props.onFocus) {
      this.props.onFocus();
    }
    this.props.setSearchFocused(true);
  };

  blurSearch = () => {
    if (this.state.search) {
      this.state.search.blur();
    }
  };

  focusSearch = () => {
    if (this.state.search) {
      this.state.search.focus({preventScroll: true});
    }
  };

  setSearchRef = ref => {
    this.setState({search: ref});
  };

  render() {
    const { mobile, suggestions, selection, focused, collapsed, loading, menu} = this.props;
    const { input } = this.state;
    const inputValue = (
      input !== null ? input : selection && selection.label || ''
    );
    const value = selection || input ? {label: input, value: input} : null;

    return (
      <div className={
        classNames(
          styles.mapSearchAutocompleteContainer,
          {
            [styles.collapse]: collapsed,
            [styles.mobile]: mobile,
            [styles.menu]: menu
          }
        )
      }>
        <Select
          autoFocus={focused}
          backspaceRemovesValue={false}
          blurInputOnSelect
          classNamePrefix={styles.mapSearchPrefix}
          components={{
            Control: this.controlRenderer,
            DropdownIndicator: this.dropDownRenderer,
            IndicatorSeparator: this.nullRenderer,
            ClearIndicator: this.clearRenderer,
            Option: this.optionsRenderer
          }}
          placeholder="Search address"
          noOptionsMessage={() => 'No results found'}
          isLoading={loading}
          isClearable
          menuIsOpen={menu}
          options={suggestions || []}
          filterOption={ident}
          value={value}
          onChange={this.changeValues}
          inputValue={inputValue}
          onInputChange={this.inputChange}
          onMenuClose={this.onClose}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          ref={this.setSearchRef}
        />
        { this.props.showMyLocation &&
          <div className={styles.locationOverlay}>
            <LocationButton search />
          </div>
        }
      </div>
    );
  }
}

MapSearchAutocomplete.propTypes = {
  clearInitialUrlFilter: PropTypes.func,
  clearSearchSelection: PropTypes.func,
  clearSearchSuggestions: PropTypes.func,
  collapsed: PropTypes.bool,
  fetchGeocodeSuggestions: PropTypes.func,
  focused: PropTypes.bool,
  initialUrlFilter: PropTypes.bool,
  linkedElement: PropTypes.object,
  loading: PropTypes.bool,
  mapRef: PropTypes.object,
  menu: PropTypes.bool,
  mobile: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  selection: PropTypes.object,
  setSearchFocused: PropTypes.func,
  setSearchSelection: PropTypes.func,
  setSearchTerm: PropTypes.func,
  showMyLocation: PropTypes.bool,
  suggestions: PropTypes.array,
  value: PropTypes.string
};

const mapStateToProps = state => ({
  linkedElement: getLinkedElement(state),
  loading: getIsLoading(state),
  menu: getMenuIsOpen(state),
  selection: getSelection(state),
  suggestions: getSuggestions(state),
  focused: getSearchFocused(state),
  initialUrlFilter: getInitialUrlFilter(state)
});

export default connect(mapStateToProps, {
  fetchGeocodeSuggestions,
  setSearchTerm,
  setSearchSelection,
  clearSearchSuggestions,
  setSearchFocused,
  clearSearchSelection,
  clearInitialUrlFilter
})(MapSearchAutocomplete);
