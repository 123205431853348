/* eslint-disable react/no-string-refs */
/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';

import { closeDateRangeDialog } from '../../../actions/ui-actions';
import { setCustomDateRange } from '../../../actions/filter-actions';
import { getDialog } from '../../../selectors/ui';
import { getCustomDateRange, getSelectedDateRange } from '../../../selectors/filters';

import ThemeStyle, {PRIMARY_TEXT} from '../../theme-style';

import styles from './date-range-modal.scss';

class DateRangeModal extends Component {
  state = {
    selectedMinDate: this.props.customDateRange.start || new Date(),
    selectedMaxDate: this.props.customDateRange.end || new Date()
  };

  handleMinDateChange = date => this.setState({ selectedMinDate: date });

  handleMaxDateChange = date => this.setState({ selectedMaxDate: date });

  setDates = () => {
    const { selectedMinDate, selectedMaxDate } = this.state;
    this.props.setCustomDateRange(selectedMinDate, selectedMaxDate);
    this.props.closeDateRangeDialog();
  };

  openStart = () => this.refs.startDate.open();

  openEnd = () => this.refs.endDate.open();

  render() {
    const { dateRangeVisible } = this.props.dialog;
    const { selectedMinDate, selectedMaxDate } = this.state;
    const { selectedDateRange } = this.props;

    return (
      <Dialog
        aria-labelledby="dialog-title"
        PaperProps={{ classes: {root: styles.container } }}
        open={dateRangeVisible}
      >
        <DialogTitle id="dialog-title" className={styles.title}>Custom Date Range</DialogTitle>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DialogContent className={styles.content}>
            <div className={styles.dateRow}>
              <div className={styles.label}>Start date</div>
              <DatePicker
                keyboard
                allowKeyboardControl={false}
                keyboardIcon={
                  <ThemeStyle style={PRIMARY_TEXT}>
                    <Icon>calendar_today</Icon>
                  </ThemeStyle>
                }
                adornmentPosition="start"
                InputProps={{disableUnderline: true, onClick: this.openStart}}
                label={null}
                id="min-date"
                format={'MM/DD/YYYY'}
                mask={value =>
                  value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                }
                value={selectedMinDate}
                onChange={this.handleMinDateChange}
                minDate={Date.parse(selectedDateRange.startDate)}
                maxDate={Date.parse(selectedDateRange.endDate)}
                className={styles.dateInput}
                ref="startDate"
              />
            </div>
            <div className={styles.dateRow}>
              <div className={styles.label}>End date</div>
              <DatePicker
                keyboard
                keyboardIcon={
                  <ThemeStyle style={PRIMARY_TEXT}>
                    <Icon>calendar_today</Icon>
                  </ThemeStyle>
                }
                adornmentPosition="start"
                InputProps={{disableUnderline: true, onClick: this.openEnd}}
                label={null}
                id="max-date"
                format={'MM/DD/YYYY'}
                mask={value =>
                  value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                }
                value={selectedMaxDate}
                onChange={this.handleMaxDateChange}
                minDate={Date.parse(selectedDateRange.startDate)}
                maxDate={Date.parse(selectedDateRange.endDate)}
                className={styles.dateInput}
                ref="endDate"
              />
            </div>
          </DialogContent>
        </MuiPickersUtilsProvider>
        <DialogActions className={styles.buttons}>
          <div className={styles.cancel}>
            <Button onClick={this.props.closeDateRangeDialog}>CANCEL</Button>
          </div>
          <div className={styles.save}>
            <Button onClick={this.setDates} variant="contained" color="secondary">SAVE</Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

DateRangeModal.propTypes = {
  closeDateRangeDialog: PropTypes.func,
  customDateRange: PropTypes.object,
  dialog: PropTypes.object,
  selectedDateRange: PropTypes.object,
  setCustomDateRange: PropTypes.func
};

const mapStateToProps = state => ({
  dialog: getDialog(state),
  customDateRange: getCustomDateRange(state),
  selectedDateRange: getSelectedDateRange(state)
});

export default connect(mapStateToProps, {closeDateRangeDialog, setCustomDateRange})(DateRangeModal);
