import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Marker } from '@react-google-maps/api';
import { getSelectedPosition } from '@selectors/search';

const SearchMarker = () => {
  const position = useSelector(getSelectedPosition);

  if (position) {
    return <Marker position={position} />;
  }
  return null;
};

export default memo(SearchMarker);
