export const OPEN_DATE_RANGE_DIALOG = 'OPEN_DATE_RANGE_DIALOG';
export const CLOSE_DATE_RANGE_DIALOG = 'CLOSE_DATE_RANGE_DIALOG';
export const OPEN_SUBSCRIBE_DIALOG = 'OPEN_SUBSCRIBE_DIALOG';
export const CLOSE_SUBSCRIBE_DIALOG = 'CLOSE_SUBSCRIBE_DIALOG';
export const OPEN_LAYER_FILTERS = 'OPEN_LAYER_FILTERS';
export const CLOSE_LAYER_FILTERS = 'CLOSE_LAYER_FILTERS';
export const SET_LAYER_FILTERS_DEFAULT_OPEN = 'SET_LAYER_FILTERS_DEFAULT_OPEN';
export const OPEN_MOBILE_LINKS = 'OPEN_MOBILE_LINKS';
export const CLOSE_MOBILE_LINKS = 'CLOSE_MOBILE_LINKS';
export const OPEN_MOBILE_MAX_DETAILS = 'OPEN_MOBILE_MAX_DETAILS';
export const CLOSE_MOBILE_MAX_DETAILS = 'CLOSE_MOBILE_MAX_DETAILS';
export const OPEN_LEGEND = 'OPEN_LEGEND';
export const CLOSE_LEGEND = 'CLOSE_LEGEND';
export const SET_DETAIL_TRAY_COLLAPSE = 'SET_DETAIL_TRAY_COLLAPSE';
export const SET_MOBILE_MAP_LAYERS = 'SET_MOBILE_MAP_LAYERS';
export const SET_DRAWING_OVERLAY = 'SET_DRAWING_OVERLAY';
export const SHOW_USER_LOCATION_ERROR = 'SHOW_USER_LOCATION_ERROR';

export const FILTERS_FETCH_SUCCESS = 'FILTERS_FETCH_SUCCESS';
export const DATE_RANGE_FETCH_SUCCESS = 'DATE_RANGE_FETCH_SUCCESS';
export const TOGGLE_FILTER = 'TOGGLE_FILTER';
export const SELECT_DATE_RANGE = 'SELECT_DATE_RANGE';
export const SET_CUSTOM_DATE_RANGE = 'SET_CUSTOM_DATE_RANGE';
export const SET_INITIAL_URL_FILTER = 'SET_INITIAL_URL_FILTER';
export const CLEAR_INITIAL_URL_FILTER = 'CLEAR_INITIAL_URL_FILTER';

export const MAP_DATA_FETCH_SUCCESS = 'MAP_DATA_FETCH_SUCCESS';
export const MAP_ZOOM_CHANGED = 'MAP_ZOOM_CHANGED';
export const MAP_SELECT_AREA = 'MAP_SELECT_AREA';
export const MAP_INVALIDATE_SELECT_AREA = 'MAP_INVALIDATE_SELECT_AREA';
export const MAP_SELECT_ITEM = 'MAP_SELECT_ITEM';
export const MAP_CLEAR_HIGHLIGHT = 'MAP_CLEAR_HIGHLIGHT';
export const MAP_HIGHLIGHT = 'MAP_HIGHLIGHT';
export const MAP_VIEWPORT_CHANGE = 'MAP_VIEWPORT_CHANGE';
export const MAP_SET_CENTER = 'MAP_SET_CENTER';
export const MAP_SET_ZOOM = 'MAP_SET_ZOOM';
export const MAP_SET_STREET_VIEW_VISIBLE = 'MAP_SET_STREET_VIEW_VISIBLE';
export const MAP_SET_TYPE_ID = 'MAP_SET_TYPE_ID';
export const MAP_TOGGLE_TRAFFIC = 'MAP_TOGGLE_TRAFFIC';
export const MAP_SET_DRAWING_MODE = 'MAP_SET_DRAWING_MODE';
export const MAP_SELECT_AREA_DRAWING = 'MAP_SELECT_AREA_DRAWING';
export const MAP_MEASURE_ACTIVE = 'MAP_MEASURE_ACTIVE';
export const MAP_MEASURE_LENGTH = 'MAP_MEASURE_LENGTH';
export const MAP_SET_USER_LOCATION = 'MAP_SET_USER_LOCATION';
export const MAP_SET_LABEL_GRID = 'MAP_SET_LABEL_GRID';
export const MAP_SET_DEV_SECRET_REQUIRED = 'MAP_SET_DEV_SECRET_REQUIRED';
export const MAP_SET_DEV_SECRET = 'MAP_SET_DEV_SECRET';
export const MAP_SET_DISCLAIMER = 'MAP_SET_DISCLAIMER';
export const SET_LINKED_ELEMENT = 'SET_LINKED_ELEMENT';
export const SET_LINKED_ELEMENT_ACTIVE = 'SET_LINKED_ELEMENT_ACTIVE';
export const SET_404_PAGE = 'SET_404_PAGE';

export const GEOCODE_SUGGESTION_FETCH_SUCCESS = 'GEOCODE_SUGGESTION_FETCH_SUCCESS';
export const PLACES_SUGGESTION_FETCH_SUCCESS = 'PLACES_SUGGESTION_FETCH_SUCCESS';
export const SET_SEARCH_SUGGESTION_TERM = 'SET_SEARCH_SUGGESTION_TERM';
export const SET_SEARCH_SELECTION = 'SET_SEARCH_SELECTION';
export const CLEAR_SEARCH_SUGGESTIONS = 'CLEAR_SEARCH_SUGGESTIONS';
export const SET_SEARCH_MARKER = 'SET_SEARCH_MARKER';
export const SET_SEARCH_FOCUSED = 'SET_SEARCH_FOCUSED';
export const SET_SELECTED_LAYER_ELEMENT = 'SET_SELECTED_LAYER_ELEMENT';

export const OPEN_NOTIFICATIONS = 'OPEN_NOTIFICATIONS';
export const CLOSE_NOTIFICATIONS = 'CLOSE_NOTIFICATIONS';
export const SET_NOTIFICATION_DRAWING_SHAPE = 'SET_NOTIFICATION_DRAWING_SHAPE';
export const SET_NOTIFICATION_STEP = 'SET_NOTIFICATION_STEP';
export const SET_NOTIFICATION_DATA_TYPES = 'SET_NOTIFICATION_DATA_TYPES';
export const SET_NOTIFICATION_FREQUENCY = 'SET_NOTIFICATION_FREQUENCY';
export const SET_NOTIFICATION_EMAIL = 'SET_NOTIFICATION_EMAIL';
export const SET_NOTIFICATION_NAME = 'SET_NOTIFICATION_NAME';
export const SET_NOTIFICATION_SUCCESS = 'SET_NOTIFICATION_SUCCESS';
export const SET_NOTIFICATION_SHAPE_ERROR = 'SET_NOTIFICATION_SHAPE_ERROR';
export const SET_NOTIFICATION_EMAIL_ERROR = 'SET_NOTIFICATION_EMAIL_ERROR';
export const SET_NOTIFICATION_PAUSED = 'SET_NOTIFICATION_PAUSED';
export const SET_NOTIFICATION_UUID = 'SET_NOTIFICATION_UUID';
export const SET_NOTIFICATION_TOOLTIP_VISIBLE = 'SET_NOTIFICATION_TOOLTIP_VISIBLE';

export const SITE_DATA_FETCH = 'SITE_DATA_FETCH';

export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
