import React from 'react';
import { connect, Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import PropTypes from 'prop-types';
import App from './App';
import { getTheme } from '../selectors/site';
import { ThemeProvider } from 'react-jss';

const ConnectedThemeProvider = connect(state => ({ theme: getTheme(state) }), {})(ThemeProvider);

const Root = ({ history, store }) => (
  <Provider store={store}>
    <ConnectedThemeProvider>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </ConnectedThemeProvider>
  </Provider>
);

Root.propTypes = {
  history: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

export default Root;
