import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Truncate from 'react-truncate';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import { getMapData } from '@selectors/map';
import { zoom } from '@utils/map-utils';
import {
  getTypes, getTitles, TITLE_FIELD, HEADER_PRE, HEADER_POST
} from '@utils/map-data-utils';
import LargeMedia from '@shared/large-media';
import ThemeStyle, { PRIMARY_BACKGROUND, SECONDARY_TEXT } from '../../../../theme-style';
import styles from './detail-header.scss';

class DetailHeader extends Component {
  zoomToDetail = event => {
    const { mapRef, detailData: { data: { bbox } } } = this.props;
    zoom(event, mapRef, bbox);
  };

  render() {
    const {
      detailData,
      mapData,
      mapRef, //eslint-disable-line no-unused-vars
      ...passThroughProps
    } = this.props;
    const types = getTypes(mapData, detailData);

    const {
      [TITLE_FIELD]: title,
      [HEADER_PRE]: preHeader,
      [HEADER_POST]: postHeader
    } = getTitles(mapData, detailData);

    let name;
    let pre;
    let post;
    if (title) {
      name = title;
    } else {
      name = types.pop();
    }
    if (preHeader) {
      pre = preHeader;
    } else {
      pre = types.pop();
    }
    if (postHeader) {
      post = postHeader;
    }

    const {layerData} = detailData;
    const streetView = layerData.uiStyle.marker;
    return (
      <Fragment>
        <ThemeStyle style={PRIMARY_BACKGROUND}>
          <div className={classNames(styles.title, {[styles.condensed]: !streetView})} {...passThroughProps}>
            <LargeMedia>
              <div
                className={classNames(styles.zoomButtonContainer, {[styles.condensed]: !streetView})}>
                <Fab
                  variant="extended"
                  classes={{root: classNames(styles.zoomButton)}}
                  onClick={this.zoomToDetail}
                >
                  <ThemeStyle style={SECONDARY_TEXT} className={styles.zoomTheme}>
                    <div className={styles.zoomInnerContainer}><Icon>zoom_in</Icon><div>ZOOM TO</div></div>
                  </ThemeStyle>
                </Fab>
              </div>
            </LargeMedia>
            {pre &&
              <div className={classNames(styles.type, {[styles.condensed]: !streetView})}>{pre}</div>
            }
            <div className={styles.name}>
              <Truncate lines={2} >{name}</Truncate>
            </div>
            {post &&
              <div className={styles.date}>{post}</div>
            }
          </div>
        </ThemeStyle>

      </Fragment>
    );
  }
}

DetailHeader.propTypes = {
  detailData: PropTypes.object.isRequired,
  mapData: PropTypes.object,
  mapRef: PropTypes.object
};

const mapStateToProps = state => ({ mapData: getMapData(state) });

export default connect(mapStateToProps, {})(DetailHeader);
