import React from 'react';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PRIMARY_TEXT = 'PRIMARY_TEXT';
export const SECONDARY_TEXT = 'SECONDARY_TEXT';
export const PRIMARY_BACKGROUND = 'PRIMARY_BACKGROUND';
export const SECONDARY_BACKGROUND = 'SECONDARY_BACKGROUND';
export const INHERIT = 'INHERIT';

const styleDefinitions = theme => ({
  [PRIMARY_TEXT]: {
    color: theme.palette.primary.main
  },
  [SECONDARY_TEXT]: {
    color: theme.palette.secondary.main
  },
  [PRIMARY_BACKGROUND]: {
    backgroundColor: theme.palette.primary.main
  },
  [SECONDARY_BACKGROUND]: {
    backgroundColor: theme.palette.secondary.main
  },
  [INHERIT]: {}
});

const ThemeStyle = props => {
  const { classes, className, children, style, ...passThrough } = props;
  return <span className={classNames(className, classes[style])} {...passThrough}>{children}</span>;
};

ThemeStyle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object,
  style: PropTypes.string
};

export default withStyles(styleDefinitions)(ThemeStyle);
