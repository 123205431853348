/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  clearAreaSelection,
  closeAreaSelection,
  setLinkedElementInactive
} from '@actions/map-actions';
import ThemeStyle, { SECONDARY_TEXT } from '@components/theme-style';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { getSelectionElementsCount } from '@selectors/map-selection';
import LargeMedia from '@shared/large-media';
import SmallMedia from '@shared/small-media';
import IdentifyList from './identify-list';
import MobileIdentify from './mobile-identify';
import MobileNoResults from './mobile-no-results';
import styles from './info-identify.scss';

const InfoIdentify = ({ mapRef }) => {
  const dispatch = useDispatch();
  const mapSelectionCount = useSelector(getSelectionElementsCount);

  const onClear = () => dispatch(clearAreaSelection());
  const onClose = () => {
    dispatch(closeAreaSelection());
    dispatch(setLinkedElementInactive());
  };

  return (
    <div className={styles.infoIdentifyContainer} >
      <LargeMedia>
        <div className={styles.identifyTitle}>
          Identify results
          <div className={styles.identifyCount}>
            {mapSelectionCount}
          </div>
        </div>
        {mapSelectionCount === 0 &&
          <div className={styles.noResults}>
            No results identified within the boundaries
            <Button className={styles.clearButton} onClick={onClear}>
              <ThemeStyle style={SECONDARY_TEXT}>
                <div className={styles.clearLabel}>CLEAR BOUNDARIES</div>
              </ThemeStyle>
            </Button>
          </div>
        }
        <div className={styles.identifyResults}>
          <IdentifyList mapRef={mapRef} />
        </div>
        <IconButton className={styles.closeButton} onClick={onClose}>
          <Icon>close</Icon>
        </IconButton>
      </LargeMedia>
      <SmallMedia>
        {mapSelectionCount > 0 ? <MobileIdentify mapRef={mapRef}/> : <MobileNoResults />}
      </SmallMedia>
    </div>
  );
};

InfoIdentify.propTypes = {
  mapRef: PropTypes.object.isRequired
};

export default memo(InfoIdentify);
