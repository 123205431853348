import axios from 'axios';
import bbox from '@turf/bbox';
import {
  camelCase,
  isArray,
  isPlainObject
} from 'lodash';
import { optimizeDataForMap } from './map-utils';

const fixKeyCasing = object => {
  if (isArray(object)) {
    return object.map(fixKeyCasing);
  }

  if (isPlainObject(object)) {
    const fixed = {};
    Object.entries(object).forEach(([key, value]) => {
      fixed[camelCase(key)] = fixKeyCasing(value);
    });
    return fixed;
  }

  return object;
};

export const layerDataConfig = {
  transformResponse: [
    // Call Axio's default transformResponse first, it will parse the data as JSON:
    ...axios.defaults.transformResponse,
    layers => fixKeyCasing(layers)
  ],
  responseType: 'json'
};

export const mapDataConfig = {
  transformResponse: [
    ...axios.defaults.transformResponse,
    payload => {
      payload.results.forEach(dataItem => {
        dataItem.bbox = bbox(dataItem.shape);
      });
      return payload;
    },
    ({results, ...payload}) => ({
      ...payload,
      results: results.map(optimizeDataForMap)
    })
  ],
  responseType: 'json'
};

export const jsonMapDataConfig = {
  transformResponse: [
    ...axios.defaults.transformResponse,
    payload => {
      const results = payload.features.map(({geometry, properties}) => ({
        shape: geometry,
        attrs: fixKeyCasing(properties),
        bbox: bbox(geometry)
      }));
      return {
        results
      };
    },
    ({results, ...payload}) => ({
      ...payload,
      results: results.map(optimizeDataForMap)
    })
  ],
  responseType: 'json'
};
