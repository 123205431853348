// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-map-info-tray-info-identify-mobile-no-results-___mobile-no-results__container{display:flex;flex-direction:column;flex:0 1 auto;justify-content:center;width:100%;height:3rem;background-color:#444444;font-size:.9375em;line-height:1.2;letter-spacing:0.2px;color:#ffffff;padding-left:1rem;font-weight:300}
`, "",{"version":3,"sources":["webpack://./src/components/map/info-tray/info-identify/mobile-no-results/mobile-no-results.scss"],"names":[],"mappings":"AAGA,8FACE,YAAa,CACb,qBAAsB,CACtB,aAAc,CACd,sBAAuB,CACvB,UAAW,CACX,WAAY,CACZ,wBAAyB,CAEzB,iBAAkB,CAClB,eAAgB,CAChB,oBAAqB,CACrB,aAAc,CACd,iBAAkB,CAClB,eAAgB","sourcesContent":["@import '../../../../../styles/mixins';\n@import '../../../../../styles/vars';\n\n.container {\n  display: flex;\n  flex-direction: column;\n  flex: 0 1 auto;\n  justify-content: center;\n  width: 100%;\n  height: 3rem;\n  background-color: #444444;\n\n  font-size: .9375em;\n  line-height: 1.2;\n  letter-spacing: 0.2px;\n  color: #ffffff;\n  padding-left: 1rem;\n  font-weight: 300;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `src-components-map-info-tray-info-identify-mobile-no-results-___mobile-no-results__container`
};
export default ___CSS_LOADER_EXPORT___;
