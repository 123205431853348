import {
  OPEN_LAYER_FILTERS,
  CLOSE_LAYER_FILTERS,
  OPEN_MOBILE_LINKS,
  CLOSE_MOBILE_LINKS,
  OPEN_MOBILE_MAX_DETAILS,
  CLOSE_MOBILE_MAX_DETAILS,
  OPEN_LEGEND,
  CLOSE_LEGEND,
  SET_DETAIL_TRAY_COLLAPSE,
  SET_MOBILE_MAP_LAYERS,
  SET_DRAWING_OVERLAY,
  OPEN_DATE_RANGE_DIALOG,
  CLOSE_DATE_RANGE_DIALOG,
  SHOW_USER_LOCATION_ERROR,
  SET_LAYER_FILTERS_DEFAULT_OPEN,
  OPEN_SUBSCRIBE_DIALOG,
  CLOSE_SUBSCRIBE_DIALOG
} from '../constants/action-types';
import { closeNotifications } from './notification-actions';


const openDateRangeAction = () => ({
  type: OPEN_DATE_RANGE_DIALOG
});

const closeDateRangeAction = () => ({
  type: CLOSE_DATE_RANGE_DIALOG
});

export const openDateRangeDialog = () => dispatch => {
  return dispatch(openDateRangeAction());
};

export const closeDateRangeDialog = () => dispatch => {
  return dispatch(closeDateRangeAction());
};

const openLayerFiltersAction = () => ({
  type: OPEN_LAYER_FILTERS
});

const closeLayerFiltersAction = () => ({
  type: CLOSE_LAYER_FILTERS
});

export const openLayerFilters = () => dispatch => {
  return dispatch(openLayerFiltersAction());
};

export const closeLayerFilters = () => dispatch => {
  return dispatch(closeLayerFiltersAction());
};

const setLayerFiltersDefaultOpenAction = (open) => ({
  type: SET_LAYER_FILTERS_DEFAULT_OPEN,
  open
});

export const setLayerFiltersDefaultOpen = (open) => dispatch => {
  return dispatch(setLayerFiltersDefaultOpenAction(open));
};

const openMobileLinksAction = () => ({
  type: OPEN_MOBILE_LINKS
});

const closeMobileLinksAction = () => ({
  type: CLOSE_MOBILE_LINKS
});

export const openMobileLinks = () => dispatch => {
  return dispatch(openMobileLinksAction());
};

export const closeMobileLinks = () => dispatch => {
  return dispatch(closeMobileLinksAction());
};

const openMobileMaxDetailsAction = () => ({
  type: OPEN_MOBILE_MAX_DETAILS
});

const closeMobileMaxDetailsAction = () => ({
  type: CLOSE_MOBILE_MAX_DETAILS
});

export const openMobileMaxDetails = () => dispatch => {
  return dispatch(openMobileMaxDetailsAction());
};

export const closeMobileMaxDetails = () => dispatch => {
  return dispatch(closeMobileMaxDetailsAction());
};

const openLegendAction = () => ({
  type: OPEN_LEGEND
});

const closeLegendAction = () => ({
  type: CLOSE_LEGEND
});

export const openLegend = () => dispatch => {
  dispatch(closeNotifications());
  dispatch(openLegendAction());
};

export const closeLegend = () => dispatch => {
  return dispatch(closeLegendAction());
};

const setDetailTrayCollapseAction = collapsed => ({
  type: SET_DETAIL_TRAY_COLLAPSE,
  collapsed
});

export const collapseDetailTray = () => dispatch => {
  return dispatch(setDetailTrayCollapseAction(true));
};

export const showDetailTray = () => dispatch => {
  return dispatch(setDetailTrayCollapseAction(false));
};

const setMobileLayersAction = visible => ({
  type: SET_MOBILE_MAP_LAYERS,
  visible
});

export const showMobileLayers = () => dispatch => {
  return dispatch(setMobileLayersAction(true));
};

export const hideMobileLayers = () => dispatch => {
  return dispatch(setMobileLayersAction(false));
};

const setDrawingOverlay = visible => ({
  type: SET_DRAWING_OVERLAY,
  visible
});

export const showDrawingOverlay = () => dispatch => {
  return dispatch(setDrawingOverlay(true));
};

export const hideDrawingOverlay = () => dispatch => {
  return dispatch(setDrawingOverlay(false));
};

const showUserLocationErrorAction = (visible) => ({
  type: SHOW_USER_LOCATION_ERROR,
  visible
});

export const showUserLocationError = () => dispatch => {
  return dispatch(showUserLocationErrorAction(true));
};

export const hideUserLocationError = () => dispatch => {
  return dispatch(showUserLocationErrorAction(false));
};

const openSubscribeAction = () => ({ type: OPEN_SUBSCRIBE_DIALOG });

const closeSubscribeAction = () => ({ type: CLOSE_SUBSCRIBE_DIALOG });

export const openSubscribeDialog = () => dispatch => dispatch(openSubscribeAction());

export const closeSubscribeDialog = () => dispatch => dispatch(closeSubscribeAction());
