import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FilterToggle from '../filter-toggle';
import ThemeStyle, {PRIMARY_BACKGROUND} from '../../theme-style';

import { getMapData } from '../../../selectors/map';

import styles from './mobile-icon.scss';

class MobileIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popover: null
    };

    this.mainToggleHandler = this.mainToggleHandler.bind(this);
  }

  mainToggleHandler = () => {
    const {id, onOpen} = this.props;
    onOpen(id);
  };

  render() {
    const { id, label, mapData} = this.props;
    const { checked, mapStyle, uiStyle: { filterIcon} } = mapData[id];
    const { open } = this.props;
    return (
      <div className={styles.filterCard}>
        <div className={styles.filterCardToggle}>
          <FilterToggle
            color={mapStyle && mapStyle.strokeColor}
            className={styles.toggle}
            checked={checked}
            onClick={this.mainToggleHandler}
            icon={filterIcon}
          />
          <label>{label}</label>
        </div>
        {open &&
          <ThemeStyle className={styles.activeTab} style={PRIMARY_BACKGROUND}/>
        }
      </div>
    );
  }
}

MobileIcon.propTypes = {
  id: PropTypes.number,
  label: PropTypes.string,
  mapData: PropTypes.object,
  name: PropTypes.string,
  onOpen: PropTypes.func,
  open: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    mapData: getMapData(state)
  };
};

export default connect(mapStateToProps, {})(MobileIcon);
