import {
  OPEN_NOTIFICATIONS,
  CLOSE_NOTIFICATIONS,
  SET_NOTIFICATION_DRAWING_SHAPE,
  SET_NOTIFICATION_STEP,
  SET_NOTIFICATION_DATA_TYPES,
  SET_NOTIFICATION_FREQUENCY,
  SET_NOTIFICATION_EMAIL,
  SET_NOTIFICATION_NAME,
  SET_NOTIFICATION_SUCCESS,
  SET_NOTIFICATION_SHAPE_ERROR,
  SET_NOTIFICATION_EMAIL_ERROR,
  SET_NOTIFICATION_PAUSED,
  SET_NOTIFICATION_UUID,
  SET_NOTIFICATION_TOOLTIP_VISIBLE
} from '../constants/action-types';
import initialState from '../store/initial-state';

const notificationsReducer = (state = initialState.notifications, action) => {
  switch (action.type) {
  case OPEN_NOTIFICATIONS:
    return {...state, visible: true};
  case CLOSE_NOTIFICATIONS:
    return {...state, visible: false};
  case SET_NOTIFICATION_DRAWING_SHAPE: {
    const { drawingShape } = action;
    return {...state, drawingShape};
  }
  case SET_NOTIFICATION_STEP: {
    const { step } = action;
    return {...state, step};
  }
  case SET_NOTIFICATION_DATA_TYPES: {
    const { dataTypes } = action;
    return {...state, dataTypes};
  }
  case SET_NOTIFICATION_FREQUENCY: {
    const { frequency } = action;
    return {...state, frequency};
  }
  case SET_NOTIFICATION_EMAIL: {
    const { email } = action;
    return {...state, email};
  }
  case SET_NOTIFICATION_NAME: {
    const { name } = action;
    return {...state, name};
  }
  case SET_NOTIFICATION_SUCCESS: {
    const { success } = action;
    return {...state, success};
  }
  case SET_NOTIFICATION_SHAPE_ERROR: {
    const { shapeError } = action;
    return {...state, shapeError};
  }
  case SET_NOTIFICATION_EMAIL_ERROR: {
    const { emailError } = action;
    return {...state, emailError};
  }
  case SET_NOTIFICATION_PAUSED: {
    const { paused } = action;
    return {...state, paused};
  }
  case SET_NOTIFICATION_UUID: {
    const { uuid } = action;
    return {...state, uuid};
  }
  case SET_NOTIFICATION_TOOLTIP_VISIBLE: {
    const { tooltipVisible } = action;
    return {...state, tooltipVisible};
  }
  default:
    return state;
  }
};

export default notificationsReducer;
