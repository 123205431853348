import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ConfirmIcon from '../confirm-icon';
import Link from '@material-ui/core/Link';
import { closeNotifications, resetNotificationForm, resendConfirmation } from '@actions/notification-actions';
import { getNotificationsVisible } from '@selectors/notifications';
/* eslint-disable css-modules/no-undef-class */
import styles from './confirmation-page.scss';

class ConfirmationPage extends Component {
  closeWindow = () => {
    this.props.closeNotifications();
    this.props.resetNotificationForm();
  };

  render() {
    return (
      <div className={styles.container}>
        <div>
          <div className={styles.confirmationHeader}>Confirm your email address</div>
          <div className={styles.confirmIcon}><ConfirmIcon /></div>
          <div className={styles.content}>
            We’ve sent you an email to verify your email address.
            <br /><br />
            Please check your inbox for a confirmation email. If you can’t find the email,
            check your spam folder or&nbsp;
            <Link color="primary" onClick={this.props.resendConfirmation}>
              resend confirmation email.
            </Link>
            <br /><br />
            To Unsubscribe to notifications, please find the unsubscribe link at the bottom of the email notification received.
            Unsubscribing will only unsubscribe your subscription to the project in question and not all notifications created in the Public Viewer.
          </div>
        </div>
        <div>
          <Button
            variant="contained"
            color="secondary"
            className={styles.ok}
            onClick={this.closeWindow}
          >
            OK
          </Button>
        </div>
      </div>
    );
  }
}

ConfirmationPage.propTypes = {
  closeNotifications: PropTypes.func,
  resendConfirmation: PropTypes.func,
  resetNotificationForm: PropTypes.func
};

const mapStateToProps = state => {
  return {
    visible: getNotificationsVisible(state)
  };
};

export default connect(mapStateToProps, {
  closeNotifications,
  resetNotificationForm,
  resendConfirmation
})(ConfirmationPage);
