/* eslint-disable react/jsx-no-bind */
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MeasureTool from 'measuretool-googlemaps-v3/lib/MeasureTool';
import {
  clearAreaSelection,
  clearDrawingMode,
  clearMeasureActive,
  setDrawingMode,
  setMeasureActive,
  setMeasureLength
} from '@actions/map-actions';
import { closeNotifications } from '@actions/notification-actions';
import { closeLegend } from '@actions/ui-actions';
import ThemeStyle, { SECONDARY_TEXT, INHERIT } from '@components/theme-style';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { useGoogleMap } from '@react-google-maps/api';
import { getDrawingActive, getMeasureToolActive } from '@selectors/map';
import { getSelectionDrawingMode } from '@selectors/map-selection';
import { getNotificationsVisible } from '@selectors/notifications';
import { getLegend } from '@selectors/ui';
import MapControl from '@shared/map-control';
import DrawIcon from '../../draw-icon';
import MapDrawingTools from '../map-drawing-tools';
import MapToolsInfo from '../map-tools-info';
import styles from './map-tools.scss';

const MapToolsSection = () => {
  const dispatch = useDispatch();
  const map = useGoogleMap();
  const drawingActive = useSelector(getDrawingActive);
  const measureActive = useSelector(getMeasureToolActive);
  const drawingMode = useSelector(getSelectionDrawingMode);
  const legend = useSelector(getLegend);
  const notificationsVisible = useSelector(getNotificationsVisible);
  const [measureTool, setMeasureTool] = useState(null);

  useEffect(() => {
    if (map) {
      const tool = new MeasureTool(map, {
        showSegmentLength: false,
        // Though the library has an option to set showAccumulativeLength false, doing so breaks it,
        // instead of just hiding it, it no longer does the calculations or stores the length at all
        // so I'm leaving it true and hiding it with css instead.
        showAccumulativeLength: true,
        tooltip: false,
        contextMenu: false,
        unit: MeasureTool.UnitTypeId.METRIC
      });
      tool.addListener('measure_change', () => {
        dispatch(setMeasureLength(tool.length));
      });
      setMeasureTool(tool);
    }
  }, [dispatch, map]);

  useEffect(() => {
    if (legend.visible && drawingActive) {
      dispatch(clearDrawingMode());
    }
  }, [dispatch, drawingActive, legend.visible]);

  useEffect(() => {
    if (notificationsVisible && drawingActive) {
      dispatch(clearDrawingMode());
    }
  }, [dispatch, drawingActive, notificationsVisible]);

  useEffect(() => {
    if (measureTool) {
      if (measureActive) {
        measureTool.start();
        map.setOptions({ draggableCursor: 'crosshair' });
      } else {
        measureTool.end();
      }
    }
  }, [dispatch, map, measureActive, measureTool]);

  const resetMeasurement = () => {
    measureTool.end();
    dispatch(setMeasureActive());
    setTimeout(() => {
      measureTool.start();
      map.setOptions({ draggableCursor: 'crosshair' });
    }, 0);
  };

  const setMeasurement = () => {
    if (measureActive) {
      dispatch(clearMeasureActive());
    } else {
      dispatch(setMeasureActive());
    }
  };

  const setMode = mode => {
    if (drawingMode !== mode) {
      if (drawingMode) {
        dispatch(clearAreaSelection());
      }
      dispatch(setDrawingMode(mode));
    } else
      if (!legend.visible && !notificationsVisible) {
        dispatch(clearDrawingMode());
      } else {
        dispatch(closeLegend());
        dispatch(closeNotifications());
      }
  };

  const setCircle = () => setMode('circle');

  const setPolygon = () => setMode('polygon');

  return (
    <div>
      <div className={styles.buttonGroup}>
        <Tooltip title="Measure" placement="bottom">
          <Button onClick={setMeasurement}>
            <ThemeStyle style={measureActive ? SECONDARY_TEXT : INHERIT}>
              <Icon>straighten</Icon>
            </ThemeStyle>
          </Button>
        </Tooltip>
        <Tooltip title="Radius search" placement="bottom">
          <Button onClick={setCircle}>
            <ThemeStyle style={drawingMode === 'circle' ? SECONDARY_TEXT : INHERIT}>
              <Icon>filter_tilt_shift</Icon>
            </ThemeStyle>
          </Button>
        </Tooltip>
        <Tooltip title="Area search" placement="bottom">
          <Button onClick={setPolygon}>
            <ThemeStyle style={drawingMode === 'polygon' ? SECONDARY_TEXT : INHERIT}>
              <DrawIcon selected={null} />
            </ThemeStyle>
          </Button>
        </Tooltip>
      </div>
      <MapDrawingTools />
      <MapControl position="RIGHT_TOP">
        <MapToolsInfo resetMeasurement={resetMeasurement} />
      </MapControl>
    </div>
  );
};

export default memo(MapToolsSection);
