import React, { Component } from 'react';
import styles from './global-backdrop.scss';

// Backdrop to blur the background while displaying some popup with
// a higher z-index.
//
// It's hidden by default, and it's meant to used by Javascript code
// that can't work with React components.
//
// This should only be used by that kind of code, since for all the
// React managed components, we can always use a Modal.
class GlobalBackdrop extends Component {
  render() {
    return <div id="global-backdrop" className={styles.backdrop} />;
  }
}

export default GlobalBackdrop;
