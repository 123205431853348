import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from '../../shared/loading-indicator';

import styles from './loading-page.scss';

class LoadingPage extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.loadingScreen}>
          <div className={styles.gradientOverlay} />
          <div className={styles.content}>
            <LoadingIndicator />
            <label>Taking you to <span>{this.props.linkType}</span> {this.props.linkValue} map...</label>
          </div>
        </div>
      </div>
    );
  }
}

LoadingPage.propTypes = {
  linkType: PropTypes.string,
  linkValue: PropTypes.string
};

export default LoadingPage;
