import polylabel from 'polylabel';
import bboxClip from '@turf/bbox-clip';

export const calculateLabels = (polygonData, bbox) => {
  const layers = {};
  polygonData.forEach(layer => {
    layers[layer.id] = {};
    layer.list.forEach(({mapElement}) => {
      const clippedShape = bboxClip(mapElement.shape, bbox);
      const coordinates = clippedShape.geometry.coordinates;
      if (clippedShape.geometry.type === 'Polygon') {
        if (coordinates.length > 0) {
          layers[layer.id][mapElement.id] = [polylabel(coordinates, 0.0001)];
        }
      }
      if (clippedShape.geometry.type === 'MultiPolygon') {
        layers[layer.id][mapElement.id] = (
          coordinates
            .filter(innerCoordinates => innerCoordinates.length > 0)
            .map(innerCoordinates => polylabel(innerCoordinates, 0.0001))
        );
      }
    });
  });
  return Promise.resolve(layers);
};
