/* eslint-disable react/no-danger */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import memoize from 'memoize-one';
import * as R from 'ramda';
import ThemeStyle, { PRIMARY_TEXT } from '../theme-style';
import { getContrastLogo, getNoteContent, getNotes } from '../../selectors/site';

import styles from './note.scss';

class Note extends Component {
  constructor(props) {
    super(props);
    this.sanitize = memoize(DOMPurify.sanitize);
  }
  render() {
    const { logo, noteContent, notes } = this.props;
    const params = (new URL(document.location)).searchParams;
    const noteType = params.get('type');
    const noteOptions = notes ? R.find(R.propEq(noteType, 'noteType'))(notes.links) : null;
    const hasRedirect = noteOptions && Object.prototype.hasOwnProperty.call(noteOptions, 'redirect');
    if (hasRedirect) {
      window.location.replace(noteOptions.redirect);
    }
    return (
      <Fragment>
        { noteOptions && !hasRedirect &&
          <div className={styles.container}>
            <ThemeStyle style={PRIMARY_TEXT}>
              <div className={styles.logo}>
                {logo && <img src={logo.imageUrl} alt="" />}
                <div>Public Viewer</div>
              </div>
              
            </ThemeStyle>
            <div className={styles.header}>{noteOptions.title}</div>
            <div className={styles.updated}>Last updated: {noteOptions.lastUpdated}</div>
            {noteContent && noteOptions.content.map(contentType => {
              const {title, content} = noteContent[contentType];
              return (
                <div key={title} className={styles.contentContainer}>
                  { title && <div className={styles.subheader}>{title}</div> }
                  { content &&
                    <div className={styles.content} dangerouslySetInnerHTML={{__html: this.sanitize(content)}} />
                  }
                </div>
              );
            })}
          </div>
        }
      </Fragment>
    );
  }
}

Note.propTypes = {
  legalContent: PropTypes.object,
  logo: PropTypes.object,
  noteContent: PropTypes.object,
  notes: PropTypes.object
};

const mapStateToProps = state => ({
  notes: getNotes(state),
  noteContent: getNoteContent(state),
  logo: getContrastLogo(state)
});

export default connect(mapStateToProps, {})(Note);
