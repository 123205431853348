import {
  GEOCODE_SUGGESTION_FETCH_SUCCESS,
  PLACES_SUGGESTION_FETCH_SUCCESS,
  SET_SEARCH_SUGGESTION_TERM,
  SET_SEARCH_SELECTION,
  CLEAR_SEARCH_SUGGESTIONS,
  SET_SEARCH_MARKER,
  SET_SEARCH_FOCUSED,
  SET_SELECTED_LAYER_ELEMENT
} from '../constants/action-types';
import initialState from '../store/initial-state';

const searchReducer = (state = initialState.search, action) => {
  switch (action.type) {
  case GEOCODE_SUGGESTION_FETCH_SUCCESS: {
    const geocodeSuggestions = action.payload.filter(result => result.place_id).map(
      suggestion => {
        return {
          label: suggestion.formatted_address,
          mainText: suggestion.formatted_address,
          value: suggestion.formatted_address,
          place_id: suggestion.place_id,
          location: suggestion.geometry.location,
          types: suggestion.types,
          type: 'address'
        };
      }
    );
    return { ...state, suggestions: { ...state.suggestions, geocoder: geocodeSuggestions }};
  }
  case PLACES_SUGGESTION_FETCH_SUCCESS: {
    const geocodeSuggestions = action.payload.filter(result => result.place_id).map(
      suggestion => {
        return {
          label: suggestion.description,
          mainText: suggestion.structured_formatting.main_text,
          secondaryText: suggestion.structured_formatting.secondary_text,
          value: suggestion.description,
          place_id: suggestion.place_id,
          type: 'address'
        };
      }
    );
    return { ...state, suggestions: { ...state.suggestions, places: geocodeSuggestions }};
  }
  case SET_SEARCH_SUGGESTION_TERM: {
    const { searchTerm } = action;
    return { ...state, searchTerm};
  }
  case CLEAR_SEARCH_SUGGESTIONS: {
    return { ...state, searchTerm: null, suggestions: {geocoder: null, places: null}};
  }
  case SET_SEARCH_MARKER: {
    return { ...state, showMarker: action.visible};
  }
  case SET_SEARCH_FOCUSED: {
    return { ...state, focused: action.focused};
  }
  case SET_SEARCH_SELECTION: {
    return { ...state, selectedLocation: action.location};
  }
  case SET_SELECTED_LAYER_ELEMENT: {
    return { ...state, selectedLayerElement: action.element};
  }
  default:
    return state;
  }
};

export default searchReducer;
