/* global google */
import React, { useEffect, useState } from 'react';
import { useGoogleMap } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

const MapControl = ({ children, position }) => {
  const map = useGoogleMap();

  const [container] = useState(document.createElement('div'));

  useEffect(() => {
    map?.controls[google.maps.ControlPosition[position]].push(container);
  }, [map, position, container]);

  const style = {
    fontSize: '1rem'  // Don't inherit font size.
  };
  const control = <div style={style}>{children}</div>;

  return createPortal(control, container);
};

MapControl.propTypes = {
  children: PropTypes.node,
  position: PropTypes.string
};

export default MapControl;
