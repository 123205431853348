/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import Icon from '@material-ui/core/Icon';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ArrowsIcon from '../shared/arrows';
import FilterCard from './filter-card';
import MapSearchAutocomplete from './map-search-autocomplete';
import {
  getFilters,
  getDateRanges,
  getSelectedDateRangeIndex,
  getInitialUrlFilter,
  getCustomDateRange
} from '../../selectors/filters';
import { getlayerFilters } from '../../selectors/ui';
import { getStreetView } from '../../selectors/map';
import { getSearchFocused } from '../../selectors/search';
import {
  setInitialUrlFilter,
  clearInitialUrlFilter,
  fetchFilterStructure,
  fetchDateRanges,
  selectDateRange
} from '../../actions/filter-actions';
import {
  openLayerFilters,
  closeLayerFilters,
  openDateRangeDialog
} from '../../actions/ui-actions';
import { renameFilterChildren } from '@utils/filter-utils';
import ThemeStyle, { PRIMARY_TEXT, SECONDARY_TEXT } from '../theme-style';
import styles from './filter-tray.scss';

class FilterTray extends Component {
  componentWillMount() {
    if (this.props.filters.length === 0) {
      this.props.fetchFilterStructure();
      this.props.fetchDateRanges();
    }
  }

  componentDidMount() {
    if (this.props.defaultOpen) {
      this.props.openLayerFilters();
    }

    const searchParams = window.location.search ? window.location.search.substr(1).split('=') : null;
    if (searchParams) {
      this.props.setInitialUrlFilter();
    } else {
      this.props.clearInitialUrlFilter();
    }
  }

  setDateRange = value => {
    this.props.selectDateRange(value);
    if (this.props.dateRanges[value].custom) {
      this.props.openDateRangeDialog();
    }
  };

  render() {
    const {
      mapRef,
      layerFilters,
      dateRanges,
      selectedDateRange,
      streetView,
      initialUrlFilter,
      customDateRange
    } = this.props;
    if (initialUrlFilter === null || selectedDateRange === null) {
      return null;
    }
    return (
      <div className={classNames(styles.filterTrayWrap, {[styles.filterHidden]: streetView.visible})}>
        {layerFilters.visible &&
          <div className={styles.filterModalOverlay} onClick={this.props.closeLayerFilters} />
        }
        <div className={classNames(
          styles.filterTrayContainer, {[styles.filterTrayContainerOpen]: layerFilters.visible}
        )}>
          <div
            className={styles.filterHeader}
            onClick={!layerFilters.visible ? this.props.openLayerFilters : null}
          >
            <div
              className={styles.collapseHeader}
              onClick={layerFilters.visible ? this.props.closeLayerFilters : this.props.openLayerFilters}
            >
              <span
                className={classNames(styles.headerButton, {[styles.collapseHeaderOpenButton]: layerFilters.visible})}
              >
                <ArrowsIcon />
              </span>
              {layerFilters.visible && <Fragment>COLLAPSE</Fragment>}
            </div>
            <div className={classNames(styles.headerItem, {[styles.headerItemCollapse]: !layerFilters.visible})}>
              <MapSearchAutocomplete
                mapRef={mapRef}
                onFocus={this.props.openLayerFilters}
                collapsed={!layerFilters.visible}
              />
            </div>
            <div className={classNames(styles.headerItem, {[styles.headerItemCollapse]: !layerFilters.visible})}>
              <Select
                value={selectedDateRange}
                onChange={event => this.setDateRange(event.target.value)}
                fullWidth={layerFilters.visible}
                className={styles.dateSelect}
                classes={{root: styles.dateInput}}
                disableUnderline
                input={
                  <Input
                    name="Date Range"
                    id="dateRange"
                    startAdornment={
                      <ThemeStyle style={PRIMARY_TEXT}>
                        <Icon className={styles.dateIcon}>calendar_today</Icon>
                      </ThemeStyle>
                    }
                  />
                }
              >
                {dateRanges.map((dateRange, index) => (
                  <MenuItem
                    key={index}
                    value={index}
                    classes={{root: styles.dateSelectOption, selected: styles.selectedDateOption}}
                    selected={selectedDateRange === index}
                  >
                    {selectedDateRange === index &&
                      <ThemeStyle style={SECONDARY_TEXT}>
                        <Icon>check</Icon>
                      </ThemeStyle>
                    }
                    {(dateRange.custom && customDateRange.start && customDateRange.end) ?
                      `${dateRange.description}
                      (${moment(customDateRange.start).format('MM/DD/YYYY')}-${moment(customDateRange.end).format('MM/DD/YYYY')})` :
                      dateRange.description
                    }
                  </MenuItem>
                ))};
              </Select>
            </div>
            <div className={classNames(styles.filterRuler, {[styles.collapsed]: !layerFilters.visible})} />
          </div>
          <div className={styles.filterContents}>
            {this.props.filters.length > 0 && this.props.filters.map(filter => (
              <div key={filter.id}>
                <FilterCard {...renameFilterChildren(filter)} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

FilterTray.propTypes = {
  clearInitialUrlFilter: PropTypes.func,
  closeLayerFilters: PropTypes.func,
  customDateRange: PropTypes.object,
  dateRanges: PropTypes.array,
  defaultOpen: PropTypes.bool,
  fetchDateRanges: PropTypes.func,
  fetchFilterStructure: PropTypes.func,
  filters: PropTypes.array,
  initialUrlFilter: PropTypes.bool,
  layerFilters: PropTypes.object,
  mapRef: PropTypes.object,
  openDateRangeDialog: PropTypes.func,
  openLayerFilters: PropTypes.func,
  selectDateRange: PropTypes.func,
  selectedDateRange: PropTypes.number,
  setInitialUrlFilter: PropTypes.func,
  streetView: PropTypes.object
};

const mapStateToProps = state => ({
  customDateRange: getCustomDateRange(state),
  dateRanges: getDateRanges(state),
  filters: getFilters(state),
  initialUrlFilter: getInitialUrlFilter(state),
  layerFilters: getlayerFilters(state),
  selectedDateRange: getSelectedDateRangeIndex(state),
  streetView: getStreetView(state),
  searchFocused: getSearchFocused(state)
});

export default connect(
  mapStateToProps,
  {
    setInitialUrlFilter,
    clearInitialUrlFilter,
    fetchFilterStructure,
    fetchDateRanges,
    selectDateRange,
    openLayerFilters,
    closeLayerFilters,
    openDateRangeDialog
  }
)(FilterTray);
