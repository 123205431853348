/* eslint-disable react/jsx-no-bind */
import React, { Fragment, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleMap } from '@react-google-maps/api';
import { toggleFilter } from '@actions/filter-actions';
import { openLegend, closeLegend } from '@actions/ui-actions';
import { getInitialUrlFilter } from '@selectors/filters';
import { getMapData, getVisibleMapDataList } from '@selectors/map';
import { getDrawingVisible } from '@selectors/map-selection';
import { getNotificationsVisible } from '@selectors/notifications';
import { getSearchMarker, getLinkedElement } from '@selectors/search';
import { getLegend, getDialog } from '@selectors/ui';
import MapControl from '@shared/map-control';
import MapData from '../map-data';
import MapLabels from '../map-labels';
import SearchMarker from '../search-marker';
import LargeMedia from '@shared/large-media';
import SmallMedia from '@shared/small-media';
import MapLegend from '../map-legend';
import MarkerOverlaps from '../marker-overlaps';
import LocationButton from '../location-button';
import MapToolbar from '../map-toolbar';
import MobileMapTools from '../mobile-map-tools';
import MobileClearButton from '../mobile-clear-button';
import MobileLegendButton from '../mobile-legend-button';
import DateRangeModal from '../date-range-modal';
import SubscribeModal from '../subscribe-modal';
import LocationError from '../location-button/location-error';
import Notifications from '../../notifications';

const MapComponents = () => {
  const dispatch = useDispatch();

  const map = useGoogleMap();

  const dialog = useSelector(getDialog);
  const drawingVisible = useSelector(getDrawingVisible);
  const initialUrlFilter = useSelector(getInitialUrlFilter);
  const legend = useSelector(getLegend);
  const linkedElement = useSelector(getLinkedElement);
  const mapData = useSelector(getMapData);
  const notificationsVisible = useSelector(getNotificationsVisible);
  const showSearchMarker = useSelector(getSearchMarker);
  const visibleDataList = useSelector(getVisibleMapDataList);

  if (!map) {
    return null;
  }

  const visibleDataTypes = [];
  if (!notificationsVisible) {
    Object.values(mapData).forEach(({id, visible, url, uiStyle: {searchable, linkable}}) => {
      if (linkable) {
        const params = new URLSearchParams(window.location.search);
        if (params.get('link_type') && params.get('link_type') === mapData[id].name && !visible) {
          dispatch(toggleFilter(id));
        }
      }
      if (searchable) {
        if (visible && url) {
          visibleDataTypes.unshift(
            <MapData key={`datatype-${id}`} id={id} mapRef={map} />
          );
        }
        visibleDataTypes.push(
          <MapData key={`datatype-highlight-${id}`} id={id} searchable mapRef={map} />
        );
      } else if (visible) {
        visibleDataTypes.push(
          <MapData key={`datatype-${id}`} id={id} mapRef={map} />
        );
      }
    });
  }

  const legendButtonAction = () => legend.visible ? dispatch(closeLegend()) : dispatch(openLegend());

  return (
    <Fragment>
      <SmallMedia>
        <MapControl position="RIGHT_TOP">
          <MobileLegendButton legendButtonAction={legendButtonAction} />
        </MapControl>
        <MapControl position="RIGHT_BOTTOM">
          <MobileMapTools legendButtonAction={legendButtonAction} mapRef={map} />
        </MapControl>
        {(drawingVisible || (initialUrlFilter && linkedElement)) &&
          <MapControl position="TOP_CENTER">
            <MobileClearButton />
          </MapControl>
        }
        <LocationError />
      </SmallMedia>
      <LargeMedia>
        <MapToolbar />
        <MapControl position="RIGHT_TOP">
          <Notifications />
        </MapControl>
        <MapControl position="RIGHT_TOP">
          <MapLegend defaultOpen mapData={visibleDataList}/>
        </MapControl>
        <MapControl position="RIGHT_BOTTOM">
          <LocationButton />
        </MapControl>
      </LargeMedia>
      {dialog.dateRangeVisible && <DateRangeModal />}
      {dialog.subscribeVisible && <SubscribeModal />}
      {!notificationsVisible && <MarkerOverlaps />}
      {visibleDataTypes}
      <MapLabels />
      {showSearchMarker && <SearchMarker />}
    </Fragment>
  );
};

export default memo(MapComponents);
