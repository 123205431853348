/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import { useGoogleMap } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import { openLegend, closeLegend } from '@actions/ui-actions';
import { openNotifications, closeNotifications } from '@actions/notification-actions';
import MapToolsSection from '@components/map/map-tools/map-tools-section';
import MapTypes from '@components/map/map-types';
import NotificationIcon from '@components/notifications/notification-icon';
import ThemeStyle, { SECONDARY_TEXT, INHERIT } from '@components/theme-style';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { getLegend } from '@selectors/ui';
import { getNotificationsVisible } from '@selectors/notifications';
import MapControl from '@shared/map-control';
import styles from './map-toolbar.scss';

const MapToolbar = () => {
  const dispatch = useDispatch();

  const map = useGoogleMap();

  const legend = useSelector(getLegend);
  const notificationsVisible = useSelector(getNotificationsVisible);

  if (map) {
    const legendButtonAction = () => legend.visible ? dispatch(closeLegend()) : dispatch(openLegend());
    const notificationButtonAction = () => notificationsVisible ? dispatch(closeNotifications()) : dispatch(openNotifications());
    return (
      <MapControl position="TOP_RIGHT">
        <div className={styles.toolbar}>
          <Tooltip title="Notifications" placement="bottom">
            <Button className={styles.button} onClick={notificationButtonAction}>
              <ThemeStyle style={notificationsVisible ? SECONDARY_TEXT : INHERIT}>
                <NotificationIcon selected={notificationsVisible} />
              </ThemeStyle>
            </Button>
          </Tooltip>
          <MapToolsSection />
          <div className={styles.divider} />
          <MapTypes />
          <div className={styles.divider} />
          <Tooltip title="Legend" placement="bottom">
            <Button
              className={styles.button}
              onClick={legendButtonAction}
            >
              <ThemeStyle style={legend.visible ? SECONDARY_TEXT : INHERIT}>
                <Icon>format_list_bulleted</Icon>
              </ThemeStyle>
            </Button>
          </Tooltip>
        </div>
      </MapControl>
    );
  }
  return null;
};

export default memo(MapToolbar);
