import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './arrows.scss';

const ArrowsIcon = props => {
  let {className = [], ...otherProps} = props;  // eslint-disable-line prefer-const
  if (!Array.isArray(className)) {
    className = [className];
  }
  return (
    <SvgIcon className={classNames([styles.arrows, ...className])} data-testid="arrows-icon" {...otherProps}>
      <path
        id="arrows-icon"
        d="M0 2.09L1.574.5 9 8l-7.426 7.5L0 13.91 5.851 8 0 2.09zm7 0L8.574.5 16 8l-7.426 7.5L7 13.91 12.851 8 7 2.09z"
      />
    </SvgIcon>
  );
};

ArrowsIcon.propTypes = {
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};

export default ArrowsIcon;
