// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-map-map-data-___point__selected-point{z-index:2000;pointer-events:none}
`, "",{"version":3,"sources":["webpack://./src/components/map/map-data/point.scss"],"names":[],"mappings":"AAAA,sDACE,YAAa,CACb,mBAAoB","sourcesContent":[".selected-point {\n  z-index: 2000;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selected-point": `src-components-map-map-data-___point__selected-point`,
	"selectedPoint": `src-components-map-map-data-___point__selected-point`
};
export default ___CSS_LOADER_EXPORT___;
