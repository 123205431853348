import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { clearInitialUrlFilter } from '../../../actions/filter-actions';
import { clearAreaSelection } from '../../../actions/map-actions';
import { getInitialUrlFilter } from '../../../selectors/filters';
import { getLinkedElement } from '../../../selectors/search';
import { getDrawingVisible } from '../../../selectors/map-selection';

import styles from './mobile-clear-button.scss';

class MobileClearButton extends Component {
  render() {
    const { linkedElement, initialUrlFilter } = this.props;
    if (linkedElement && initialUrlFilter) {
      return (
        <div className={styles.container}>
          <div
            className={styles.button}
            onClick={this.props.clearInitialUrlFilter}
            role="button"
            tabIndex={0}
          >CLEAR BOUNDARIES</div>
        </div>
      );
    } else if (this.props.drawingVisible) {
      return (
        <div
          className={styles.container}
          onClick={this.props.clearAreaSelection}
          role="button"
          tabIndex={0}
        >
          <div className={styles.button}>CLEAR RESULTS</div>
        </div>
      );
    }
    return null;
  }
}

MobileClearButton.propTypes = {
  clearAreaSelection: PropTypes.func,
  clearInitialUrlFilter: PropTypes.func,
  drawingVisible: PropTypes.bool,
  initialUrlFilter: PropTypes.bool,
  linkedElement: PropTypes.object
};

const mapStateToProps = state => ({
  drawingVisible: getDrawingVisible(state),
  initialUrlFilter: getInitialUrlFilter(state),
  linkedElement: getLinkedElement(state)
});

export default connect(mapStateToProps, {clearInitialUrlFilter, clearAreaSelection})(MobileClearButton);
