import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import FilterRow from '../filter-row';

import { getMapData } from '../../../selectors/map';

import { toggleFilter } from '../../../actions/filter-actions';

import styles from './filter-card-layers.scss';

const filtersDisabled = (filters, mapData) => (
  filters.some(filter => (
    (!filter.uiStyle.hidden && !mapData[filter.id].checked) ||
    (filter.children && filtersDisabled(filter.children, mapData))
  ))
);

class FilterCardLayers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      popover: null
    };

    this.allFiltersEnabled = this.allFiltersEnabled.bind(this);
    this.toggleFilterType = this.toggleFilterType.bind(this);
  }

  allFiltersEnabled = () => {
    const {filterChildren, mapData} = this.props;
    return !filtersDisabled(filterChildren, mapData);
  };

  toggleFilterType = () => {
    const allFilters = this.allFiltersEnabled();
    const {filterChildren, mapData} = this.props;
    filterChildren.filter(
      filter => !filter.uiStyle.hidden
    ).forEach(filter => {
      if ((!mapData[filter.id].checked && !allFilters) || (mapData[filter.id].checked && allFilters)) {
        this.props.toggleFilter(filter.id);
      }
      if (filter.children) {
        filter.children.filter(child => !child.uiStyle.hidden &&
          ((!mapData[child.id].checked && !allFilters) || (mapData[child.id].checked && allFilters))
        ).forEach(child => {
          this.props.toggleFilter(child.id);
        });
      }
    });
  };

  render() {
    const { filterChildren, id, mapData } = this.props;
    const { checked } = mapData[id];
    const allFilters = this.allFiltersEnabled();
    const visibleChildren = filterChildren && filterChildren.some(child => !child.uiStyle.hidden);
    return (
      <Fragment>
        <div className={styles.filterLinkWrapper}>
          <Button
            className={styles.filterLink}
            disabled={allFilters}
            key="select"
            onClick={!allFilters && this.toggleFilterType}
            size="small"
          >
            Select all
          </Button>
          <div className={styles.filterSeparator} />
          <Button
            className={styles.filterLink}
            disabled={!allFilters}
            key="clear"
            onClick={allFilters && this.toggleFilterType}
            size="small"
          >
            Clear
          </Button>
        </div>
        {
          filterChildren.filter(filter => !filter.uiStyle.hidden).map(filter => (
            <div key={`filter-body-${filter.id}`} className={styles.filterRowWrap}>
              {visibleChildren &&
                <FilterRow
                  {...filter}
                  mapData={mapData}
                  disabled={!checked}
                />
              }
            </div>
          ))
        }
      </Fragment>
    );
  }
}

FilterCardLayers.propTypes = {
  filterChildren: PropTypes.array,
  id: PropTypes.number,
  mapData: PropTypes.object,
  toggleFilter: PropTypes.func
};

const mapStateToProps = state => {
  return {
    mapData: getMapData(state)
  };
};

export default connect(mapStateToProps, { toggleFilter })(FilterCardLayers);
