import React, { memo } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SubLegend from './sub-legend';
import styles from './legend-items.scss';

const LegendItems = ({ mapData }) => (
  <List classes={{root: styles.list}}>
    {mapData.map(data => {
      if (data.uiStyle.showInLegend) {
        return (
          <ListItem key={data.id} classes={{root: styles.legendItem}}>
            <ListItemIcon classes={{root: styles.legendIcon}}>
              { data.uiStyle.legendIcon &&
                <img src={data.uiStyle.legendIcon} alt="" />
              }
            </ListItemIcon>
            <ListItemText
              classes={{root: styles.legendText}}
              primary={data.uiStyle.legendLabel || data.label}
            />
            {data.uiStyle.subLegendItems &&
              <SubLegend
                classes={{root: styles.legendSubIcon}}
                label={data.uiStyle.subLegendLabel}
                items={data.uiStyle.subLegendItems}
              />
            }
          </ListItem>
        );
      }
      return null;
    })}
  </List>
);

LegendItems.propTypes = {
  mapData: PropTypes.array
};

export default memo(LegendItems);
