import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { getUserLocationError } from '../../../../selectors/ui';
import { hideUserLocationError, showUserLocationError } from '../../../../actions/ui-actions';
import { setCenter, setUserLocation } from '../../../../actions/map-actions';
import { setSearchFocused } from '../../../../actions/search-actions';

import styles from './location-error.scss';

class LocationError extends Component {
  constructor() {
    super();
    this.state = {hide: null};
  }

  retryClick = () => {
    this.props.hideUserLocationError();
    navigator.geolocation.getCurrentPosition((pos) => {
      this.props.setCenter({lat: pos.coords.latitude, lng: pos.coords.longitude});
      this.props.setUserLocation({lat: pos.coords.latitude, lng: pos.coords.longitude});
      this.props.setSearchFocused(false);
    }, () => {
      this.props.showUserLocationError();
    });
  };

  //autohide breaks in mobile after clicking on the snackbar
  //so manually resetting the duration and hiding
  clickHandler = () => {
    if (this.state.hide) {
      clearTimeout(this.state.hide);
    }
    this.setState({
      hide: setTimeout(() => {
        this.props.hideUserLocationError();
      }, 2000)
    });
  };

  render() {
    return (
      <Fragment>
        <Snackbar
          classes={{anchorOriginBottomCenter: styles.snackbar}}
          open={this.props.userLocationError.visible}
          onClose={this.props.hideUserLocationError}
          autoHideDuration={2000}
          onClick={this.clickHandler}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={
            <span id="message-id">Your location couldn't be found</span>
          }
          action={
            <Button
              classes={{sizeSmall: styles.retryButton}}
              key="retry"
              size="small"
              onClick={this.retryClick}
            >
              RETRY
            </Button>
          }
        />
      </Fragment>
    );
  }
}

LocationError.propTypes = {
  hideUserLocationError: PropTypes.func,
  locationRetry: PropTypes.func,
  setCenter: PropTypes.func,
  setSearchFocused: PropTypes.func,
  setUserLocation: PropTypes.func,
  showUserLocationError: PropTypes.func,
  userLocationError: PropTypes.object
};

const mapStateToProps = state => {
  return {
    userLocationError: getUserLocationError(state)
  };
};

export default connect(mapStateToProps, {
  hideUserLocationError,
  setCenter,
  setUserLocation,
  setSearchFocused,
  showUserLocationError
})(LocationError);
