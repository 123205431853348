/* eslint-disable react/jsx-no-bind */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { toggleFilter } from '@actions/filter-actions';
import ThemeStyle, {PRIMARY_TEXT, INHERIT} from '@components/theme-style';
import Checkbox from '@material-ui/core/Checkbox';
import { getMapData } from '@selectors/map';
import { withIsSmallMedia } from '@shared/media';
import InfoPopup from '../info-popup';
import styles from './filter-row.scss';

class FilterRow extends Component {
  render() {
    const { id, label, children, disabled, isSmallMedia, mapData } = this.props;
    const { checked, uiStyle: { filterIcon: icon, infoText, infoLink } } = mapData[id];
    const highlighted = checked && !isSmallMedia && !disabled;
    return (
      <Fragment>
        <div className={styles.filterRow}>
          <Checkbox
            className={styles.checkbox}
            checked={checked || false}
            onChange={() => this.props.toggleFilter(id)}
            disableRipple
            disabled={disabled}
          />
          { icon &&
            <img src={icon} alt={icon} />
          }
          <ThemeStyle
            className={classNames(styles.filterLabel, {[styles.active]: highlighted})}
            style={highlighted ? PRIMARY_TEXT : INHERIT}
          >
            <label>{label}</label>
          </ThemeStyle>
          {infoText &&
            <InfoPopup label={label} className={styles.helpIcon}>
              {infoText}
              {infoLink &&
                <p><a href={infoLink.href} target="_blank" rel="noopener noreferrer">{infoLink.label}</a></p>
              }
            </InfoPopup>
          }
        </div>
        {children && children.filter(child => !child.uiStyle.hidden).length > 0 &&
          <div className={styles.filterRowChildren}>
            {children.filter(child => !child.uiStyle.hidden).map(child => (
              <div key={`subheader-${child.id}`} >
                <FilterRow
                  {...child}
                  mapData={mapData}
                  toggleFilter={this.props.toggleFilter}
                  disabled = {disabled || !checked}
                />
              </div>
            ))}
          </div>
        }
      </Fragment>
    );
  }
}

FilterRow.propTypes = {
  children: PropTypes.array,
  disabled: PropTypes.bool,
  id: PropTypes.number,
  isSmallMedia: PropTypes.bool,
  label: PropTypes.string,
  mapData: PropTypes.object,
  name: PropTypes.string,
  toggleFilter: PropTypes.func
};


const mapStateToProps = state => ({ mapData: getMapData(state) });

export default withIsSmallMedia(connect(mapStateToProps, { toggleFilter })(FilterRow));
