import * as R from 'ramda';
import { createSelector } from 'reselect';
import { createMuiTheme } from '@material-ui/core/styles';

export const getLogo = state => state.site.logo;
export const getContrastLogo = state => state.site.contrastLogo;
export const getStyle = state => state.site.style;
export const getTray = state => state.site.tray;
export const getLocalityString = state => state.site.map.localityString;
export const getDefaultCenter = state => state.site.map.defaultCenter;
export const getDisclaimerText = state => state.site.disclaimerText || null;
export const getNotes = state => state.site.notes || null;
export const getNoteContent = state => state.site.noteContent || null;
export const getNotificationFrequencyOptions = state => state.site.notificationsFrequency;
export const getNotificationEntityTypes = state => state.site.notificationEntityTypes;
export const getError4040Logo = state => state.site.error404Logo;
export const getFavicon = state => state.site.favicon;
export const getTitle = state => R.pathOr('dotMaps public viewer', ['site', 'title', 'label'], state);

const getDefaults = () => ({
  palette: {
    primary: { main: '#000000' },
    secondary: { main: '#888888' }
  }
});

export const getTheme = createSelector(
  [getStyle],
  style => {
    const theme = getDefaults();
    if (style) {
      if (style.root) {
        const { root } = style;
        if (root.primaryColor) {
          theme.palette.primary.main = root.primaryColor;
        }
        if (root.secondaryColor) {
          theme.palette.secondary.main = root.secondaryColor;
        }
      }
    }
    return createMuiTheme(theme);
  }
);

export const getLogoUrl = createSelector(
  [getStyle],
  style => style.logo
);
