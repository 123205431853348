import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Carousel } from 'react-responsive-carousel';
import LegendItems from './legend-items';
import styles from './mobile-carousel.scss';

export default class MobileCarousel extends Component {
  shouldComponentUpdate(nextProps) {
    const { layers } = this.props;
    const nextLayers = nextProps.layers;
    if (
      layers.length === nextLayers.length
      && layers.every((layer, index) => {
        const nextLayer = nextLayers[index];
        return (
          layer.id === nextLayer.id
          && layer.uiStyle === nextLayer.uiStyle
          && layer.label === nextLayer.label
        );
      })
    ) {
      return false;
    }
    return true;
  }

  render() {
    const { layers } = this.props;
    const chunkedFilters = layers.reduce(
      (chunks, element, index) => {
        chunks[Math.floor(index / 6)].push(element);
        return chunks;
      },
      Array.from({length: Math.ceil(layers.length / 6)}, () => [])
    );
    return (
      <Carousel
        className={classNames(styles.legendCarousel, {[styles.single]: chunkedFilters.length < 2})}
        showThumbs={false}
      >
        {chunkedFilters.map((chunk, index) => {
          const left = chunk.slice(0, 3);
          const right = chunk.slice(3, 6);
          return (
            <div key={index} className={styles.carouselContent} >
              {left.length > 0 && <LegendItems mapData={left} />}
              {right.length > 0 && <LegendItems mapData={right} />}
            </div>
          );
        })}
      </Carousel>
    );
  }
}

MobileCarousel.propTypes = {
  layers: PropTypes.array
};
