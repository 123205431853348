/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Truncate from 'react-truncate';
import PropTypes from 'prop-types';
import { selectItem, setHighlight, clearHighlight} from '@actions/map-actions';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import { getMapData } from '@selectors/map';
import { getSelectedArea } from '@selectors/map-selection';
import LargeMedia from '@shared/large-media';
import SmallMedia from '@shared/small-media';
import {
  getTypes, getTitles, TITLE_FIELD, HEADER_PRE, HEADER_POST
} from '@utils/map-data-utils';
import { zoom } from '@utils/map-utils';
import ThemeStyle, {SECONDARY_BACKGROUND} from '../../../theme-style';
import styles from './identify-card.scss';

class IdentifyCard extends Component {
  constructor(props) {
    super(props);
    this.dataClick = this.dataClick.bind(this);
    this.setHighlight = this.setHighlight.bind(this);
    this.clearHighlight = this.clearHighlight.bind(this);
  }

  componentWillUnmount() {
    this.clearHighlight();
  }

  dataClick() {
    const { index, selectedArea } = this.props;
    this.props.selectItem(selectedArea, index);
    if (this.props.onClick) {
      this.props.onClick(index);
    }
  }

  setHighlight() {
    const {layerData, data} = this.props.detailData;
    this.props.setHighlight('identify', layerData.id, data.id);
  }

  clearHighlight() {
    this.props.clearHighlight('identify');
  }

  zoomToDetail = event => {
    const { mapRef, detailData: { data: { bbox } } } = this.props;
    zoom(event, mapRef, bbox);
  };

  render() {
    const { mapData, detailData } = this.props;
    const { layerData } = detailData;
    const types = getTypes(mapData, detailData);

    const {
      [TITLE_FIELD]: title,
      [HEADER_PRE]: preHeader,
      [HEADER_POST]: postHeader
    } = getTitles(mapData, detailData);

    let name;
    let pre;
    let post;
    if (title) {
      name = title;
    } else {
      name = types.pop();
    }
    if (preHeader) {
      pre = preHeader;
    } else {
      pre = types.pop();
    }
    if (postHeader) {
      post = postHeader;
    }

    const iconUrl = layerData.uiStyle.marker || layerData.uiStyle.legendIcon;

    return (
      <Fragment>
        <LargeMedia>
          <div
            className={styles.title}
            onMouseEnter={this.setHighlight}
            onMouseLeave={this.clearHighlight}
            onClick={this.dataClick}
          >
            {iconUrl &&
              <div className={styles.markerIcon}>
                <img src={iconUrl} alt="" />
              </div>
            }
            <div className={styles.arrow}>
              <Icon>arrow_right</Icon>
            </div>
            <div className={styles.type}>{pre}</div>
            <div className={styles.name}><Truncate lines={1}>{name}</Truncate></div>
            <div className={styles.type}>{post}</div>
          </div>
        </LargeMedia>
        <SmallMedia>
          <ThemeStyle style={SECONDARY_BACKGROUND}>
            <Fab color="secondary" classes={{root: styles.zoomButton}} onClick={this.zoomToDetail}>
              <Icon>zoom_in</Icon>
            </Fab>
          </ThemeStyle>
          <div onClick={this.dataClick}>
            <div className={styles.mobile}>
              <div className={styles.mobileTitle}>
                { name &&
                  <div className={styles.name}>
                    <Truncate lines={1}>{name}</Truncate>
                  </div>
                }
                {pre &&
                  <div className={styles.type}>{pre}</div>
                }
                {post &&
                  <div>{post}</div>
                }
              </div>
              <div className={styles.mobileArrow}>
                <Icon>arrow_right</Icon>
              </div>
            </div>
          </div>
        </SmallMedia>
      </Fragment>
    );
  }
}

IdentifyCard.propTypes = {
  clearHighlight: PropTypes.func,
  detailData: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  mapData: PropTypes.object,
  mapRef: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  selectItem: PropTypes.func,
  selectedArea: PropTypes.object,
  setHighlight: PropTypes.func
};

const mapStateToProps = state => {
  return {
    mapData: getMapData(state),
    selectedArea: getSelectedArea(state)
  };
};

export default connect(mapStateToProps, {selectItem, setHighlight, clearHighlight})(IdentifyCard);
