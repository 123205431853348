/* eslint-disable quote-props */
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import distance from '@turf/distance';
import pointToLineDistance from '@turf/point-to-line-distance';
import booleanDisjoint from '@turf/boolean-disjoint';
import { point, lineString, polygon, multiPolygon } from '@turf/helpers';


export const bboxIntersects = (first, second) => (
  !(first[0] > second[2] || first[1] > second[3] || second[0] > first[2] || second[1] > first[3])
);

export const pointInBbox = (bbox, pnt) => (
  !(bbox[0] > pnt[0] || bbox[1] > pnt[1] || pnt[0] > bbox[2] || pnt[1] > bbox[3])
);

export const bboxCombine = (first, second) => [
  Math.min(first[0], second[0]),
  Math.min(first[1], second[1]),
  Math.max(first[2], second[2]),
  Math.max(first[3], second[3])
];

export const googleMapsBoundsToBbox = bounds => {
  if (!bounds) {
    return [];
  }
  const box = bounds.toJSON();
  return [
    box.west,
    box.south,
    box.east,
    box.north
  ];
};

export const bboxToGoogleMapsBounds = bbox => {
  return {
    west: bbox[0],
    south: bbox[1],
    east: bbox[2],
    north: bbox[3]
  };
};

export const getBboxCenter = (bbox) => ({
  type: 'Feature',
  geometry: {
    type: 'Point',
    coordinates: [(bbox[0] + bbox[2]) * 0.5, (bbox[1] + bbox[3]) * 0.5]
  }
});

export const pointDistanceFunctions = {
  'Point': (shape, pnt, options) => distance(pnt, shape.coordinates, options),
  'LineString': (shape, pnt, options) => pointToLineDistance(pnt, shape.coordinates, options),
  'MultiLineString': (
    (shape, pnt, options) => Math.min(
      ...shape.coordinates.map(lineStringParam => pointToLineDistance(pnt, lineStringParam, options))
    )
  ),
  'Polygon': (
    (shape, pnt, options) => booleanPointInPolygon(pnt, shape) ? 0 : (
      Math.min(
        ...shape.coordinates.map(ring => pointToLineDistance(pnt, ring, options))
      )
    )
  ),
  'MultiPolygon': (
    (shape, pnt, options) => booleanPointInPolygon(pnt, shape) ? 0 : (
      Math.min(...shape.coordinates.reduce(
        (acc, polygonParam) => [...acc, ...polygonParam.map(ring => pointToLineDistance(pnt, ring, options))], []
      ))
    )
  )
};

export const polygonIntersectionFunctions = {
  'Point': (shape, drawnPolygon) => booleanPointInPolygon(point(shape), polygon(drawnPolygon)),
  'LineString': (shape, drawnPolygon) => !booleanDisjoint(lineString(shape), polygon(drawnPolygon)),
  'Polygon': (shape, drawnPolygon) => !booleanDisjoint(polygon(shape), polygon(drawnPolygon)),
  'MultiPolygon': (shape, drawnPolygon) => !booleanDisjoint(multiPolygon(shape), polygon(drawnPolygon))
};
