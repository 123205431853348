/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDrawingOverlay } from '../../../selectors/ui';
import { hideDrawingOverlay } from '../../../actions/ui-actions';
import Icon from '@material-ui/core/Icon';
import styles from './drawing-overlay.scss';

class DrawingOverlay extends Component {
  render() {
    if (this.props.drawingOverlay.visible) {
      return (
        <div
          className={styles.overlayContainer}
          onClick={this.props.hideDrawingOverlay}
          role="button"
          tabIndex={0}
        >
          <Icon>touch_app</Icon>
          <label>Draw an area on the map to see results.</label>
        </div>
      );
    }
    return null;
  }
}

DrawingOverlay.propTypes = {
  drawingOverlay: PropTypes.object,
  hideDrawingOverlay: PropTypes.func
};

const mapStateToProps = state => ({
  drawingOverlay: getDrawingOverlay(state)
});

export default connect(mapStateToProps, {hideDrawingOverlay})(DrawingOverlay);
