import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { closeSnackbar } from '../../actions/snackbar-actions';
import styles from './info-snackbar.scss';

const InfoSnackbar = () => {
  const dispatch = useDispatch();
  const { message } = useSelector(state => state.snackbar);
  const onClose = useCallback(() => dispatch(closeSnackbar()), [dispatch]);

  return (
    <Snackbar
      classes={{anchorOriginBottomCenter: styles.snackbar}}
      open={message !== null}
      onClose={onClose}
      autoHideDuration={2000}
      message={message}
    />
  );
};

export default memo(InfoSnackbar);
