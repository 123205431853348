import React, { Fragment, memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import LargeMedia from '@shared/large-media';
import SmallMedia from '@shared/small-media';
import {
  getSelectedElement,
  getSelectionElementsCount,
  getDrawingVisible
} from '@selectors/map-selection';
import { getMobileMaxDetails } from '@selectors/ui';
import InfoDetail from './info-detail';
import InfoIdentify from './info-identify';

const InfoTray = ({ mapRef }) => {
  const detailSelection = useSelector(getSelectedElement);
  const drawingVisible = useSelector(getDrawingVisible);
  const mobileMaxDetails = useSelector(getMobileMaxDetails);
  const selectionCount = useSelector(getSelectionElementsCount);

  return (
    <Fragment>
      <LargeMedia>
        { detailSelection ?
          <InfoDetail detailData={detailSelection} mapRef={mapRef} /> :
          <InfoIdentify mapRef={mapRef} />
        }
      </LargeMedia>
      <SmallMedia>
        { ((selectionCount > 0 && !mobileMaxDetails.visible) || (selectionCount === 0 && drawingVisible)) &&
          <InfoIdentify mapRef={mapRef} />
        }
        { mobileMaxDetails.visible && detailSelection &&
          <InfoDetail detailData={detailSelection} mapRef={mapRef} />
        }
      </SmallMedia>
    </Fragment>
  );
};

InfoTray.propTypes = {
  mapRef: PropTypes.object.isRequired
};

export default memo(InfoTray);
