import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import InfoPopup from '../info-popup';
import FilterCardLayers from '../filter-card-layers';

import { toggleFilter } from '../../../actions/filter-actions';

import { getMapData } from '../../../selectors/map';

import styles from './mobile-card.scss';

class MobileCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popover: null
    };
  }

  render() {
    const { filterChildren, id, label, mapData} = this.props;
    const { uiStyle: { infoText, infoLink } } = mapData[id];
    const visibleChildren = filterChildren && filterChildren.some(child => !child.uiStyle.hidden);
    return (
      <div className={styles.filterWrap}>
        <div className={styles.filterCard}>
          <div className={styles.filterTitle}>
            <label>{label}</label>
            {infoText &&
              <InfoPopup label={label} className={styles.helpIcon}>
                {infoText}
                {infoLink &&
                  <p><a href={infoLink.href} target="_blank" rel="noopener noreferrer">{infoLink.label}</a></p>
                }
              </InfoPopup>
            }
          </div>
          {visibleChildren &&
            <div>
              <FilterCardLayers
                id={id}
                filterChildren={filterChildren}
                mapData={mapData}
              />
            </div>
          }
        </div>
        <div className={styles.filterClose}>
          <div onClick={this.props.onClose} role="button" tabIndex={0}>CLOSE</div>
        </div>
      </div>
    );
  }
}

MobileCard.propTypes = {
  filterChildren: PropTypes.array,
  id: PropTypes.number,
  label: PropTypes.string,
  mapData: PropTypes.object,
  onClose: PropTypes.func,
  toggleFilter: PropTypes.func
};

const mapStateToProps = state => {
  return {
    mapData: getMapData(state)
  };
};

export default connect(mapStateToProps, {toggleFilter})(MobileCard);
