/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Cookie from 'universal-cookie';
import Icon from '@material-ui/core/Icon';
import Drawer from '@material-ui/core/Drawer';
import ThemeStyle, {PRIMARY_TEXT} from '../theme-style';
import NotificationIcon from '../notifications/notification-icon';
import { getMobileLinks } from '../../selectors/ui';
import { getContrastLogo, getNotes, getDisclaimerText } from '../../selectors/site';
import { closeMobileLinks } from '../../actions/ui-actions';
import { openDisclaimer } from '../../actions/map-actions';
import { openNotifications } from '../../actions/notification-actions';
import styles from './menu-tray.scss';

class MenuTray extends Component {
  constructor(props) {
    super(props);
    this.state = {notificationMessageOpen: false, cookie: new Cookie(), newTooltipSeen: false};
  }

  componentWillMount() {
    if (this.state.cookie.get('notificationNewTooltipSeen')) {
      this.setState({newTooltipSeen: true});
    }
  }

  componentDidMount() {
    this.state.cookie.set('notificationNewTooltipSeen', true, { path: '/' });
  }

  toggleNotificationMessage = () => {
    this.setState({notificationMessageOpen: !this.state.notificationMessageOpen});
  };

  openNotifications = () => {
    this.props.openNotifications();
    this.props.closeMobileLinks();
  };

  render() {
    const { logo, notes, disclaimer } = this.props;
    return (
      <div>
        <Drawer
          anchor="left"
          open={this.props.mobileLinks.visible}
          onClose={this.props.closeMobileLinks}
          ModalProps={{
            BackdropProps: {
              classes: {root: styles.drawer}
            }
          }}
        >
          <div className={styles.linksContainer}>
            <ThemeStyle style={PRIMARY_TEXT} className={styles.top}>
              <div
                className={styles.closeButton}
                onClick={this.props.closeMobileLinks}
                role="button"
                tabIndex={0}
              >
                <Icon>close</Icon>
              </div>
              { logo &&
                <div className={styles.logo}>
                  { logo && logo.imageUrl && !logo.linkUrl &&
                    <img src={logo.imageUrl} alt="" />
                  }
                  { logo && logo.imageUrl && logo.linkUrl &&
                    <a href={logo.linkUrl} target="_blank" rel="noopener noreferrer"><img src={logo.imageUrl} alt="" /></a>
                  }
                </div>
              }
            </ThemeStyle>
            <div className={styles.notification} onClick={this.openNotifications}>
              <NotificationIcon />
              <div className={styles.notificationLabel}>
                Receive Activity Updates
              </div>
              {!this.state.newTooltipSeen &&
                <div className={styles.notificationNew}>NEW</div>
              }
            </div>
            <div className={styles.links}>
              {disclaimer && <span onClick={this.props.openDisclaimer}>Disclaimer</span>}
              {notes && notes.links &&
                notes.links.map(link => (
                  <div key={link.noteType}><Link to={`/notes?type=${link.noteType}`}>{link.label}</Link></div>
                ))
              }
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}

MenuTray.propTypes = {
  closeMobileLinks: PropTypes.func,
  disclaimer: PropTypes.string,
  logo: PropTypes.object,
  mobileLinks: PropTypes.object,
  notes: PropTypes.object,
  openDisclaimer: PropTypes.func,
  openNotifications: PropTypes.func
};

const mapStateToProps = state => ({
  mobileLinks: getMobileLinks(state),
  logo: getContrastLogo(state),
  notes: getNotes(state),
  disclaimer: getDisclaimerText(state)
});

export default connect(mapStateToProps, {
  closeMobileLinks,
  openDisclaimer,
  openNotifications
})(MenuTray);
