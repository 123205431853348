/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { Fragment, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayView } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { setCenter, setUserLocation } from '@actions/map-actions';
import { setSearchFocused } from '@actions/search-actions';
import { showUserLocationError } from '@actions/ui-actions';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { getUserLocation } from '@selectors/map';
import LargeMedia from '@shared/large-media';
import SmallMedia from '@shared/small-media';
import LocationError from './location-error';
import ThemeStyle, { PRIMARY_TEXT, INHERIT } from '../../theme-style';
import styles from './location-button.scss';

const LocationButton = ({ search }) => {
  const dispatch = useDispatch();
  const userLocation = useSelector(getUserLocation);

  const onClick = () => {
    dispatch(showUserLocationError());
    navigator.geolocation.getCurrentPosition(pos => {
      dispatch(setCenter({ lat: pos.coords.latitude, lng: pos.coords.longitude }));
      dispatch(setUserLocation({ lat: pos.coords.latitude, lng: pos.coords.longitude }));
      dispatch(setSearchFocused(false));
    }, () => {
      dispatch(showUserLocationError());
    });
  };

  return (
    <Fragment>
      <div
        className={!search ? styles.button : styles.searchEmbededButton}
        onClick={event => onClick(event)}
      >
        <SmallMedia>
          <ThemeStyle style={userLocation ? PRIMARY_TEXT : INHERIT}>
            <Icon>my_location</Icon>
          </ThemeStyle>
        </SmallMedia>
        <LargeMedia>
          <Tooltip title="Use my location" placement="bottom">
            <ThemeStyle style={userLocation ? PRIMARY_TEXT : INHERIT}>
              <Icon>my_location</Icon>
            </ThemeStyle>
          </Tooltip>
        </LargeMedia>
      </div>
      {userLocation &&
        <OverlayView
          mapPaneName={OverlayView.FLOAT_PANE}
          position={userLocation}
          zIndex={20000}
        >
          <div className={styles.locationMarker} role="presentation" />
        </OverlayView>
      }
      <LargeMedia>
        <LocationError />
      </LargeMedia>
    </Fragment>
  );
};

LocationButton.propTypes = {
  search: PropTypes.bool
};

export default memo(LocationButton);
