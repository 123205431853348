import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '@material-ui/core/Icon';
import InfoTray from '../info-tray';

import { getMapData } from '../../../selectors/map';
import {
  getSelectedElement,
  getSelectionElements
} from '../../../selectors/map-selection';

import { getTray } from '../../../selectors/site';
import { getDetailTray } from '../../../selectors/ui';

import { collapseDetailTray, showDetailTray } from '../../../actions/ui-actions';

import styles from './collapsable-info-tray.scss';

class CollapsableInfoTray extends Component {
  componentDidUpdate(prevProps) {
    const {mapSelection} = this.props;
    if (mapSelection !== prevProps.mapSelection) {
      this.props.showDetailTray();
    }
  }

  getTrayProps = () => {
    const { detailSelection, detailTray, mapSelection, trayConfig } = this.props;
    if (detailSelection && trayConfig.width && mapSelection && !detailTray.collapsed) {
      return { width: trayConfig.width };
    }
    return {};
  };

  render() {
    const { detailTray, mapRef, mapSelection } = this.props;
    return (
      <div
        id="info-tray-wrapper"
        className={classNames(
          styles.leftTrayWrap,
          {[styles.leftTrayWrapOpen]: mapSelection && !detailTray.collapsed}
        )}
        style={{...this.getTrayProps()}}
      >
        <div
          id="info-tray-content"
          className={classNames(
            styles.leftTrayContent,
            {[styles.leftTrayWrapOpen]: mapSelection && !detailTray.collapsed}
          )}
          style={{...this.getTrayProps()}}
        >
          { this.props.mapSelection &&
            <Fragment>
              {!detailTray.collapsed && <InfoTray mapRef={mapRef} /> }
              {!detailTray.collapsed ?
                <div
                  className={styles.leftTrayCollapseControl}
                  onClick={this.props.collapseDetailTray}
                  role="button"
                  tabIndex={0}
                >
                  <Icon>arrow_left</Icon>
                </div> :
                <div
                  className={styles.leftTrayCollapseControl}
                  onClick={this.props.showDetailTray}
                  role="button"
                  tabIndex={0}
                >
                  <Icon>arrow_right</Icon>
                </div>
              }
            </Fragment>
          }
        </div>
      </div>
    );
  }
}

CollapsableInfoTray.propTypes = {
  collapseDetailTray: PropTypes.func,
  detailSelection: PropTypes.object,
  detailTray: PropTypes.object,
  mapRef: PropTypes.object.isRequired,
  mapSelection: PropTypes.array,
  showDetailTray: PropTypes.func,
  trayConfig: PropTypes.object
};

const mapStateToProps = state => ({
  detailSelection: getSelectedElement(state),
  detailTray: getDetailTray(state),
  mapData: getMapData(state),
  mapSelection: getSelectionElements(state),
  trayConfig: getTray(state)
});

export default connect(mapStateToProps, { collapseDetailTray, showDetailTray })(CollapsableInfoTray);
