import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';

import { setAuthSecret } from '../../../actions/map-actions';

import styles from './dev-login.scss';

class LoadingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      secret: ''
    };

    this.updateSecret = this.updateSecret.bind(this);
    this.submitSecret = this.submitSecret.bind(this);
  }

  updateSecret(event) {
    const {target: {value}} = event;
    this.setState({secret: value});
  }

  submitSecret() {
    this.props.setAuthSecret(this.state.secret);
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.loadingScreen}>
          <div className={styles.gradientOverlay} />
          <div className={styles.content}>
            <Paper>
              <form className={styles.login} action="#" onSubmit={this.submitSecret}>
                <div className={styles.logo}>
                  <img alt="" />
                </div>
                <span className={styles.text}>
                  Please enter the password to continue using the application in this environment.
                </span>
                <Input
                  id="dev-secret"
                  placeholder="Enter password"
                  type="password"
                  value={this.state.secret}
                  onChange={this.updateSecret}
                  classes={{
                    root: styles.textField,
                    input: styles.input
                  }}
                />
                <div className={styles.loginButton}>
                  <Button type="submit" disableRipple>
                    Log In
                  </Button>
                </div>
              </form>
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

LoadingPage.propTypes = {
  linkType: PropTypes.string,
  linkValue: PropTypes.string,
  setAuthSecret: PropTypes.func
};

export default connect(null, {setAuthSecret})(LoadingPage);
