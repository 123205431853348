// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-global-backdrop-___global-backdrop__backdrop{display:none;position:fixed;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,0.8);z-index:100;touch-action:none}
`, "",{"version":3,"sources":["webpack://./src/components/global-backdrop/global-backdrop.scss"],"names":[],"mappings":"AAGA,6DACE,YAAa,CACb,cAAe,CACf,KAAM,CACN,MAAO,CACP,OAAQ,CACR,QAAS,CACT,gCAAoC,CACpC,WAAY,CACZ,iBAAkB","sourcesContent":["@import '../../styles/mixins';\n@import '../../styles/vars';\n\n.backdrop {\n  display: none;\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.8);\n  z-index: 100;\n  touch-action: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `src-components-global-backdrop-___global-backdrop__backdrop`
};
export default ___CSS_LOADER_EXPORT___;
