import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { closeLegend, closeMobileMaxDetails } from '@actions/ui-actions';
import Drawer from '@material-ui/core/Drawer';
import { getStreetView } from '@selectors/map';
import { getSelectionElements, getSelectedElement } from '@selectors/map-selection';
import { getNotificationsVisible } from '@selectors/notifications';
import { getLegend, getlayerFilters, getMobileMaxDetails } from '@selectors/ui';
import { withOnlyLargeMedia, withOnlySmallMedia } from '@shared/media';
import SmallMedia from '@shared/small-media';
import FilterTray from '../../filter-tray';
import InfoTray from '../info-tray';
import MenuTray from '../../menu-tray';
import MobileMapLegend from '../map-legend/mobile';
import CollapsableInfoTray from '../collapsable-info-tray';
import DrawingMode from '../../notifications/drawing-mode';
import ShapeError from '../../notifications/shape-error';
import styles from './map-trays.scss';

const LargeInfoTray = withOnlyLargeMedia(CollapsableInfoTray);
const LargeFilterTray = withOnlyLargeMedia(FilterTray);
const LargeDrawingMode = withOnlyLargeMedia(DrawingMode);
const SmallMenuTray = withOnlySmallMedia(MenuTray);

class MapTrays extends Component {
  render() {
    const { mapRef, streetView, notificationsVisible, layerFilters } = this.props;

    return (
      <Fragment>
        { !notificationsVisible &&
        <div id="filter-tray" className={styles.mapFilterTray}>
          <LargeFilterTray mapRef={mapRef} defaultOpen={layerFilters.defaultOpen} />
        </div>
        }
        <LargeDrawingMode />
        <ShapeError />
        {mapRef && !streetView.visible && !notificationsVisible &&
          <LargeInfoTray mapRef={mapRef} />
        }
        <SmallMenuTray />
        <SmallMedia>
          {!streetView.visible && !notificationsVisible &&
            <div className={styles.mapDrawers}>
              <Drawer
                variant="persistent"
                anchor="bottom"
                open={this.props.legend.visible}
                onClose={this.props.closeLegend}
              >
                <MobileMapLegend />
              </Drawer>
              {this.props.mapSelection &&
                <Drawer
                  variant="permanent"
                  anchor="bottom"
                  open={this.props.mobileMaxDetails.visible}
                  onClose={this.props.closeMobileMaxDetails}
                  classes={{
                    paper: classNames(
                      styles.mobileDetailDrawer,
                      {[styles.mobileFullDetailDrawer]: this.props.mobileMaxDetails.visible}
                    )
                  }}
                >
                  {this.props.mapSelection && <InfoTray mapRef={mapRef} />}
                </Drawer>
              }
            </div>
          }
        </SmallMedia>
      </Fragment>
    );
  }
}

MapTrays.propTypes = {
  closeLegend: PropTypes.func,
  closeMobileMaxDetails: PropTypes.func,
  drawingVisible: PropTypes.bool,
  layerFilters: PropTypes.object,
  legend: PropTypes.object,
  mapRef: PropTypes.object.isRequired,
  mapSelection: PropTypes.array,
  mobileMaxDetails: PropTypes.object,
  notificationsVisible: PropTypes.bool,
  streetView: PropTypes.object
};

const mapStateToProps = state => ({
  legend: getLegend(state),
  mapSelection: getSelectionElements(state),
  layerFilters: getlayerFilters(state),
  mobileMaxDetails: getMobileMaxDetails(state),
  streetView: getStreetView(state),
  detailData: getSelectedElement(state),
  notificationsVisible: getNotificationsVisible(state)
});

export default connect(mapStateToProps, {closeLegend, closeMobileMaxDetails})(MapTrays);
