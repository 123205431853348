import React from 'react';

const ConfirmIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="125px" height="100px" viewBox="-16.7 -8.3 130 100">
    <g fill="none" fillRule="evenodd">
      <path d="M-8-16H92V84H-8z" />
      <path
        fill="#5367D7"
        fillRule="nonzero"
        d={`
            M75.333.667H8.667C4.083.667.375 4.417.375 9L.333 59c0 4.583 3.75 8.333 8.334
            8.333h66.666c4.584 0 8.334-3.75 8.334-8.333V9c0-4.583-3.75-8.333-8.334-8.333zM71.167
            59H12.833a4.179 4.179 0 0 1-4.166-4.167v-37.5l28.916 18.084a8.253 8.253 0 0 0 8.834
            0l28.916-18.084v37.5A4.179 4.179 0 0 1 71.167 59zM42 29.833L8.667 9h66.666L42 29.833z
           `}
      />
      <path
        fill="#5367D7"
        fillOpacity=".2"
        d={`
            M71.167 59H12.833a4.179 4.179 0 0 1-4.166-4.167v-37.5l28.916 18.084a8.253
            8.253 0 0 0 8.834 0l28.916-18.084v37.5A4.179 4.179 0 0 1 71.167 59z
           `}
      />
      <path
        fill="#5367D7"
        fillOpacity=".2"
        d="M42 29.833L8.667 9h66.666z"
      />
      <g fillRule="nonzero">
        <path
          fill="#35C470"
          d="M77 34c-13.8 0-25 11.2-25 25s11.2 25 25 25 25-11.2 25-25-11.2-25-25-25z"
        />
        <path
          fill="#FFF"
          d={`
              M70.225 69.725L61.25 60.75a2.49 2.49 0 0 1 0-3.525 2.49 2.49 0 0 1 3.525
              0l7.225 7.2 17.2-17.2a2.49 2.49 0 0 1 3.525 0 2.49 2.49 0 0 1 0 3.525L73.75
              69.725c-.95.975-2.55.975-3.525 0z
            `}
        />
      </g>
    </g>
  </svg>
);

export default ConfirmIcon;
