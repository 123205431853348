/* global google */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import classNames from 'classnames';
import Header from '../header';
import Footer from '../footer';
import MobileFilter from '../filter-tray/mobile-filter';
import MapComponents from './map-components';
import MapTrays from './map-trays';
import Disclaimer from './disclaimer';
import DrawingOverlay from './drawing-overlay';
import MobileNotifications from '../notifications/mobile-notifications';
import NotificationTooltip from '../notifications/notification-tooltip';
import { StreetViewPanorama } from '@react-google-maps/api';
import { setViewport, setStreetViewVisible, selectArea } from '@actions/map-actions';
import { setSearchMarker } from '@actions/search-actions';
import {
  getDevSecret,
  getLoading,
  getMapTypeId,
  getMeasureToolActive,
  getRequiresDevSecret,
  getStreetView,
  getTraffic,
  getViewport
} from '@selectors/map';
import { getSearchMarker, getSearchFocused } from '@selectors/search';
import { getNotificationsVisible } from '@selectors/notifications';
import DotMapsGoogleMap from '@shared/dotmaps-google-map';
import LoadingIndicator from '@shared/loading-indicator';
import { withOnlyLargeMedia, withOnlySmallMedia } from '@shared/media';
import { remToPx } from '@utils/dom-utils';
import styles from './map.scss';

const LargeFooter = withOnlyLargeMedia(Footer);
const SmallDrawingOverlay = withOnlySmallMedia(DrawingOverlay);
const SmallHeader = withOnlySmallMedia(Header);
const SmallFilter = withOnlySmallMedia(MobileFilter);
const SmallNotifications = withOnlySmallMedia(MobileNotifications);

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {viewportSet: false, initialElementLinked: false};
    this.updateViewport = debounce(this.updateViewport.bind(this), 600);
  }

  componentDidUpdate(prevProps) {
    const { streetView, notificationsVisible } = this.props;
    const { mapRef = null } = this.state;
    if (mapRef && prevProps.streetView.visible !== streetView.visible) {
      google.maps.event.trigger(mapRef, 'resize');
      google.maps.event.trigger(mapRef.getStreetView(), 'resize');
    }
    if (mapRef && prevProps.notificationsVisible !== notificationsVisible && notificationsVisible) {
      mapRef.setOptions({minZoom: 16});
    } else if (mapRef && prevProps.notificationsVisible !== notificationsVisible && !notificationsVisible) {
      mapRef.setOptions({minZoom: null});
    }
  }

  onMapMounted = mapRef => {
    window.mapRef = mapRef;
    this.setState({ mapRef });
  };

  onSelectArea = event => {
    if (this.state.mapRef && !this.props.measureActive) {
      this.props.selectArea(
        event.latLng,
        this.state.mapRef,
        remToPx(1.25)
      );
    }
  };

  updateViewport() {
    if (!this.state.viewportSet && this.props.searchMarker) {
      this.setState({viewportSet: true});
    } else if (this.state.viewportSet && this.props.searchMarker) {
      this.props.setSearchMarker(false);
      this.setState({viewportSet: false});
    }
  }

  onBoundsChanged = () => this.props.setViewport(this.state.mapRef);

  onStreetViewVisibleChanged = () => {
    this.props.setStreetViewVisible(this.state.mapRef.getStreetView().getVisible());
  };

  render() {
    const { viewport, streetView, searchFocused, mapTypeId, traffic, devSecret, requiresDevSecret } = this.props;
    if (viewport.center) {
      return (
        <div className={styles.mapContainer}>
          <SmallDrawingOverlay />
          <SmallHeader mapRef={this.state.mapRef} />
          { this.props.notificationsVisible && <SmallNotifications mapRef={this.state.mapRef} /> }
          <div
            className={classNames(
              styles.mapContent,
              {[styles.streetView]: streetView.visible, [styles.searchContent]: searchFocused }
            )}
          >
            {this.props.loading && <div className={styles.loadingContainer}><LoadingIndicator /></div>}
            { (!requiresDevSecret || devSecret) &&
              <Fragment>
                <Disclaimer />
                <div className={styles.filterContainer}>
                  <SmallFilter mapRef={this.state.mapRef} />
                </div>
                { this.state.mapRef &&
                  <MapTrays mapRef={this.state.mapRef} />
                }
              </Fragment>
            }
            <NotificationTooltip />
            <div id="map-container" className={classNames(styles.mapElementContainer, {[styles.loading]: this.props.loading})}>
              <div className={styles.mapElement}>
                <DotMapsGoogleMap
                  center={viewport.center}
                  components={
                    <Fragment>
                      <MapComponents />
                      <StreetViewPanorama onVisibleChanged={this.onStreetViewVisibleChanged} />
                    </Fragment>
                  }
                  mapTypeId={mapTypeId}
                  onBoundsChanged={this.updateViewport}
                  onClick={!this.props.notificationsVisible ? this.onSelectArea : null}
                  onIdle={this.onBoundsChanged}
                  onMapLoad={this.onMapMounted}
                  traffic={traffic}
                  zoom={viewport.zoom}
                />
              </div>
            </div>
          </div>
          <LargeFooter />
        </div>
      );
    }
    return null;
  }
}

Map.propTypes = {
  devSecret: PropTypes.string,
  loading: PropTypes.bool,
  mapTypeId: PropTypes.string,
  measureActive: PropTypes.bool,
  notificationsVisible: PropTypes.bool,
  requiresDevSecret: PropTypes.bool,
  searchFocused: PropTypes.bool,
  searchMarker: PropTypes.bool,
  selectArea: PropTypes.func,
  setSearchMarker: PropTypes.func,
  setStreetViewVisible: PropTypes.func,
  setViewport: PropTypes.func,
  streetView: PropTypes.object,
  traffic: PropTypes.bool,
  viewport: PropTypes.object
};

const mapStateToProps = state => ({
  viewport: getViewport(state),
  searchMarker: getSearchMarker(state),
  searchFocused: getSearchFocused(state),
  streetView: getStreetView(state),
  devSecret: getDevSecret(state),
  requiresDevSecret: getRequiresDevSecret(state),
  mapTypeId: getMapTypeId(state),
  traffic: getTraffic(state),
  measureActive: getMeasureToolActive(state),
  loading: getLoading(state),
  notificationsVisible: getNotificationsVisible(state)
});

export default connect(mapStateToProps, {
  setViewport, setStreetViewVisible, setSearchMarker, selectArea
})(Map);
