import {
  MAP_SELECT_ITEM,
  OPEN_LAYER_FILTERS,
  CLOSE_LAYER_FILTERS,
  OPEN_MOBILE_LINKS,
  CLOSE_MOBILE_LINKS,
  OPEN_MOBILE_MAX_DETAILS,
  CLOSE_MOBILE_MAX_DETAILS,
  OPEN_LEGEND,
  CLOSE_LEGEND,
  SET_DETAIL_TRAY_COLLAPSE,
  SET_MOBILE_MAP_LAYERS,
  SET_DRAWING_OVERLAY,
  OPEN_DATE_RANGE_DIALOG,
  CLOSE_DATE_RANGE_DIALOG,
  SHOW_USER_LOCATION_ERROR,
  SET_LAYER_FILTERS_DEFAULT_OPEN,
  OPEN_SUBSCRIBE_DIALOG,
  CLOSE_SUBSCRIBE_DIALOG
} from '../constants/action-types';
import initialState from '../store/initial-state';

const uiReducer = (state = initialState.ui, action) => {
  switch (action.type) {
  case OPEN_DATE_RANGE_DIALOG:
    return {...state, dialog: {...state.dialog, dateRangeVisible: true}};
  case CLOSE_DATE_RANGE_DIALOG:
    return {...state, dialog: {...state.dialog, dateRangeVisible: false}};
  case OPEN_SUBSCRIBE_DIALOG:
    return {...state, dialog: {...state.dialog, subscribeVisible: true}};
  case CLOSE_SUBSCRIBE_DIALOG:
    return {...state, dialog: {...state.dialog, subscribeVisible: false}};
  case OPEN_LAYER_FILTERS:
    return {...state, layerFilters: {...state.layerFilters, visible: true}};
  case CLOSE_LAYER_FILTERS:
    return {...state, layerFilters: {...state.layerFilters, visible: false}};
  case SET_LAYER_FILTERS_DEFAULT_OPEN: {
    const { defaultOpen } = action;
    return {...state, layerFilters: {...state.layerFilters, defaultOpen}};
  }
  case OPEN_MOBILE_LINKS:
    return {...state, mobileLinks: {...state.mobileLinks, visible: true}};
  case CLOSE_MOBILE_LINKS:
    return {...state, mobileLinks: {...state.mobileLinks, visible: false}};
  case OPEN_MOBILE_MAX_DETAILS:
    return {...state, mobileMaxDetails: {...state.mobileMaxDetails, visible: true}};
  case MAP_SELECT_ITEM:
  case CLOSE_MOBILE_MAX_DETAILS:
    return {...state, mobileMaxDetails: {...state.mobileMaxDetails, visible: false}};
  case OPEN_LEGEND:
    return {...state, legend: {...state.legend, visible: true}};
  case CLOSE_LEGEND:
    return {...state, legend: {...state.legend, visible: false}};
  case SET_DETAIL_TRAY_COLLAPSE: {
    const { collapsed } = action;
    return {...state, detailTray: {...state.detailTray, collapsed}};
  }
  case SET_MOBILE_MAP_LAYERS: {
    const { visible } = action;
    return {...state, mobileLayers: {...state.mobileLayers, visible}};
  }
  case SET_DRAWING_OVERLAY: {
    const { visible } = action;
    return {...state, drawingOverlay: {...state.drawingOverlay, visible}};
  }
  case SHOW_USER_LOCATION_ERROR: {
    return {...state, userLocationError: {visible: action.visible}};
  }
  default:
    return state;
  }
};

export default uiReducer;
