/* global google */
import memoize from 'memoize-one';

import {
  GEOCODE_SUGGESTION_FETCH_SUCCESS,
  PLACES_SUGGESTION_FETCH_SUCCESS,
  SET_SEARCH_SUGGESTION_TERM,
  SET_SEARCH_SELECTION,
  CLEAR_SEARCH_SUGGESTIONS,
  SET_SEARCH_MARKER,
  SET_SEARCH_FOCUSED,
  SET_SELECTED_LAYER_ELEMENT
} from '../constants/action-types';
import { setCenter, setZoom } from './map-actions';

import { getLocalityString } from '../selectors/site';

const doSetSearchMarker = visible => ({ type: SET_SEARCH_MARKER, visible });

const getPlacesApi = memoize(googleRef => googleRef && new googleRef.maps.places.AutocompleteService());
const getGeocoder = memoize(googleRef => googleRef && new googleRef.maps.Geocoder());

const fetchGeocodeSuggestionsSuccess = payload => ({
  type: GEOCODE_SUGGESTION_FETCH_SUCCESS,
  payload
});

const fetchPlacesSuggestionsSuccess = payload => ({
  type: PLACES_SUGGESTION_FETCH_SUCCESS,
  payload
});

export const fetchGeocodeSuggestions = (searchValue) => (dispatch, getState) => {
  const localityString = getLocalityString(getState());
  const geocoder = getGeocoder(google);
  if (geocoder) {
    geocoder.geocode({ address: `${searchValue}, ${localityString}` }, (georesult, geostatus) => {
      if (geostatus === 'OK') {
        dispatch(fetchGeocodeSuggestionsSuccess(georesult));
      }
      if (geostatus === 'ZERO_RESULTS') {
        dispatch(fetchGeocodeSuggestionsSuccess([]));
      }
    });
  }
  const places = getPlacesApi(google);
  if (places) {
    places.getQueryPredictions({input: `${searchValue}, ${localityString}`}, (georesult, geostatus) => {
      if (geostatus === 'OK') {
        dispatch(fetchPlacesSuggestionsSuccess(georesult));
      }
      if (geostatus === 'ZERO_RESULTS') {
        dispatch(fetchPlacesSuggestionsSuccess([]));
      }
    });
  }
};

export const setSearchTermAction = (searchTerm) => ({
  type: SET_SEARCH_SUGGESTION_TERM,
  searchTerm
});

export const setSearchTerm = searchValue => (dispatch) => {
  dispatch(setSearchTermAction(searchValue));
};

export const clearSearchSuggestions = () => ({
  type: CLEAR_SEARCH_SUGGESTIONS
});

const setSearchSelectionSuccess = location => ({
  type: SET_SEARCH_SELECTION,
  location
});

export const setSelectedLayerElement = element => ({
  type: SET_SELECTED_LAYER_ELEMENT,
  element
});

export const setSearchSelection = (searchValue) => dispatch => {
  if (searchValue.type === 'address') {
    if (searchValue.location) {
      const location = {
        label: searchValue.mainText,
        value: searchValue.value,
        position: searchValue.location
      };
      dispatch(setCenter(location.position));
      dispatch(setZoom(17));
      dispatch(setSearchSelectionSuccess(location));
      dispatch(doSetSearchMarker(true));
    } else if (searchValue.place_id) {
      dispatch(setSearchSelectionSuccess(searchValue));
      const geocoder = getGeocoder(google);
      if (geocoder) {
        geocoder.geocode({placeId: searchValue.place_id}, (results, status) => {
          if (status === 'OK') {
            const result = results[0];
            const location = {
              label: searchValue.mainText,
              value: searchValue.value,
              position: result.geometry.location
            };
            dispatch(setCenter(location.position));
            dispatch(setZoom(17));
            dispatch(doSetSearchMarker(true));
            dispatch(setSearchSelectionSuccess(location));
          }
        });
      }
    }
  } else {
    dispatch(setSelectedLayerElement(searchValue));
  }
};

export const clearSearchSelection = () => dispatch => {
  dispatch(setSearchSelectionSuccess(null));
  dispatch(setSelectedLayerElement(null));
};

export const setSearchMarker = visible => dispatch => {
  dispatch(doSetSearchMarker(visible));
};

const doSetSearchFocused = focused => ({
  type: SET_SEARCH_FOCUSED,
  focused
});

export const setSearchFocused = focused => dispatch => {
  dispatch(doSetSearchFocused(focused));
};
