// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-map-mobile-legend-button-___mobile-legend-button__button{display:flex;align-items:center;justify-content:center;background:white;border-radius:50%;box-shadow:0 6px 6px 0 rgba(0,0,0,0.24),0 0 6px 0 rgba(0,0,0,0.12);white-space:nowrap;margin:calc(5.75em + 1em) 1em 0 0;width:2.75em;height:2.75em;color:rgba(0,0,0,0.4)}.src-components-map-mobile-legend-button-___mobile-legend-button__button span{height:1.5em}
`, "",{"version":3,"sources":["webpack://./src/components/map/mobile-legend-button/mobile-legend-button.scss"],"names":[],"mappings":"AAGA,yEACI,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CACvB,gBAAiB,CACjB,iBAAkB,CAClB,kEAA0E,CAC1E,kBAAmB,CACnB,iCAA+C,CAC/C,YAAa,CACb,aAAc,CACd,qBAAyB,CAX7B,8EAcQ,YAAa","sourcesContent":["@import '../../../styles/mixins';\n@import '../../../styles/vars';\n\n.button {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: white;\n    border-radius: 50%;\n    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.24), 0 0 6px 0 rgba(0, 0, 0, 0.12);\n    white-space: nowrap;\n    margin: calc(#{$s-filter-height} + 1em) 1em 0 0;\n    width: 2.75em;\n    height: 2.75em;\n    color: rgba(0, 0, 0, 0.4);\n\n    span {\n        height: 1.5em;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `src-components-map-mobile-legend-button-___mobile-legend-button__button`
};
export default ___CSS_LOADER_EXPORT___;
