/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookie from 'universal-cookie';
import { closeNotificationTooltip } from '@actions/notification-actions';
import NotificationIcon from '@components/notifications/notification-icon';
import Icon from '@material-ui/core/Icon';
import { getNotificationsVisible, getNotificationsTooltipVisible } from '@selectors/notifications';
import LargeMedia from '@shared/large-media';
import SmallMedia from '@shared/small-media';
import styles from './notification-tooltip.scss';

const NotificationTooltip = () => {
  const dispatch = useDispatch();
  const notificationsVisible = useSelector(getNotificationsVisible);
  const tooltipVisible = useSelector(getNotificationsTooltipVisible);
  const cookie = new Cookie();

  const onClick = () => dispatch(closeNotificationTooltip());

  if (notificationsVisible || !tooltipVisible || cookie.get('notificationTooltipSeen')) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.header}>Get notifications for your area</div>
        <LargeMedia>
          <div className={styles.content}>
            Now you can get notified of what’s happening in your area.
            Click the bell (<NotificationIcon />) icon to get started.
          </div>
        </LargeMedia>
        <SmallMedia>
          <div className={styles.content}>
            Now you can get notified of what’s happening in your area.
            Tap on the menu (<Icon fontSize="small">menu</Icon>) icon to get started.
          </div>
        </SmallMedia>
      </div>
      <div className={styles.footer}>
        <span onClick={onClick} role="button" tabIndex={0}>GOT IT</span>
      </div>
    </div>
  );
};

export default memo(NotificationTooltip);
