import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Modal from '@material-ui/core/Modal';

import ThemeStyle, {SECONDARY_BACKGROUND} from '../../theme-style';

import { getDisclaimerContents } from '../../../selectors/map';

import { closeDisclaimer } from '../../../actions/map-actions';

import styles from './disclaimer.scss';

class Disclaimer extends Component {
  render() {
    const { disclaimer } = this.props;
    if (disclaimer) {
      return (
        <Modal classes={{root: styles.modal}} disableBackdropClick open >
          <div className={styles.disclaimer}>
            <div className={styles.title}>Disclaimer</div>
            <div className={styles.contents}>{disclaimer}</div>
            <ThemeStyle
              style={SECONDARY_BACKGROUND}
              className={styles.close}
              onClick={this.props.closeDisclaimer}
            >
              <div >OK</div>
            </ThemeStyle>
          </div>
        </Modal>
      );
    }
    return null;
  }
}

Disclaimer.propTypes = {
  closeDisclaimer: PropTypes.func,
  disclaimer: PropTypes.string
};

const mapStateToProps = state => ({
  disclaimer: getDisclaimerContents(state)
});

export default connect(mapStateToProps, {closeDisclaimer})(Disclaimer);
