/* eslint-disable react/jsx-no-bind */
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { openLegend, closeLegend } from '@actions/ui-actions';
import Icon from '@material-ui/core/Icon';
import { getLegend } from '@selectors/ui';
import LegendItems from './legend-items';
import styles from './map-legend.scss';

const MapLegend = ({ defaultOpen, mapData }) => {
  const dispatch = useDispatch();
  const legend = useSelector(getLegend);

  const onCloseClick = () => dispatch(closeLegend());

  useEffect(() => {
    if (defaultOpen) {
      dispatch(openLegend());
    }
  }, [defaultOpen, dispatch]);

  if (legend.visible) {
    const empty = !mapData.some(data => data.uiStyle.showInLegend);
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div>Legend</div>
          <Icon onClick={onCloseClick}>close</Icon>
        </div>
        {empty ?
          <div className={styles.emptyState}>
            No legend to show
          </div> :
          <LegendItems mapData={mapData} />
        }
      </div>
    );
  }
  return null;
};

MapLegend.propTypes = {
  defaultOpen: PropTypes.bool,
  mapData: PropTypes.array
};

export default memo(MapLegend);
