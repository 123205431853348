/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '@material-ui/core/Icon';
import Carousel from '../../../../shared/patched-carousel';

import { withIdentifyList } from '../identify-list';

import classNames from 'classnames';

import { getSelectionElements, getSelectedIndex, getSelectedArea } from '../../../../../selectors/map-selection';

import { openMobileMaxDetails } from '../../../../../actions/ui-actions';
import { setHighlight, clearHighlight, selectItem, closeAreaSelection } from '../../../../../actions/map-actions';

import styles from './mobile-identify.scss';

const CarouselIdentifyList = withIdentifyList(Carousel);

class MobileIdentify extends Component {
  constructor(props) {
    super(props);

    this.state = {initialSelection: this.props.identifyIndex};
    this.setIdentifyHighlight = this.setIdentifyHighlight.bind(this);
    this.setSelectedIndex = this.setSelectedIndex.bind(this);
    this.back = this.back.bind(this);
    this.forward = this.forward.bind(this);
  }

  componentDidMount() {
    this.setIdentifyHighlight(this.props.identifyIndex);
  }

  componentDidUpdate(prevProps) {
    if (this.props.identifyIndex !== prevProps.identifyIndex) {
      this.setIdentifyHighlight(this.props.identifyIndex);
    }
  }

  componentWillUnmount() {
    this.props.clearHighlight('identify');
  }

  setIdentifyHighlight(index) {
    const {layerData, data} = this.props.mapSelection[index];
    this.props.setHighlight('identify', layerData.id, data.id);
    if (this.props.onChange) {
      this.props.onChange(index);
    }
  }

  setSelectedIndex(index) {
    const { selectedArea } = this.props;
    this.props.selectItem(selectedArea, index);
    this.setIdentifyHighlight(index);
  }

  back() {
    const { identifyIndex } = this.props;
    if (identifyIndex > 0) {
      this.setSelectedIndex(identifyIndex - 1);
    }
  }

  forward() {
    const { identifyIndex, mapSelection } = this.props;
    if (identifyIndex < mapSelection.length - 1) {
      this.setSelectedIndex(identifyIndex + 1);
    }
  }

  render() {
    const { mapRef, identifyIndex, mapSelection } = this.props;
    const single = mapSelection.length === 1;
    return (
      <Fragment>
        <CarouselIdentifyList
          className={classNames(styles.identifyCarousel, {[styles.single]: single})}
          mapRef={mapRef}
          showThumbs={false}
          onClickItem={this.props.openMobileMaxDetails}
          onChange={this.setSelectedIndex}
          selectedItem={identifyIndex}
          showIndicators={false}
          showArrows={false}
          showStatus
          emulateTouch
        />
        <Icon
          className={classNames(styles.button, styles.leftButton, {[styles.disabled]: identifyIndex === 0})}
          onClick={identifyIndex > 0 ? this.back : null}
        >
          keyboard_arrow_left
        </Icon>
        <Icon
          className={classNames(styles.button, styles.rightButton,
            {[styles.disabled]: identifyIndex === mapSelection.length - 1})}
          onClick={identifyIndex < mapSelection.length - 1 ? this.forward : null}
        >
          keyboard_arrow_right
        </Icon>
        <div className={styles.closeButton} onClick={this.props.closeAreaSelection}>CLOSE</div>
      </Fragment>

    );
  }
}

MobileIdentify.propTypes = {
  clearHighlight: PropTypes.func,
  closeAreaSelection: PropTypes.func,
  identifyIndex: PropTypes.number,
  mapRef: PropTypes.object.isRequired,
  mapSelection: PropTypes.array,
  onChange: PropTypes.func,
  openMobileMaxDetails: PropTypes.func,
  selectItem: PropTypes.func,
  selectedArea: PropTypes.object,
  setHighlight: PropTypes.func
};

const mapStateToProps = state => ({
  identifyIndex: getSelectedIndex(state),
  mapSelection: getSelectionElements(state),
  selectedArea: getSelectedArea(state)
});

export default connect(
  mapStateToProps, {openMobileMaxDetails, setHighlight, clearHighlight, selectItem, closeAreaSelection}
)(MobileIdentify);
