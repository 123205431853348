/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
import React, { memo, useEffect, useState } from 'react';
import { useGoogleMap } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import classNames from 'classnames';
import {
  closeNotifications,
  setNotificationDrawingShape,
  setNotificationStep,
  setNotificationDataTypes,
  setNotificationFrequency,
  setNotificationEmail,
  setNotificationName,
  saveNotification,
  TYPE_AREA
} from '@actions/notification-actions';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
  getNotificationsVisible,
  getNotificationsDrawingShape,
  getNotificationsShapeError,
  getNotificationsStep,
  getNotificationsDataTypes,
  getNotificationsFrequency,
  getNotificationsEmail,
  getNotificationsEmailError,
  getNotificationsName,
  getNotificationsSuccess,
  getNotificationsPaused
} from '@selectors/notifications';
import { getNotificationFrequencyOptions, getNotificationEntityTypes } from '@selectors/site';
import NotificationDrawingTools from './notification-drawing-tools';
import ConfirmationPage from './confirmation-page';
import PausedPage from './paused-page';
import ThemeStyle, { SECONDARY_TEXT, INHERIT} from '../theme-style';
import styles from './notifications.scss';

const Notifications = () => {
  const dispatch = useDispatch();

  const map = useGoogleMap();

  const dataTypes = useSelector(getNotificationsDataTypes);
  const emailError = useSelector(getNotificationsEmailError);
  const email = useSelector(getNotificationsEmail);
  const entityTypes = useSelector(getNotificationEntityTypes);
  const frequencyOptions = useSelector(getNotificationFrequencyOptions);
  const frequency = useSelector(getNotificationsFrequency);
  const name = useSelector(getNotificationsName);
  const paused = useSelector(getNotificationsPaused);
  const shapeError = useSelector(getNotificationsShapeError);
  const shape = useSelector(getNotificationsDrawingShape);
  const step = useSelector(getNotificationsStep);
  const success = useSelector(getNotificationsSuccess);
  const visible = useSelector(getNotificationsVisible);

  const [emailTimeout, setEmailTimeout] = useState(null);
  const [emailValue, setEmailValue] = useState(null);

  useEffect(() => {
    if (email === null) {
      setEmailValue(null);
    }
  }, [email]);

  const nextStep = () => dispatch(setNotificationStep(step + 1));
  const prevStep = () => dispatch(setNotificationStep(step - 1));
  const setFrequency = event => dispatch(setNotificationFrequency(event.target.value));
  const setName = event => dispatch(setNotificationName(event.target.value));

  const save = () => {
    dispatch(saveNotification(TYPE_AREA, shape, dataTypes, frequency, email, name, null));
  };

  const setEmail = event => {
    const newEmail = event.target.value;

    if (emailTimeout) {
      clearTimeout(emailTimeout);
    }

    setEmailValue(newEmail);
    setEmailTimeout(setTimeout(() => {
      dispatch(setNotificationEmail(newEmail));
    }, 500));
  };

  const setType = event => {
    const { value, checked } = event.target;
    let types = [...dataTypes];
    if (checked && dataTypes.indexOf(value) < 0) {
      types.push(value);
    } else if (!checked && dataTypes.indexOf(value) > -1) {
      types = dataTypes.filter(dataType => dataType !== value);
    }
    dispatch(setNotificationDataTypes(types));
  };

  const checkStepComplete = () => {
    if (step === 1 && (!shape || shapeError)) {
      return false;
    } else if (step === 2 && dataTypes.length === 0) {
      return false;
    } else if (step === 3 && (frequency === null || email === null || emailError)) {
      return false;
    }
    return true;
  };

  const clearShape = () => dispatch(setNotificationDrawingShape(null));

  const onCloseClick = () => dispatch(closeNotifications());

  if (!visible) {
    return null;
  }

  const disabled = !checkStepComplete();

  if (paused) {
    return <PausedPage />;
  } else if (!success) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div>Receive Activity Updates</div>
          <div>
            {shapeError && <Icon className={styles.errorIcon}>error</Icon>}
            <Icon onClick={onCloseClick}>close</Icon>
          </div>
        </div>
        <div className={styles.content}>
          {step === 1 && (
            <div>
              <p>Draw an enclosed area on the map where you want to get notified of new activities.</p>
              <ol>
                <li className={styles.numberedList}>First set point A then navigate your cursor to point B and click to create first segment.</li>
                <li>
                  Continue this step until you have created an enclosed polygon to receive
                  notifications on all new records that get generated in this location.
                </li>
              </ol>
            </div>
          )}
          {step === 2 &&
            <div>
              <div className={styles.title}>Notify me about:</div>
              {entityTypes && entityTypes.map(entityType => (
                <div key={entityType.value} className={styles.notificationType}>
                  <div>{entityType.label}</div>
                  <Switch
                    onChange={event => setType(event)}
                    value={entityType.value}
                    checked={dataTypes.indexOf(entityType.value) > -1} />
                </div>
              ))}
            </div>
          }
          {step === 3 &&
            <div className={styles.userInfo}>
              <div className={styles.frequency}>
                <label>Email me</label>
                <Select
                  className={styles.input}
                  classes={{root: styles.selectInput}}
                  onChange={event => setFrequency(event)}
                  value={frequency}
                  labelWidth={0}
                  disableUnderline
                >
                  {frequencyOptions && frequencyOptions.map(item => (
                    <MenuItem
                      classes={{selected: styles.selectedItem}}
                      key={item.value} value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
              </div>
              <div className={styles.email}>
                <label>At</label>
                <Input
                  className={styles.input}
                  onChange={event => setEmail(event)}
                  error={emailError}
                  value={emailValue}
                  placeholder="example@domain.com"
                  labelWidth={0}
                  disableUnderline
                  aria-describedby="component-error-text" />
              </div>
              {emailError &&
                <FormHelperText className={styles.error} id="email-error-text">Enter a valid email</FormHelperText>
              }
            </div>
          }
          {step === 4 &&
            <div className={styles.name}>
              <label>Name this area <span>(Optional)</span></label>
              <Input
                className={styles.input}
                fullWidth
                onChange={event => setName(event)}
                value={name}
                labelWidth={0}
                disableUnderline
                placeholder="Ex. Near home, near work..." />
            </div>
          }
          {step === 5 &&
            <div className={styles.summary}>
              <div>In summary, you&apos;ll receive</div>
              <ul>
                <li>
                  <div>
                    <span>
                      {R.find(R.propEq(frequency, 'value'))(frequencyOptions).label}
                    </span>&nbsp;notifications
                  </div>
                </li>
                <li>
                  <div>
                    About&nbsp;
                    {dataTypes.length === 1 && <span key={dataTypes[0]} className={styles.dataType}>{dataTypes[0]}</span>}
                    {dataTypes.length === 2 && (
                      <div>
                        <span key={dataTypes[0]} className={styles.dataType}>{dataTypes[0]}</span>
                        &nbsp;and&nbsp;
                        <span key={dataTypes[1]} className={styles.dataType}>{dataTypes[1]}</span>
                      </div>
                    )}&nbsp;in this area
                    {name && <div>&nbsp;(<span>{name}</span>)</div>}
                  </div>
                </li>
                <li><div>At&nbsp;<span>{email}</span></div></li>
              </ul>
            </div>
          }
        </div>
        <div className={styles.footer}>
          <div className={styles.steps}>Step {step} of 5</div>
          <div>
            {step === 1 && shape &&
              <Button onClick={clearShape}>Clear</Button>
            }
            {step > 1 && <Button onClick={prevStep}>Back</Button>}
            {step < 5 &&
              <Button
                classes={{disabled: styles.disabled}}
                disabled={disabled}
                onClick={nextStep}>
                <ThemeStyle style={!disabled ? SECONDARY_TEXT : INHERIT}>Next</ThemeStyle>
              </Button>
            }
            {step === 5 &&
              <Button variant="contained" color="secondary" className={styles.save} onClick={save}>
                Save
              </Button>
            }
          </div>
        </div>
        <ThemeStyle style={SECONDARY_TEXT}>
          <div
            className={classNames(styles.progressBar, {[styles.complete]: step >= 5})}
            style={{width: `${Math.min((20 * step), 100)}%`}}
          />
        </ThemeStyle>
        <NotificationDrawingTools mapRef={map} />
      </div>
    );
  }
  return <ConfirmationPage />;
};

export default memo(Notifications);
