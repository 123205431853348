// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-shared-___arrows__arrows{transform:translate(4px, 4px)}
`, "",{"version":3,"sources":["webpack://./src/components/shared/arrows.scss"],"names":[],"mappings":"AAGA,yCACE,6BAA8B","sourcesContent":["@import '../../styles/mixins';\n@import '../../styles/vars';\n\n.arrows {\n  transform: translate(4px, 4px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrows": `src-components-shared-___arrows__arrows`
};
export default ___CSS_LOADER_EXPORT___;
