/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';
import styles from './toggle.scss';

class FilterToggle extends PureComponent {
  render() {
    const {checked, color, icon, onClick, theme, ...passThroughProps} = this.props;
    const dynamicStyles = {};
    if (checked) {
      dynamicStyles.backgroundColor = color || theme.palette.primary.main;
    }
    return (
      <div {...passThroughProps} >
        <div
          onClick={onClick}
          style={dynamicStyles}
          className={styles.toggle}
        >
          { icon &&
            <img src={icon} className={styles.icon} alt="" />
          }
        </div>
      </div>
    );
  }
}

FilterToggle.propTypes = {
  checked: PropTypes.bool,
  color: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.object
};

export default withTheme()(FilterToggle);
