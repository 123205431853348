import { camelCase } from 'lodash';

const JS_TEMPLATE_WRAP = /^([^`]*)`([^`]*)`$/;
const JS_TEMPLATE_RE = /\${([^.}]*)\.?[^}]*}/g;
const ID_FIELD = 'id';
export const TITLE_FIELD = 'title';
export const HEADER_PRE = 'preHeader';
export const HEADER_POST = 'postHeader';
const SPECIAL_FIELDS = new Set([ID_FIELD, TITLE_FIELD, HEADER_PRE, HEADER_POST]);

const getField = (field, attributes, mapping) => {
  const value = attributes[field] || '';
  const camel = camelCase(field);
  return (mapping && mapping[camel] && mapping[camel][value]) || value;
};

/*
  String templates are any alias data key wrapped with ``
  If a name preceds the first ` a special processing method from below is used.
  Template values are designated by the form ${...} (similar to an ES2015 template, but the epxression is only a key)
*/
const templateTags = {
  /*
    Standard template processing extracts all template values
    and replaces them with values from the attributes dictionary
  */
  '': (attributes, mapping, strings, keys) => {
    const result = [strings[0]];
    keys.forEach((key, index) => {
      result.push(getField(key, attributes, mapping) || '', strings[index + 1]);
    });
    return result.join('');
  },
  /*
    Presents a single value as an ISO date.
    Returs the first template value with the time portion stripped.
  */
  isodate: (attributes, mapping, strings, keys) => {
    return getField(keys[0], attributes, mapping).split('T')[0];
  },
  /*
    Extracts the first string value as a delmiter, then concatenates all remaining template values with the delimeter.
    There should be no other strings between template values.
  */
  concat: (attributes, mapping, strings, keys) => {
    const delim = strings[0] || ', ';
    const values = [];
    keys.forEach(key => {
      const value = getField(key, attributes, mapping);
      if (value) {
        values.push(value);
      }
    });
    return values.join(delim);
  },
  /*
    Creates an HTML table.
    Extracts the first string as a column separator.
    Useses ',' as the separator if there is no string before first template value.
    Extracts the first template value as the key from attributes for the data source.
    Data souche must be an array of row objects.
    Remainder of the template is treated as a template for each row.
    Separators in the row template is treated as a new column.
    Row template values are extracted from each row object.
  */
  table: (attributes, mapping, strings, keys) => {
    const table = [];
    table.push('<table>');
    const [separatorString, ...templateStrings] = strings;
    const separator = separatorString || ',';
    const separatedTemplateStrings = templateStrings.map(string => string.replace(separator, '</td><td>'));
    const [dataKey, ...templateKeys] = keys;
    const rowData = getField(dataKey, attributes) || [];
    if (rowData.length === 0) {
      return null;
    }
    rowData.forEach(rowAttributes => {
      table.push('<tr><td>');
      table.push(separatedTemplateStrings[0]);
      templateKeys.forEach((key, index) => {
        table.push(getField(key, rowAttributes, mapping) || '', separatedTemplateStrings[index + 1]);
      });
      table.push('</td></tr>');
    });

    table.push('</table>');
    return table.join('');
  },
  list: (attributes, mapping, strings, keys) => {
    let result = [];
    keys.forEach(key => {
      result = result.concat(getField(key, attributes, mapping));
    });
    return result.join('</br>');
  }
};

export const getAliases = ({aliases}) => (
  aliases
    .filter(([, label]) => !SPECIAL_FIELDS.has(label))
    .map(([alias, label, ...flags]) => [
      alias,
      label,
      new Set(flags)
    ])
);

export const resolveFields = (fields, attributes, mapping) => {
  if (typeof fields === 'string' || fields instanceof String) {
    const match = fields.match(JS_TEMPLATE_WRAP);
    if (match) {
      const tag = match[1];
      const template = match[2];
      const strings = [];
      const keys = [];
      template.split(JS_TEMPLATE_RE).forEach((value, index) => {
        if (index % 2) {
          keys.push(value);
        } else {
          strings.push(value);
        }
      });
      if (templateTags[tag]) {
        return templateTags[tag](attributes, mapping, strings, keys);
      }
      return templateTags[''](attributes, mapping, strings, keys);
    }
    return getField(fields, attributes, mapping);
  }
  const data = {};
  Object.entries(fields).forEach(([key, value]) => {
    data[key] = resolveFields(value, attributes, mapping);
  });
  return data;
};

// eslint-disable-next-line no-unused-vars
export const getCategory = (mapData, {layerData}) => {
  /* TODO: determin how category is derived */
  // layerData
  return null;
};

export const getTypes = (mapData, {layerData}) => {
  const types = [];
  let filterId = layerData.id;
  while (filterId) {
    const data = mapData[filterId];
    if (data.label) {
      types.push(data.label);
    }
    filterId = data.parentId;
  }
  types.reverse();
  return types;
};

export const getTitles = (mapData, detailData) => {
  const {data: {attrs}, layerData} = detailData;
  const titles = {};

  const titleField = layerData.aliases.filter(([, label]) => label === TITLE_FIELD)[0];
  if (titleField) {
    titles[TITLE_FIELD] = resolveFields(titleField[0], attrs);
  }

  const preField = layerData.aliases.filter(([, label]) => label === HEADER_PRE)[0];
  if (preField) {
    titles[HEADER_PRE] = resolveFields(preField[0], attrs);
  }

  const postField = layerData.aliases.filter(([, label]) => label === HEADER_POST)[0];
  if (postField) {
    titles[HEADER_POST] = resolveFields(postField[0], attrs);
  }

  return titles;
};

// Return a list of all locations on the map with the same 'remote_id' field.
export const getAllLocations = (mapData, remoteId) => {
  const locations = [];
  Object.values(mapData).forEach(layerData => {
    layerData.list.forEach(item => {
      if (item.remote_id === remoteId) {
        locations.push(item);
      }
    });
  });
  return locations;
};
