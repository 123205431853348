/* global google */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, useJsApiLoader, TrafficLayer } from '@react-google-maps/api';
import { desktopMinWidth, getMapOptions, mapConfig } from '@constants/component-configs';

const libraries = ['drawing', 'geometry', 'places'];  // For performance, keep 'libraries' outside the component,
                                                      // to avoid unintentional reloads.

const DotMapsGoogleMap = ({
  center,
  components,
  mapTypeId,
  onBoundsChanged,
  onClick,
  onIdle,
  onMapLoad,
  traffic,
  zoom
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBg3iYyycTNutbbCzFmhd5W_kt4eodjbIQ',
    libraries
  });

  if (isLoaded) {
    const mapOptions = window.screen.width >= desktopMinWidth ? {...getMapOptions(google).mapOptions} : {...mapConfig.mobileMapOptions};
    const containerStyle = { width: '100%', height: '100%' };
    return (
      <GoogleMap
        center={center}
        mapContainerStyle={containerStyle}
        mapTypeId={mapTypeId}
        onBoundsChanged={onBoundsChanged}
        onClick={onClick}
        onIdle={onIdle}
        onLoad={onMapLoad}
        options={mapOptions}
        zoom={zoom}
      >
        {traffic && <TrafficLayer />}
        {components}
      </GoogleMap>
    );
  }
  return null;
};

DotMapsGoogleMap.propTypes = {
  center: PropTypes.object,
  components: PropTypes.node,
  mapTypeId: PropTypes.string,
  onBoundsChanged: PropTypes.func,
  onClick: PropTypes.func,
  onIdle: PropTypes.func,
  onMapLoad: PropTypes.func,
  traffic: PropTypes.bool,
  zoom: PropTypes.number
};

export default memo(DotMapsGoogleMap);
