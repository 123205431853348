/* eslint-disable import/no-named-as-default */
import React, { memo, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { hot } from 'react-hot-loader';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getError404Visible } from '@selectors/map';
import { getTitle } from '@selectors/site';
import LoadingScreens from './loading-screens';
import Map from './map';
import Note from './note';
import Error404 from './error-404';
import GlobalBackdrop from './global-backdrop';
import GlobalPopup from './global-popup';
import InfoSnackbar from './info-snackbar';

const App = () => {
  const error404Visible = useSelector(getError404Visible);
  const title = useSelector(getTitle);

  if (error404Visible) {
    return <Error404 />;
  }

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Switch>
        <Route path="/map" component={Map} />
        <Route path="/notes" component={Note} />
        <Redirect from="/" to="/map" />
      </Switch>
      <LoadingScreens />
      <GlobalBackdrop />
      <GlobalPopup />
      <InfoSnackbar />
    </Fragment>
  );
};

export default hot(module)(memo(App));
