// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-loading-screens-___loading-screens__container{height:0;width:0}
`, "",{"version":3,"sources":["webpack://./src/components/loading-screens/loading-screens.scss"],"names":[],"mappings":"AAGA,8DACI,QAAS,CACT,OAAQ","sourcesContent":["@import '../../styles/mixins';\n@import '../../styles/vars';\n\n.container {\n    height: 0;\n    width: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `src-components-loading-screens-___loading-screens__container`
};
export default ___CSS_LOADER_EXPORT___;
