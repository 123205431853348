// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-notifications-shape-error-___shape-error__shape-error{font-size:.875rem;letter-spacing:0.2px;border-radius:.25rem;color:#ebebeb;background-color:#df1717}@media only screen and (min-device-width: 768px){.src-components-notifications-shape-error-___shape-error__shape-error{width:27rem;margin:0 0 3rem 0rem}}.src-components-notifications-shape-error-___shape-error__shape-error .src-components-notifications-shape-error-___shape-error__content{background-color:#df1717;padding:6px 16px;justify-content:center}@media only screen and (min-device-width: 768px){.src-components-notifications-shape-error-___shape-error__shape-error .src-components-notifications-shape-error-___shape-error__content{width:27rem}}.src-components-notifications-shape-error-___shape-error__clear-button span{font-weight:500;color:#fff}
`, "",{"version":3,"sources":["webpack://./src/components/notifications/shape-error/shape-error.scss"],"names":[],"mappings":"AAGA,sEACI,iBAAkB,CAClB,oBAAqB,CACrB,oBAAqB,CACrB,aAAc,CACd,wBAAyB,CACzB,iDANJ,sEAOQ,WAAY,CACZ,oBAAqB,CAU5B,CAlBD,wIAWM,wBAAyB,CACzB,gBAAiB,CACjB,sBAAuB,CACvB,iDAdN,wIAeQ,WAAY,CAEf,CAGL,4EAEQ,eAAgB,CAChB,UACJ","sourcesContent":["@import '../../../styles/mixins';\n@import '../../../styles/vars';\n\n.shape-error {\n    font-size: .875rem;\n    letter-spacing: 0.2px;\n    border-radius: .25rem;\n    color: #ebebeb;\n    background-color: #df1717;\n    @media only screen and (min-device-width: $desktop-device-width) {\n        width: 27rem;\n        margin: 0 0 3rem 0rem;\n    }\n    .content {\n      background-color: #df1717;\n      padding: 6px 16px;\n      justify-content: center;\n      @media only screen and (min-device-width: $desktop-device-width) {\n        width: 27rem;\n      }\n    }\n}\n\n.clear-button {\n    span {\n        font-weight: 500;\n        color: #fff\n    };\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shape-error": `src-components-notifications-shape-error-___shape-error__shape-error`,
	"shapeError": `src-components-notifications-shape-error-___shape-error__shape-error`,
	"content": `src-components-notifications-shape-error-___shape-error__content`,
	"clear-button": `src-components-notifications-shape-error-___shape-error__clear-button`,
	"clearButton": `src-components-notifications-shape-error-___shape-error__clear-button`
};
export default ___CSS_LOADER_EXPORT___;
