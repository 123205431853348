/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Icon from '@material-ui/core/Icon';
import Popover from '@material-ui/core/Popover';
import LargeMedia from '@shared/large-media';
import SmallMedia from '@shared/small-media';
import styles from './filter-card.scss';

const stopPropagation = event => {
  event.stopPropagation();
};

class InfoPopup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      popover: null
    };

    this.handleInfoClick = this.handleInfoClick.bind(this);
    this.setPopoverElement = this.setPopoverElement.bind(this);
  }

  handleInfoClick = event => {
    event.stopPropagation();
    event.preventDefault();
    this.setPopoverElement(event.currentTarget);
  };

  setPopoverElement = element => {
    this.setState({popover: element});
  };

  render() {
    const { label, children, ...passThroughProps } = this.props;
    return (
      <Fragment>
        <Icon onClick={this.handleInfoClick} {...passThroughProps}>
          help
          <span onClick={stopPropagation} >
            <LargeMedia>
              <Popover
                open={Boolean(this.state.popover)}
                anchorEl={this.state.popover}
                onClose={() => this.setPopoverElement(null)}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
              >
                <div className={styles.tooltipHeader}>
                  <div className={styles.tooltipLabel}>{label}</div>
                  <Icon onClick={() => this.setPopoverElement(null)}>close</Icon>
                </div>
                <div className={styles.tooltipText}>{children}</div>
              </Popover>
            </LargeMedia>
            <SmallMedia>
              <Dialog
                open={Boolean(this.state.popover)}
                onClose={() => this.setPopoverElement(null)}
                classes={{paper: styles.mobileContainer}}
              >
                <div className={styles.tooltipHeader}>
                  <div className={styles.tooltipLabel}>{label}</div>
                  <Icon onClick={() => this.setPopoverElement(null)}>close</Icon>
                </div>
                <div className={styles.tooltipText}>{children}</div>
              </Dialog>
            </SmallMedia>
          </span>
        </Icon>
      </Fragment>
    );
  }
}

InfoPopup.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string
};

export default InfoPopup;
