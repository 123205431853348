/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import MapSearchAutocomplete from '../filter-tray/map-search-autocomplete';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popover from '@material-ui/core/Popover';
import classNames from 'classnames';
import ThemeStyle, {PRIMARY_BACKGROUND} from '../theme-style';
import { setSearchFocused, clearSearchSuggestions } from '../../actions/search-actions';
import { openMobileLinks, hideDrawingOverlay, openDateRangeDialog } from '../../actions/ui-actions';
import { clearDrawingMode } from '../../actions/map-actions';
import { selectDateRange } from '../../actions/filter-actions';
import { closeNotificationTooltip } from '../../actions/notification-actions';
import { getSearchFocused, getMenuIsOpen } from '../../selectors/search';
import { getDateRanges, getCustomDateRange } from '../../selectors/filters';
import { getLogo } from '../../selectors/site';
import styles from './header.scss';

class Header extends Component {
  state = {dateSelectOpen: false, open: false};

  clickHandler = () => {
    this.props.setSearchFocused(false);
    this.props.clearSearchSuggestions();
  };

  menuClick = () => {
    this.props.openMobileLinks();
    this.props.clearDrawingMode();
    this.props.hideDrawingOverlay();
    this.props.closeNotificationTooltip();
  };

  searchClick = () => {
    this.props.setSearchFocused(true);
    this.props.clearDrawingMode();
    this.props.hideDrawingOverlay();
  };

  setDateRange = (value, custom) => {
    this.closeDateSelect();
    this.props.selectDateRange(value);
    if (custom) {
      this.props.openDateRangeDialog();
    }
  };

  openDateSelect = event => this.setState({ anchorEl: event.currentTarget });

  closeDateSelect = () => this.setState({ anchorEl: null });

  render() {
    const { logo, mapRef, searchMenu, focused, dateRanges, customDateRange } = this.props;
    const { anchorEl } = this.state;
    return (
      <ThemeStyle
        className={classNames(styles.headerContainer, {[styles.headerContainerSearching]: focused})}
        style={PRIMARY_BACKGROUND}
      >
        <div className={styles.headerContent}>
          <div className={styles.headerItem} >
            <Icon onClick={this.menuClick}>menu</Icon>
          </div>
          { logo &&
            <div className={styles.headerItem} >
              { logo && logo.imageUrl && !logo.linkUrl &&
                <img src={logo.imageUrl} alt="" />
              }
              { logo && logo.imageUrl && logo.linkUrl &&
                <a href={logo.linkUrl} target="_blank" rel="noopener noreferrer"><img src={logo.imageUrl} alt="" /></a>
              }
            </div>
          }
          <div className={styles.headerPadding} />
          <div className={classNames(styles.searchContainer, {[styles.searchMenuOpen]: searchMenu})} >
            <MapSearchAutocomplete
              mapRef={mapRef}
              onFocus={this.searchClick}
              collapsed={!focused}
              mobile
            />
          </div>
          <div className={styles.headerItem}>
            <Icon onClick={this.openDateSelect}>calendar_today</Icon>
            <Popover
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              classes={{paper: styles.dateSelectMenu}}
              onClose={this.closeDateSelect}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <MenuList id="date-menu">
                {dateRanges.map((dateRange, index) => (
                  <MenuItem
                    key={index}
                    value={index}
                    onClick={() => this.setDateRange(index, dateRange.custom)}
                    className={styles.menuItem}
                  >
                    {(dateRange.custom && customDateRange.start && customDateRange.end) ?
                      `${dateRange.description} (${moment(customDateRange.start).format('MM/DD/YYYY')}-${moment(customDateRange.end).format('MM/DD/YYYY')})` :
                      dateRange.description
                    }
                  </MenuItem>
                ))};
              </MenuList>
            </Popover>
          </div>
        </div>
      </ThemeStyle>
    );
  }
}

Header.propTypes = {
  clearDrawingMode: PropTypes.func,
  clearSearchSuggestions: PropTypes.func,
  closeNotificationTooltip: PropTypes.func,
  customDateRange: PropTypes.object,
  dateRanges: PropTypes.array,
  focused: PropTypes.bool,
  hideDrawingOverlay: PropTypes.func,
  logo: PropTypes.object,
  mapRef: PropTypes.object,
  openDateRangeDialog: PropTypes.func,
  openMobileLinks: PropTypes.func,
  searchMenu: PropTypes.bool,
  selectDateRange: PropTypes.func,
  setSearchFocused: PropTypes.func
};

const mapStateToProps = state => ({
  dateRanges: getDateRanges(state),
  focused: getSearchFocused(state),
  logo: getLogo(state),
  customDateRange: getCustomDateRange(state),
  searchMenu: getMenuIsOpen(state)
});

export default connect(mapStateToProps, {
  openMobileLinks,
  setSearchFocused,
  clearSearchSuggestions,
  clearDrawingMode,
  hideDrawingOverlay,
  openDateRangeDialog,
  selectDateRange,
  closeNotificationTooltip
})(Header);
