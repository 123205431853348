import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IdentifyCard from '../../identify-card';
import { getSelectionElements } from '@selectors/map-selection';
import { getMapData } from '@selectors/map';
import {
  getTitles, HEADER_POST
} from '@utils/map-data-utils';
import styles from './identify-list.scss';

export function withIdentifyList(WrappedComponent) {
  if (!WrappedComponent) {
    WrappedComponent = Fragment;  // eslint-disable-line no-param-reassign
  }

  class IdentifyList extends Component {
    render() {
      const { mapSelection, onClickItem, mapRef, mapData, ...passThroughProps } = this.props;
      return (
        <WrappedComponent {...passThroughProps}>
          {mapSelection.map((detail, index) => {
            const {
              [HEADER_POST]: postHeader
            } = getTitles(mapData, detail);
            return (
              <div
                className={classNames(styles.identifyCard, {[styles.condensed]: !postHeader})}
                key={`${detail.layerData.id}-${detail.data.id}`}
              >
                <IdentifyCard detailData={detail} index={index} onClick={onClickItem} mapRef={mapRef} />
              </div>
            );
          })}
        </WrappedComponent>
      );
    }
  }

  IdentifyList.displayName = `withIdentifyList(${WrappedComponent.displayName || WrappedComponent.name}`;

  IdentifyList.propTypes = {
    mapData: PropTypes.object,
    mapRef: PropTypes.object.isRequired,
    mapSelection: PropTypes.array,
    onClickItem: PropTypes.func
  };

  const mapStateToProps = state => ({
    mapData: getMapData(state),
    mapSelection: getSelectionElements(state)
  });

  return connect(mapStateToProps, {})(IdentifyList);
}

export default withIdentifyList();
