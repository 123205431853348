/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { setMapTypeId, toggleTraffic } from '@actions/map-actions';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import LargeMedia from '@shared/large-media';
import SmallMedia from '@shared/small-media';
import ThemeStyle, {
  PRIMARY_TEXT, SECONDARY_TEXT, PRIMARY_BACKGROUND, INHERIT
} from '../../theme-style';
import { getMapTypeId, getTraffic } from '@selectors/map';
import styles from './map-types.scss';

class MapTypes extends Component {
  setRoadmap = () => this.props.setMapTypeId('roadmap');
  setHybrid = () => this.props.setMapTypeId('hybrid');
  toggleTraffic = () => this.props.toggleTraffic();

  render() {
    const { mapTypeId, traffic } = this.props;
    const roadmap = mapTypeId === 'roadmap';
    const hybrid = mapTypeId === 'hybrid';

    return (
      <Fragment>
        <SmallMedia>
          <div className={styles.mobileButtonGroup}>
            <div className={classNames(styles.buttonWrap, {[styles.selected]: roadmap})} onClick={this.setRoadmap}>
              <ThemeStyle className={styles.button} style={roadmap ? PRIMARY_BACKGROUND : INHERIT}>
                <Icon className={styles.icon}>map</Icon>
              </ThemeStyle>
              <ThemeStyle style={roadmap ? PRIMARY_TEXT : INHERIT}>
                <label className={roadmap ? 'selected' : null}>Default</label>
              </ThemeStyle>
            </div>
            <div className={classNames(styles.buttonWrap, {[styles.selected]: hybrid})} onClick={this.setHybrid}>
              <ThemeStyle className={styles.button} style={hybrid ? PRIMARY_BACKGROUND : INHERIT}>
                <Icon className={styles.icon}>satellite</Icon>
              </ThemeStyle>
              <ThemeStyle style={hybrid ? PRIMARY_TEXT : INHERIT}>
                <label className={hybrid ? 'selected' : null}>Satellite</label>
              </ThemeStyle>
            </div>
            <div className={classNames(styles.buttonWrap, {[styles.selected]: traffic})} onClick={this.toggleTraffic}>
              <ThemeStyle className={styles.button} style={traffic ? PRIMARY_BACKGROUND : INHERIT}>
                <Icon className={styles.icon}>traffic</Icon>
              </ThemeStyle>
              <ThemeStyle style={traffic ? PRIMARY_TEXT : INHERIT}>
                <label className={traffic ? 'selected' : null}>Traffic</label>
              </ThemeStyle>
            </div>
          </div>
        </SmallMedia>
        <LargeMedia>
          <div className={styles.buttonGroup}>
            <Tooltip title="Satellite" placement="bottom">
              <Button onClick={this.setHybrid}>
                <ThemeStyle style={hybrid ? SECONDARY_TEXT : INHERIT}>
                  <Icon className={styles.icon}>satellite</Icon>
                </ThemeStyle>
              </Button>
            </Tooltip>
            <Tooltip title="Map" placement="bottom">
              <Button onClick={this.setRoadmap}>
                <ThemeStyle style={roadmap ? SECONDARY_TEXT : INHERIT}>
                  <Icon>map</Icon>
                </ThemeStyle>
              </Button>
            </Tooltip>
            <Tooltip title="Traffic" placement="bottom">
              <Button onClick={this.toggleTraffic}>
                <ThemeStyle style={traffic ? SECONDARY_TEXT : INHERIT}>
                  <Icon>traffic</Icon>
                </ThemeStyle>
              </Button>
            </Tooltip>
          </div>
        </LargeMedia>
      </Fragment>
    );
  }
}

MapTypes.propTypes = {
  mapTypeId: PropTypes.string,
  setMapTypeId: PropTypes.func,
  toggleTraffic: PropTypes.func,
  traffic: PropTypes.bool
};

const mapStateToProps = state => ({
  mapTypeId: getMapTypeId(state),
  traffic: getTraffic(state)
});

export default connect(mapStateToProps, {setMapTypeId, toggleTraffic})(MapTypes);
