import {
  FILTERS_FETCH_SUCCESS,
  MAP_DATA_FETCH_SUCCESS,
  TOGGLE_FILTER,
  SELECT_DATE_RANGE,
  MAP_SELECT_AREA,
  MAP_INVALIDATE_SELECT_AREA,
  MAP_SELECT_ITEM,
  MAP_CLEAR_HIGHLIGHT,
  MAP_HIGHLIGHT,
  MAP_VIEWPORT_CHANGE,
  MAP_SET_CENTER,
  MAP_SET_ZOOM,
  MAP_SET_STREET_VIEW_VISIBLE,
  MAP_SET_TYPE_ID,
  MAP_TOGGLE_TRAFFIC,
  MAP_SET_DRAWING_MODE,
  MAP_SELECT_AREA_DRAWING,
  MAP_MEASURE_ACTIVE,
  MAP_MEASURE_LENGTH,
  MAP_SET_USER_LOCATION,
  MAP_SET_LABEL_GRID,
  MAP_SET_DEV_SECRET_REQUIRED,
  MAP_SET_DEV_SECRET,
  MAP_SET_DISCLAIMER,
  OPEN_LEGEND,
  SET_LINKED_ELEMENT,
  SET_LINKED_ELEMENT_ACTIVE,
  SET_404_PAGE
} from '../constants/action-types';
import initialState from '../store/initial-state';

const mapReducer = (state = initialState.map, action) => {
  switch (action.type) {
  case FILTERS_FETCH_SUCCESS: {
    const dataTypeList = {};
    const addDataType = ({id, name, uiStyle, children}, ancestors) => {
      const newAncestors = new Set(ancestors).add(name);
      dataTypeList [id] = {
        id,
        list: [],
        checked: uiStyle.defaultChecked || uiStyle.hidden,
        loading: true
      };
      if (children) {
        children.forEach(child => addDataType(child, newAncestors));
      }
    };

    action.payload.forEach(dataType => addDataType(dataType, new Set()));
    return {...state, data: {...dataTypeList}};
  }
  case MAP_DATA_FETCH_SUCCESS: {
    let existingData = [];
    if (!action.replace) {
      existingData = state.data[action.id].list;
      if (state.data[action.id]) {
        const newIDs = new Set();
        action.payload.results.forEach(dataItem => {
          newIDs.add(dataItem.id);
        });
        existingData = existingData.filter(dataItem => !newIDs.has(dataItem.id));
      }
    }

    return { ...state, data: { ...state.data, [action.id]: {
      ...state.data[action.id],
      list: [...existingData, ...action.payload.results],
      next: action.payload.next,
      loading: false
    }}};
  }
  case TOGGLE_FILTER: {
    return {
      ...state,
      data: { ...state.data, [action.id]: { ...state.data[action.id], checked: !state.data[action.id].checked }},
      selection: { area: null, item: null }
    };
  }
  case SELECT_DATE_RANGE: {
    return { ...state, selection: { area: null, item: null } };
  }
  case MAP_SELECT_AREA: {
    return { ...state, selection: { ...state.selection, area: action.area }};
  }
  case MAP_INVALIDATE_SELECT_AREA: {
    return {
      ...state,
      selection: state.selection && {
        ...state.selection, area: state.selection.area && {...state.selection.area, invalidated: true}
      }
    };
  }
  case MAP_SELECT_ITEM: {
    return { ...state, selection: { ...state.selection, item: action.item }};
  }
  case MAP_CLEAR_HIGHLIGHT: {
    const highlight = { ...state.highlight };
    delete highlight[action.name];
    return { ...state, highlight };
  }
  case MAP_HIGHLIGHT: {
    return { ...state, highlight: {
      ...state.highlight, [action.name]: { layerId: action.layerId, itemId: action.itemId }
    }};
  }
  case MAP_VIEWPORT_CHANGE: {
    return { ...state, viewport: { ...action.viewport }};
  }
  case MAP_SET_CENTER: {
    return { ...state, viewport: { ...state.viewport, center: action.center }};
  }
  case MAP_SET_ZOOM: {
    return { ...state, viewport: { ...state.viewport, zoom: action.zoom }};
  }
  case MAP_SET_STREET_VIEW_VISIBLE: {
    return { ...state, streetView: { ...state.streetView, visible: action.visible }};
  }
  case MAP_SET_TYPE_ID: {
    const mapTypeId = action.payload;
    return { ...state, mapTypeId };
  }
  case MAP_TOGGLE_TRAFFIC: {
    const traffic = !state.traffic;
    return { ...state, traffic };
  }
  case MAP_SET_DRAWING_MODE: {
    const { mode } = action;
    return { ...state, drawing: { ...state.drawing, area: null, mode, complete: false, size: 0 } };
  }
  case MAP_SELECT_AREA_DRAWING: {
    return {
      ...state,
      selection: { ...state.selection, area: action.area },
      drawing: { ...state.drawing, area: action.area, complete: true, size: action.size}
    };
  }
  case MAP_MEASURE_ACTIVE: {
    const { active } = action;
    return { ...state, measureTool: { ...state.measureTool, active, length: 0 } };
  }
  case MAP_MEASURE_LENGTH: {
    const { length } = action;
    return { ...state, measureTool: { ...state.measureTool, length } };
  }
  case MAP_SET_USER_LOCATION: {
    const { userLocation } = action;
    return { ...state, userLocation};
  }
  case MAP_SET_LABEL_GRID: {
    const { labelGrid } = action;
    return { ...state, labelGrid};
  }
  case MAP_SET_DEV_SECRET_REQUIRED: {
    return { ...state, requiresDevSecret: true};
  }
  case MAP_SET_DEV_SECRET: {
    const { secret } = action;
    return { ...state, devSecret: secret };
  }
  case MAP_SET_DISCLAIMER: {
    return { ...state, disclaimer: action.visible };
  }
  case OPEN_LEGEND: {
    return {
      ...state,
      measureTool: { ...state.measureTool, active: false, length: 0 }
    };
  }
  case SET_LINKED_ELEMENT: {
    const { layerData, data } = action;
    return { ...state, linkedElement: {layerData, data, selectionDistance: 0}};
  }
  case SET_LINKED_ELEMENT_ACTIVE: {
    const { active } = action;
    return { ...state, linkedElementActive: active};
  }
  case SET_404_PAGE: {
    return { ...state, error404Visible: true};
  }
  default:
    return state;
  }
};

export default mapReducer;
