import React from 'react';
import MediaQuery from 'react-responsive';

const LARGE_MEDIA_PROPS = { minDeviceWidth: 768 };

const getDisplayName = (WrappedComponent) => (
  WrappedComponent && (WrappedComponent.displayName || WrappedComponent.name || 'Component') || 'null'
);

export const withIsSmallMedia = (Component, mediaProps = LARGE_MEDIA_PROPS) => {
  const wrappedComponent = props => (
    <MediaQuery {...mediaProps}>
      { matches => <Component {...props} isSmallMedia={!matches} /> }
    </MediaQuery>
  );
  wrappedComponent.displayName = `withIsSmallMedia(${getDisplayName(Component)})`;
  return wrappedComponent;
};

export const withOnlyLargeMedia = (Component, mediaProps = LARGE_MEDIA_PROPS) => {
  const wrappedComponent = props => (
    <MediaQuery {...mediaProps}>
      { matches => matches ? <Component {...props} /> : null }
    </MediaQuery>
  );
  wrappedComponent.displayName = `WithOnlyLargeMedia(${getDisplayName(Component)})`;
  return wrappedComponent;
};

export const withOnlySmallMedia = (Component, mediaProps = LARGE_MEDIA_PROPS) => {
  const wrappedComponent = props => (
    <MediaQuery {...mediaProps}>
      { matches => !matches ? <Component {...props} /> : null }
    </MediaQuery>
  );
  wrappedComponent.displayName = `WithOnlySmallMedia(${getDisplayName(Component)})`;
  return wrappedComponent;
};

export const withMediaSelect = (LargeMediaComponent, SmallMediaComponent, mediaProps = LARGE_MEDIA_PROPS) => {
  if (LargeMediaComponent && SmallMediaComponent) {
    const wrappedComponent = props => (
      <MediaQuery {...mediaProps}>
        { matches => matches ? <LargeMediaComponent {...props} /> : <SmallMediaComponent {...props} /> }
      </MediaQuery>
    );
    wrappedComponent.displayName = (
      `WithMediaSelect(${getDisplayName(LargeMediaComponent)}, ${getDisplayName(SmallMediaComponent)})`
    );
    return wrappedComponent;
  } else if (LargeMediaComponent) {
    return withOnlyLargeMedia(LargeMediaComponent, mediaProps);
  } else if (SmallMediaComponent) {
    return withOnlySmallMedia(SmallMediaComponent, mediaProps);
  }
  return null;
};
