import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Marker, OverlayView } from '@react-google-maps/api';
import styles from './point.scss';

const Point = ({ highlighted, ...passThroughProps }) => {
  if (highlighted) {
    const { icon, ...markerProps } = passThroughProps;
    return (
      <OverlayView mapPaneName={OverlayView.FLOAT_PANE} {...markerProps}>
        <img
          alt=""
          className={styles.selectedPoint}
          src={icon.url}
          style={{
            msTransform: `translateX(-${icon.anchor.x}px) translateY(-${icon.anchor.y}px)`,
            transform: `translateX(-${icon.anchor.x}px) translateY(-${icon.anchor.y}px)`
          }}
        />
      </OverlayView>
    );
  }
  return <Marker {...passThroughProps} />;
};

Point.propTypes = {
  highlighted: PropTypes.bool
};

export default memo(Point);
