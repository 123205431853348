const getEnvironmentUrl = () => {
  switch (process.env.NODE_ENV) {
  case 'production':
    return '';
  default:
    // return 'https://dev-tennant.i4tnqjmicotck0zh.dev.dotmapsapp.com';
    return 'http://localhost:8000';
  }
};

export const BASE_URL = getEnvironmentUrl();
export const BASE_API_URL = `${BASE_URL}/publicapi`;
export const FORMAT = '?format=json';

export const buildFullUrl = relativeUrl => `${BASE_URL}${relativeUrl}`;
export const getApiRequestUrl = (type, format = FORMAT) => `${BASE_API_URL}/${type}/${format}`;
export const getDirectionsUrl = (lat, lng) => `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
