import { createStore, compose, applyMiddleware } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';

function configureStoreProd(initialState, history) {
  const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [
    // Add other middleware on this line...
    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
    thunk,
    reactRouterMiddleware
  ];

  return createStore(
    rootReducer(history),
    initialState,
    compose(applyMiddleware(...middlewares))
  );
}

const sanitizeMapDataList = state => ({
  ...state,
  map: {
    ...state.map,
    data: Object.keys(state.map.data).reduce(
      (acc, key) => {
        acc[key] = {
          ...state.map.data[key],
          list: state.map.data[key].list.slice(0, 10)
        };
        return acc;
      },
      {}
    )
  }
});

function configureStoreDev(initialState, history) {
  const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [
    // Add other middleware on this line...

    // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
    reduxImmutableStateInvariant({ignore: ['map.data', 'map.selection', 'map.linkedElement']}),

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
    thunk,
    reactRouterMiddleware
  ];

  // add support for Redux dev tools
  const composeEnhancers = (
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        stateSanitizer: state => sanitizeMapDataList(state)
      }) :
      compose
  );

  const store = createStore(
    rootReducer(history),
    initialState,
    composeEnhancers(
      applyMiddleware(...middlewares)
    )
  );

  if (module.hot) {
    const connectRouterHistory = connectRouter(history);

    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers').default; // eslint-disable-line global-require
      store.replaceReducer(connectRouterHistory(nextRootReducer));
    });
  }

  return store;
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;
