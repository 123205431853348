import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadSiteData } from '@actions/site-actions';
import { getInitialUrlFilter } from '@selectors/filters';
import { getDevSecret, getRequiresDevSecret } from '@selectors/map';
import { getSelection } from '@selectors/search';
import { getFavicon } from '@selectors/site';
import LoadingPage from './loading-page';
import LoginPage from './dev-login';
import styles from './loading-screens.scss';

const LoadingScreens = () => {
  const dispatch = useDispatch();
  const devSecret = useSelector(getDevSecret);
  const initialUrlFilter = useSelector(getInitialUrlFilter);
  const requiresDevSecret = useSelector(getRequiresDevSecret);
  const selection = useSelector(getSelection);
  const favicon = useSelector(getFavicon);

  useEffect(() => {
    dispatch(loadSiteData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadSiteData());
  }, [dispatch, devSecret]);

  useEffect(() => {
    if (favicon) {
      const element = document.getElementById('favicon');
      if (element) {
        element.setAttribute('href', favicon);
      }
    }
  }, [favicon]);

  const searchParams = window.location.search ? window.location.search.substr(1).split('=') : null;
  const showLogin = requiresDevSecret && !devSecret;
  return (
    <div className={styles.container}>
      {showLogin &&
        <LoginPage/>
      }
      {!showLogin && initialUrlFilter && !selection &&
        <LoadingPage linkType={searchParams[0]} linkValue={searchParams[1]} />
      }
    </div>
  );
};

export default memo(LoadingScreens);
