/* eslint-disable react/jsx-no-bind */
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDrawingArea,
  selectDrawingPolygonArea
} from '@actions/map-actions';
import { mapConfig } from '@constants/component-configs';
import { DrawingManager, useGoogleMap } from '@react-google-maps/api';
import { getDrawingVisible, getSelectionDrawingMode } from '@selectors/map-selection';

const MapDrawingTools = () => {
  const dispatch = useDispatch();

  const map = useGoogleMap();

  const drawingMode = useSelector(getSelectionDrawingMode);
  const drawingVisible = useSelector(getDrawingVisible);

  const [mapsShape, setMapsShape] = useState(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    // Cleanup when unmounting:
    return () => mapsShape?.setMap(null);
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const clearShape = () => {
    mapsShape?.setMap(null);
    setMapsShape(null);
    setActive(false);
  };

  const resetShape = () => {
    clearShape();
    setTimeout(() => setActive(true), 0);
  };

  useEffect(() => {
    if (drawingMode) {
      setActive(true);
    } else {
      clearShape();
    }
  }, [drawingMode]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (drawingMode) {
      resetShape();
    } else {
      clearShape();
    }
  }, [drawingVisible]);  // eslint-disable-line react-hooks/exhaustive-deps

  const onCircleComplete = shape => {
    mapsShape?.setMap(null);
    setMapsShape(shape);
    setActive(false);
    dispatch(selectDrawingArea(shape.center, map, shape.radius, true));
  };

  const onPolygonComplete = shape => {
    const shapeArray = shape.getPath().getArray();
    mapsShape?.setMap(null);
    setActive(false);
    if (shapeArray.length < 3) {
      return;
    }
    setMapsShape(shape);
    dispatch(selectDrawingPolygonArea(shapeArray));
  };

  const mode = (Boolean(drawingMode.length) && active && drawingMode) || null;
  if (!mode) {
    return null;
  }
  return (
    <DrawingManager
      drawingMode={mode}
      options={mapConfig.drawingManager}
      onPolygonComplete={onPolygonComplete}
      onCircleComplete={onCircleComplete}
    />
  );
};

export default memo(MapDrawingTools);
