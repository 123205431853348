/* global google */
/* eslint-disable react/no-string-refs */
/* eslint-disable react/jsx-no-bind */
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {
  resendConfirmation,
  saveNotification,
  setNotificationSuccess,
  TYPE_RECORDS
} from '@actions/notification-actions';
import { closeSubscribeDialog } from '@actions/ui-actions';
import ConfirmIcon from '@components/notifications/confirm-icon';
import { getSelectedElement } from '@selectors/map-selection';
import styles from './subscribe-modal.scss';


const SubscribeModal = () => {
  const dispatch = useDispatch();
  const { subscribeVisible } = useSelector(state => state.ui.dialog);
  const selectedElement = useSelector(getSelectedElement);
  const { notificationsFrequency: frequencyOptions, subscribeConfig = {} } = useSelector(state => state.site);
  const { success } = useSelector(state => state.notifications);
  const { fieldId, fieldLabel } = subscribeConfig;
  const { data, layerData } = selectedElement;
  const fieldValue = data.attrs[fieldId];
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [frequency, setFrequency] = useState(2);

  const onCancel = () => {
    dispatch(closeSubscribeDialog());
    dispatch(setNotificationSuccess(false));
  };

  const onSubscribe = () => {
    const attrs = {
      entityId: data.attrs.id,  // Id to locate the entity.
      layerId: layerData.id,  // Id to locate the type of entity (gis or geotemporal).
      fieldId,  // Entity attrs attribute name.
      fieldValue  // Entity attrs attribute value.
    };
    // A shape is required since it's an indexed field on the database,
    // but subscriptions don't have shapes, so use an empty one:
    const mapsShape = new google.maps.Polygon({
      path: [
        new google.maps.LatLng(0, 0),
        new google.maps.LatLng(0, 0),
        new google.maps.LatLng(0, 0)
      ]
    });
    const shape = [...mapsShape.getPath().getArray()];
    dispatch(saveNotification(TYPE_RECORDS, shape, layerData.label, frequency, email, null, attrs));
  };

  const onSetEmail = value => {
    setEmail(value);
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) && value !== '') {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const onResendConfirmation = () => {
    dispatch(resendConfirmation());
  };

  return (
    <Dialog
      aria-labelledby="dialog-title"
      PaperProps={{ classes: {root: styles.container } }}
      open={subscribeVisible}
    >
      <DialogTitle id="dialog-title" className={classNames(styles.title, {[styles.center]: success })}>
        {!success && <span>Subscribe to updates</span>}
        {success && <span>Confirm your email address</span>}
      </DialogTitle>
      <DialogContent className={styles.content}>
        {!success && (
          <Fragment>
            <div className={styles.formEntry}>
              <div className={styles.formLabel}>
                Get email notifications for any updates on the record.
              </div>
            </div>
            <div className={styles.formEntry}>
              <div className={styles.formLabel}>
                {fieldLabel}
              </div>
              <div className={styles.formValue}>
                {fieldValue}
              </div>
            </div>
            <div className={styles.formEntry}>
              <div className={styles.formLabel}>Email me</div>
              <Select
                disableUnderline
                className={styles.formInput}
                classes={{root: styles.formSelect}}
                fullWidth
                labelWidth={0}
                onChange={event => setFrequency(event.target.value)}
                value={frequency}
              >
                {frequencyOptions?.map(item => (
                  <MenuItem
                    classes={{selected: styles.selectedItem}}
                    key={item.value} value={item.value}
                  >{item.label}</MenuItem>
                ))}
              </Select>
            </div>
            <div className={styles.formEntry}>
              <div className={styles.formLabel}>
                At
              </div>
              <Input
                className={styles.formInput}
                onChange={event => onSetEmail(event.target.value)}
                error={emailError}
                value={email}
                placeholder="example@domain.com"
                labelWidth={0}
                disableUnderline
                aria-describedby="component-error-text"
              />
              {emailError &&
                <FormHelperText className={styles.formError}>Enter a valid email</FormHelperText>
              }
            </div>
          </Fragment>
        )}
        {success && (
          <div className={styles.confirmationContainer}>
            <div className={styles.confirmationRow}>
              <ConfirmIcon />
            </div>
            <div className={styles.confirmationRow}>
              We've sent you an email to verify your email address.
              <br />
              <br />
              Please check your inbox for a confirmation email.
              <br />
              If you can't find the email, check your spam folder or&nbsp;
              <Link color="primary" onClick={onResendConfirmation}>
                resend confirmation email
              </Link>.
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions className={styles.buttons}>
        {!success && (
          <Fragment>
            <div className={styles.cancel}>
              <Button onClick={onCancel}>CANCEL</Button>
            </div>
            <div className={styles.subscribe}>
              <Button
                color="secondary"
                disabled={email === null || email === '' || emailError}
                onClick={onSubscribe}
                variant="contained"
              >SUBSCRIBE</Button>
            </div>
          </Fragment>
        )}
        {success && (
          <Button className={styles.ok} color="secondary" onClick={onCancel} variant="contained">OK</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SubscribeModal;
