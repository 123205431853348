import axios from 'axios';
import Cookie from 'universal-cookie';
import { closeLegend, setLayerFiltersDefaultOpen } from '@actions/ui-actions';
import {
  OPEN_NOTIFICATIONS,
  CLOSE_NOTIFICATIONS,
  SET_NOTIFICATION_DRAWING_SHAPE,
  SET_NOTIFICATION_STEP,
  SET_NOTIFICATION_DATA_TYPES,
  SET_NOTIFICATION_FREQUENCY,
  SET_NOTIFICATION_EMAIL,
  SET_NOTIFICATION_NAME,
  SET_NOTIFICATION_SUCCESS,
  SET_NOTIFICATION_SHAPE_ERROR,
  SET_NOTIFICATION_EMAIL_ERROR,
  SET_NOTIFICATION_PAUSED,
  SET_NOTIFICATION_UUID,
  SET_NOTIFICATION_TOOLTIP_VISIBLE
} from '@constants/action-types';
import { getApiRequestUrl, buildFullUrl } from '@constants/endpoints';
import { getDevSecretParams } from '@selectors/map';
import turfArea from '@turf/area';
import { polygon } from '@turf/helpers';
import { mapsPolygonToFeature } from '@utils/map-utils';

const setNotificationSuccessAction = success => ({ type: SET_NOTIFICATION_SUCCESS, success });

const setNotificationTooltipVisible = tooltipVisible => ({
  type: SET_NOTIFICATION_TOOLTIP_VISIBLE,
  tooltipVisible
});

export const closeNotificationTooltip = () => dispatch => {
  const cookie = new Cookie();
  cookie.set('notificationTooltipSeen', true, { path: '/' });
  return dispatch(setNotificationTooltipVisible(false));
};

const setNotificationPausedAction = paused => ({
  type: SET_NOTIFICATION_PAUSED,
  paused
});

export const setNotificationPaused = paused => dispatch => {
  return dispatch(setNotificationPausedAction(paused));
};

const openNotificationsAction = () => ({
  type: OPEN_NOTIFICATIONS
});

const closeNotificationsAction = () => ({
  type: CLOSE_NOTIFICATIONS
});

export const openNotifications = () => dispatch => {
  dispatch(setNotificationTooltipVisible(false));
  dispatch(setLayerFiltersDefaultOpen(false));
  dispatch(closeLegend());
  dispatch(openNotificationsAction());
};

export const closeNotifications = () => (dispatch, getState) => {
  const { step, drawingShape } = getState().notifications;
  if (step > 1 || drawingShape) {
    dispatch(setNotificationPausedAction(true));
  }
  dispatch(closeNotificationsAction());
  dispatch(setNotificationSuccessAction(false));
};

const setNotificationShapeErrorAction = shapeError => ({
  type: SET_NOTIFICATION_SHAPE_ERROR,
  shapeError
});

const setNotificationDrawingShapeAction = drawingShape => ({
  type: SET_NOTIFICATION_DRAWING_SHAPE,
  drawingShape
});

export const setNotificationDrawingShape = shape => dispatch => {
  let size = null;
  if (shape) {
    const area = {
      coordinates: [[...shape.map(coord => [coord.lng(), coord.lat()]), [shape[0].lng(), shape[0].lat()]]]
    };
    size = turfArea(polygon(area.coordinates));
  }
  if (size && size > 405000) {
    dispatch(setNotificationShapeErrorAction(true));
  } else {
    dispatch(setNotificationShapeErrorAction(false));
  }
  return dispatch(setNotificationDrawingShapeAction(shape));
};

const setNotificationStepAction = step => ({
  type: SET_NOTIFICATION_STEP,
  step
});

export const setNotificationStep = step => dispatch => {
  return dispatch(setNotificationStepAction(step));
};

const setNotificationDataTypesAction = dataTypes => ({
  type: SET_NOTIFICATION_DATA_TYPES,
  dataTypes
});

export const setNotificationDataTypes = dataTypes => dispatch => {
  return dispatch(setNotificationDataTypesAction(dataTypes));
};

const setNotificationFrequencyAction = frequency => ({
  type: SET_NOTIFICATION_FREQUENCY,
  frequency
});

export const setNotificationFrequency = frequency => dispatch => {
  return dispatch(setNotificationFrequencyAction(frequency));
};

const setNotificationEmailErrorAction = emailError => ({
  type: SET_NOTIFICATION_EMAIL_ERROR,
  emailError
});

const setNotificationEmailAction = email => ({
  type: SET_NOTIFICATION_EMAIL,
  email
});

export const setNotificationEmail = email => dispatch => {
  dispatch(setNotificationEmailErrorAction(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)));
  dispatch(setNotificationEmailAction(email));
};

const setNotificationNameAction = name => ({
  type: SET_NOTIFICATION_NAME,
  name
});

export const setNotificationName = name => dispatch => {
  return dispatch(setNotificationNameAction(name));
};

export const setNotificationSuccess = success => dispatch => {
  return dispatch(setNotificationSuccessAction(success));
};

const setNotificationUuidAction = uuid => ({
  type: SET_NOTIFICATION_UUID,
  uuid
});

export const resetNotificationForm = () => dispatch => {
  dispatch(setNotificationDrawingShapeAction(null));
  dispatch(setNotificationDataTypesAction([]));
  dispatch(setNotificationFrequencyAction(2));
  dispatch(setNotificationEmailAction(null));
  dispatch(setNotificationNameAction(null));
  dispatch(setNotificationStepAction(1));
  dispatch(setNotificationSuccessAction(false));
  dispatch(setNotificationPaused(false));
};

export const TYPE_AREA = 1;
export const TYPE_RECORDS = 2;

// eslint-disable-next-line max-params
export const saveNotification = (type, shape, dataTypes, frequency, email, name, attrs) => (dispatch, getState) => {
  const url = getApiRequestUrl('notification');
  const params = {
    type,
    attrs,
    shape: mapsPolygonToFeature(shape).geometry,
    entity_type: dataTypes, // eslint-disable-line camelcase
    frequency,
    email,
    name
  };

  axios.post(
    url,
    params,
    {headers: {Authorization: `secret ${getDevSecretParams(getState()).secret}`}}
  ).then((payload) => {
    dispatch(setNotificationSuccessAction(true));
    dispatch(setNotificationUuidAction(payload.data.uuid));
    return payload;
  })
    .catch(() => {
      dispatch(setNotificationSuccessAction(false));
    });
};

export const resendConfirmation = () => (dispatch, getState) => {
  const url = buildFullUrl('/notification/send_confirmation/') + getState().notifications.uuid;
  axios.get(
    url,
    {headers: {Authorization: `secret ${getDevSecretParams(getState()).secret}`}}
  );
};
