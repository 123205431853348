import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './loading-indicator.scss';

const LoadingIndicator = () => (
  <div className={styles.progressBackground}>
    <CircularProgress
      classes={{circle: styles.progressCircle}}
      thickness={6}
      size={50} />
  </div>
);

export default LoadingIndicator;
