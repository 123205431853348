import { mapStylers } from './google-maps';
import memoize from 'memoize-one';

export const desktopMinWidth = 768;

export const drawingFigureOptions = {
  strokeColor: '#4A4A4A',
  fillOpacity: 0,
  editable: false,
  draggable: false,
  clickable: false
};

export const freehandDrawingFigureOptions = {
  strokeColor: '#4A4A4A',
  fillOpacity: 0.2,
  strokeWeight: 5,
  editable: false,
  draggable: false,
  clickable: false
};

export const freehandDrawingFigureHighlightOptions = {
  fillOpacity: 0,
  strokeWeight: 3,
  editable: false,
  draggable: false,
  clickable: false
};

export const mapConfig = {
  defaultCenter: { lat: 41.878114, lng: -87.629798 },
  defaultZoom: 15,
  mobileMapOptions: {
    styles: mapStylers,
    disableDefaultUI: false,
    zoomControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    gestureHandling: 'greedy'
  },
  highlightCircleOptions: {
    clickable: false,
    draggable: false,
    editable: false,
    fillColor: '#406EE3',
    fillOpacity: 0.4,
    strokeWeight: 0,
    zIndex: 200
  },
  drawingManager: {
    drawingControl: false, // Hides the drawing tools toolbar.
    polygonOptions: drawingFigureOptions,
    circleOptions: drawingFigureOptions
  },
  notificationDrawingOptions: {
    drawingControl: false,
    polygonOptions: {
      strokeColor: '#4A4A4A',
      strokeOpacity: 0.8,
      fillOpacity: 0,
      editable: true,
      draggable: false,
      clickable: false
    }
  }
};

export const getMapOptions = memoize(google => ({
  mapOptions: {
    styles: mapStylers,
    disableDefaultUI: false,
    zoomControl: true,
    zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_BOTTOM },
    streetViewControl: true,
    streetViewControlOptions: { position: google.maps.ControlPosition.RIGHT_BOTTOM },
    mapTypeControl: false,
    fullscreenControl: false,
    gestureHandling: 'greedy'
  }
}));
