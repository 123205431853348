/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import Icon from '@material-ui/core/Icon';

import InfoPopup from '../info-popup';
import FilterToggle from '../filter-toggle';
import FilterCardLayers from '../filter-card-layers';
import ThemeStyle, {PRIMARY_TEXT, INHERIT} from '../../theme-style';

import { getMapData } from '../../../selectors/map';
import { getlayerFilters } from '../../../selectors/ui';

import { toggleFilter } from '../../../actions/filter-actions';

import styles from './filter-card.scss';

class FilterCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      popover: null
    };

    this.toggleCardVisibility = this.toggleCardVisibility.bind(this);
    this.mainToggleHandler = this.mainToggleHandler.bind(this);
  }

  toggleCardVisibility = (event, id, open) => {
    event.stopPropagation();
    this.setState({open: !open});
  };

  mainToggleHandler = id => {
    this.setState({open: !this.props.mapData[id].checked});
    this.props.toggleFilter(id);
  };

  render() {
    const { filterChildren, id, label, layerFilters, mapData } = this.props;
    const { checked, mapStyle, uiStyle: { filterIcon, infoText, infoLink } } = mapData[id];
    const { open } = this.state;
    const visibleChildren = filterChildren && filterChildren.some(child => !child.uiStyle.hidden);
    return (
      <div className={styles.filterCard}>
        <div className={styles.filterCardToggle} onClick={() => this.mainToggleHandler(id)}>
          <FilterToggle
            color={mapStyle && mapStyle.strokeColor}
            className={styles.toggle}
            checked={checked}
            icon={filterIcon}
          />
          <ThemeStyle
            className={classNames(styles.filterLabel, {[styles.checked]: checked})}
            style={checked ? PRIMARY_TEXT : INHERIT}>
            <label>{label}</label>
          </ThemeStyle>
          {infoText &&
            <InfoPopup label={label} className={styles.helpIcon}>
              {infoText}
              {infoLink &&
                <p><a href={infoLink.href} target="_blank" rel="noopener noreferrer">{infoLink.label}</a></p>
              }
            </InfoPopup>
          }
          {visibleChildren &&
            <div className={styles.arrowIcon} onClick={event => this.toggleCardVisibility(event, id, open)}>
              {!open && <Icon>keyboard_arrow_down</Icon>}
              {open && <Icon>keyboard_arrow_up</Icon>}
            </div>
          }
        </div>
        {open && visibleChildren && layerFilters.visible &&
          <FilterCardLayers
            id={id}
            filterChildren={filterChildren}
            mapData={mapData}
          />
        }
      </div>
    );
  }
}

FilterCard.propTypes = {
  filterChildren: PropTypes.array,
  id: PropTypes.number,
  label: PropTypes.string,
  layerFilters: PropTypes.object,
  mapData: PropTypes.object,
  toggleFilter: PropTypes.func
};

const mapStateToProps = state => {
  return {
    layerFilters: getlayerFilters(state),
    mapData: getMapData(state)
  };
};

export default connect(mapStateToProps, { toggleFilter })(FilterCard);
