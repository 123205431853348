import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from '@material-ui/core';
import MobileIcon from '../mobile-icon';
import MobileCard from '../mobile-card';
import { getMapData } from '../../../selectors/map';
import { getFilters } from '../../../selectors/filters';
import { getNotificationsVisible } from '../../../selectors/notifications';
import {
  setInitialUrlFilter,
  fetchDateRanges,
  clearInitialUrlFilter,
  fetchFilterStructure,
  toggleFilter
} from '../../../actions/filter-actions';
import { showMobileLayers } from '../../../actions/ui-actions';
import { renameFilterChildren } from '@utils/filter-utils';
import styles from './mobile-filter.scss';

class MobileFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openFilter: null
    };

    this.setOpenFilter = this.setOpenFilter.bind(this);
    this.closeFilter = this.closeFilter.bind(this);
  }

  componentWillMount() {
    if (this.props.filters.length === 0) {
      this.props.fetchFilterStructure();
      this.props.fetchDateRanges();
    }
  }

  componentDidMount() {
    const searchParams = window.location.search ? window.location.search.substr(1).split('=') : null;
    if (searchParams) {
      this.props.setInitialUrlFilter();
    } else {
      this.props.clearInitialUrlFilter();
    }
  }

  setOpenFilter(filterId) {
    const {openFilter} = this.state;
    const {mapData} = this.props;

    if (!openFilter || openFilter === filterId || !mapData[filterId].checked) {
      this.props.toggleFilter(filterId);
    }

    if (openFilter || !mapData[filterId].checked) {
      this.setState({ openFilter: filterId });
    }
  }

  closeFilter() {
    this.setState({ openFilter: null });
  }

  render() {
    const {initialUrlFilter, filters} = this.props;
    const {openFilter} = this.state;
    if (initialUrlFilter === null) {
      return null;
    }
    let openFilterData;
    if (openFilter) {
      openFilterData = filters.find(filter => filter.id === openFilter);
    }

    if (this.props.notificationsVisible) {
      return null;
    }
    return (
      <div className={styles.mobileFilterRoot}>
        <div className={classNames(styles.mobileFilterWrap, {[styles.mobileFilterWrapOpen]: openFilterData})}>
          <div className={styles.mapFilters}>
            {filters.length > 0 && filters.map(filter => (
              <div key={filter.id}>
                <MobileIcon
                  open={openFilter === filter.id}
                  onOpen={this.setOpenFilter}
                  {...filter}
                />
              </div>
            ))}
          </div>
          <div className={styles.mapLayersButton}>
            <Icon onClick={this.props.showMobileLayers}>layers</Icon>
          </div>
        </div>
        {openFilterData &&
          <MobileCard onClose={this.closeFilter} {...renameFilterChildren(openFilterData)} />
        }
      </div>
    );
  }
}

MobileFilter.propTypes = {
  clearInitialUrlFilter: PropTypes.func,
  fetchDateRanges: PropTypes.func,
  fetchFilterStructure: PropTypes.func,
  filters: PropTypes.array,
  initialUrlFilter: PropTypes.bool,
  mapData: PropTypes.object,
  notificationsVisible: PropTypes.bool,
  setInitialUrlFilter: PropTypes.func,
  showMobileLayers: PropTypes.func,
  toggleFilter: PropTypes.func
};

const mapStateToProps = state => ({
  filters: getFilters(state),
  mapData: getMapData(state),
  notificationsVisible: getNotificationsVisible(state)
});

export default connect(
  mapStateToProps,
  {
    setInitialUrlFilter,
    clearInitialUrlFilter,
    fetchDateRanges,
    fetchFilterStructure,
    showMobileLayers,
    toggleFilter,
    getNotificationsVisible
  }
)(MobileFilter);
