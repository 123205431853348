/* eslint-disable max-nested-callbacks */
import { createSelector } from 'reselect';
import { getMapData } from './map';
import * as R from 'ramda';

// export const getGeocodeSuggestions = state => state.search.geocodeSuggestions;
export const getGeocoderSuggestions = state => state.search.suggestions.geocoder;
export const getPlacesSuggestions = state => state.search.suggestions.places;
export const getSearchTerm = state => state.search.searchTerm;
export const getSearchMarker = state => state.search.showMarker;
export const getSearchFocused = state => state.search.focused;
export const getSelectedLocation = state => state.search.selectedLocation;
export const getselectedLayerElement = state => state.search.selectedLayerElement;

export const getSelection = createSelector(
  [getSelectedLocation, getselectedLayerElement],
  (location, layer) => location || layer || null
);

export const getSelectedPosition = createSelector(
  [getSelectedLocation],
  (location) => location && location.position
);

export const getSearchableLayerData = createSelector(
  [getMapData], mapData => (Object.values(mapData).filter(layerData => layerData.uiStyle.searchable))
);

// Memoize truthy result as long as search remains the same:
let lastSearch = null;
let lastResult = null;
export const getLinkedElement = createSelector(
  [getSearchableLayerData, () => window.location.search],
  (searchableLayers, search) => {
    if (lastSearch === search && lastResult) {
      return lastResult;
    }
    lastSearch = search;
    const links = [];
    search.substr(1).split(',')
      .forEach(param => {
        const [paramName, searchValue] = param.split('=');
        searchableLayers.filter(layerData => paramName === layerData.name).forEach(layerData => {
          const {id: layerId, list, uiStyle: {searchKey, searchLabel, searchGroup}} = layerData;
          links.push(...(
            list.filter(element => element.attrs[searchKey] === searchValue)
              .map(({id, bbox, attrs: {[searchKey]: match}}) => (
                { id, bbox, label: `${searchLabel} ${match}`, layerId, type: searchGroup }
              ))
          ));
        });
      });
    lastResult = links[0] || null;
    return lastResult;
  }
);

const getGeocodeSuggestions = createSelector(
  [getGeocoderSuggestions, getPlacesSuggestions],
  (geocoderSuggestions, placesSuggestions) => {
    if (geocoderSuggestions && placesSuggestions) {
      if (geocoderSuggestions.length || placesSuggestions.length) {
        const filteredGeocoding = geocoderSuggestions.filter(
          ({types}) => types.indexOf('intersection') > -1 || types.indexOf('postal_code') > -1
        );
        const suggestions = filteredGeocoding.concat(placesSuggestions.filter(suggestion =>
          !R.find(R.propEq(suggestion.place_id, 'place_id'), filteredGeocoding)
        ));
        if (suggestions.length > 0) {
          return [...suggestions.slice(0, 5)];
        }
      }
      return [];
    }
    return null;
  }
);

const getLayerSuggestions = createSelector(
  [getSearchTerm, getSearchableLayerData],
  (searchValue, layerDatas) => {
    if (searchValue) {
      const searchLower = searchValue.toLowerCase();
      let searchResults = [];
      layerDatas.forEach(layerData => {
        const {id: layerId, uiStyle: { searchGroup, searchKey, searchLabel } } = layerData;
        const layerSearchResults = (
          layerData.list
            .filter(element => {
              const searchTerm = `${searchLabel} ${element.attrs[searchKey]}`.toLowerCase();
              return searchTerm.includes(searchLower);
            })
            // eslint-disable-next-line id-length
            .sort((a, b) => a.attrs[searchKey].localeCompare(b.attrs[searchKey]))
            .slice(0, 5)
            .map(({id, bbox, attrs: {[searchKey]: match}}) => (
              { id, bbox, label: `${searchLabel} ${match}`, value: `layer-${id}`, layerId, type: searchGroup }
            ))
        );
        if (layerSearchResults.length > 0) {
          const header = {label: searchGroup, value: null, isDisabled: true, type: searchGroup, header: true};
          searchResults = [...searchResults, header, ...layerSearchResults];
        }
      });
      return searchResults;
    }
    return null;
  }
);

export const getSuggestions = createSelector(
  [getGeocodeSuggestions, getLayerSuggestions],
  (geocodeSuggestions, layerSuggestions) => {
    if (geocodeSuggestions && layerSuggestions) {
      return [...geocodeSuggestions || [], ...layerSuggestions || []];
    }
    return null;
  }
);


export const getIsLoading = createSelector(
  [getSearchTerm, getSuggestions],
  (searchTerm, suggestions) => Boolean(searchTerm && !suggestions)
);

export const getMenuIsOpen = createSelector(
  [getIsLoading, getSuggestions],
  (loading, suggestions) => Boolean(!loading && suggestions)
);
