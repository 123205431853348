import React from 'react';
import { render } from 'react-dom';
import { createBrowserHistory } from 'history';
import Root from './components/Root';
import configureStore from './store/configure-store';

import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';

import './styles/global.scss';

const history = createBrowserHistory();

const store = configureStore({}, history);

const generateClassName = createGenerateClassName();
const jss = create(jssPreset());
// We define a custom insertion point that JSS will look for injecting the styles in the DOM.
jss.options.insertionPoint = 'jss-insertion-point';

render(
  <JssProvider jss={jss} generateClassName={generateClassName}>
    <Root store={store} history={history} />
  </JssProvider>,
  document.getElementById('app')
);
