/* global google */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '@material-ui/core/Icon';
import Drawer from '@material-ui/core/Drawer';
import LocationButton from '../location-button';
import MapTypes from '../map-types';

import { getMobileLayers } from '../../../selectors/ui';
import { getDrawingVisible, getSelectionDrawingMode } from '../../../selectors/map-selection';
import { getStyle } from '../../../selectors/site';
import { getNotificationsVisible } from '../../../selectors/notifications';
import { hideMobileLayers, showDrawingOverlay } from '../../../actions/ui-actions';
import { selectDrawingPolygonArea, setDrawingMode, closeAreaSelection } from '../../../actions/map-actions';
import {
  freehandDrawingFigureOptions, freehandDrawingFigureHighlightOptions
} from '../../../constants/component-configs';

import ThemeStyle, {PRIMARY_TEXT, INHERIT} from '../../theme-style';

import styles from './mobile-map-tools.scss';

import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

class MobileMapTools extends Component {
  state = {shape: null, drawingActive: false, touchStartListener: null};

  componentWillReceiveProps(nextProps) {
    if (this.state.shape && !nextProps.drawingVisible) {
      this.clearShape();
    }
    if (this.state.drawingActive && nextProps.drawingMode === '') {
      const map = this.props.mapRef.current;
      google.maps.event.removeListener(this.state.touchStartListener);
      this.enableMap(map);
      this.setState({ shape: null, highlightShape: null, drawingActive: false });
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  disableMap = map => map.setOptions({draggable: false, scrollwheel: false});
  enableMap = map => map.setOptions({draggable: true, scrollwheel: true});
  setFreehand = () => this.props.setDrawingMode('freehand');

  clearShape = () => {
    this.state.shape.setMap(null);
    this.state.highlightShape.setMap(null);
    this.setState({ shape: null, highlightShape: null, drawingActive: false });
  };

  drawFreeHand = map => {
    let poly = new google.maps.Polyline({map, clickable: false});
    const move = google.maps.event.addListener(map, 'mousemove', event => {
      poly.getPath().push(event.latLng);
    });

    google.maps.event.addDomListenerOnce(map.getDiv(), 'touchend', () => {
      const { root } = this.props.style;
      const polygonOptions = {
        ...freehandDrawingFigureOptions,
        strokeColor: 'rgba(255, 255, 255, 0.6)',
        fillColor: root.secondaryColor
      };
      const polygonHighlightOptions = {
        ...freehandDrawingFigureHighlightOptions,
        strokeColor: root.secondaryColor
      };
      enableBodyScroll(document.querySelector('#app'));
      google.maps.event.removeListener(move);
      const path = poly.getPath();
      poly.setMap(null);
      poly = new google.maps.Polygon({map, path, ...polygonOptions});
      const polygonHighlight = new google.maps.Polygon({map, path, ...polygonHighlightOptions});
      this.setState({shape: poly, highlightShape: polygonHighlight});
      this.props.selectDrawingPolygonArea(path.getArray());
      google.maps.event.removeListener(this.state.touchStartListener);
      this.enableMap(map);
    });
  };

  startDrawing = () => {
    if (this.state.drawingActive) {
      return;
    }
    this.setFreehand();
    this.props.showDrawingOverlay();
    if (this.state.shape) {
      this.state.shape.setMap(null);
    }
    this.setState({ drawingActive: true });
    const map = this.props.mapRef.current;
    disableBodyScroll(document.querySelector('#app'));
    this.disableMap(map);
    this.setState({ touchStartListener: google.maps.event.addDomListener(map.getDiv(), 'touchstart', () => {
      this.drawFreeHand(map);
    })});
  };

  render() {
    return (
      <Fragment>
        <div>
          <div className={styles.buttonGroup}>
            { !this.props.notificationsVisible &&
              <Fragment>
                { !this.props.drawingVisible ?
                  <div className={styles.button} onClick={this.startDrawing}>
                    <ThemeStyle style={this.state.drawingActive ? PRIMARY_TEXT : INHERIT}>
                      <Icon>touch_app</Icon>
                    </ThemeStyle>
                  </div> :
                  <div className={styles.button} onClick={this.props.closeAreaSelection}>
                    <Icon>close</Icon>
                  </div>
                }
              </Fragment>
            }
            <div className={classNames(styles.button, {[styles.notifications]: this.props.notificationsVisible})}>
              <LocationButton />
            </div>
          </div>
        </div>
        <Drawer anchor="bottom" open={this.props.mobileLayers.visible} onClose={this.props.hideMobileLayers}>
          <div className={styles.layersContainer}>
            <label className={styles.header}>
              <div className={styles.title}>Map Layers</div>
              <Icon onClick={this.props.hideMobileLayers}>close</Icon>
            </label>
            <MapTypes />
          </div>
        </Drawer>
      </Fragment>
    );
  }
}

MobileMapTools.propTypes = {
  closeAreaSelection: PropTypes.func,
  drawingMode: PropTypes.string,
  drawingVisible: PropTypes.bool,
  hideMobileLayers: PropTypes.func,
  mapRef: PropTypes.object,
  mobileLayers: PropTypes.object,
  notificationsVisible: PropTypes.bool,
  selectDrawingPolygonArea: PropTypes.func,
  setDrawingMode: PropTypes.func,
  showDrawingOverlay: PropTypes.func,
  style: PropTypes.object
};

const mapStateToProps = state => ({
  drawingVisible: getDrawingVisible(state),
  mobileLayers: getMobileLayers(state),
  drawingMode: getSelectionDrawingMode(state),
  style: getStyle(state),
  notificationsVisible: getNotificationsVisible(state)
});

export default connect(mapStateToProps, {
  hideMobileLayers,
  selectDrawingPolygonArea,
  showDrawingOverlay, setDrawingMode,
  closeAreaSelection,
  getNotificationsVisible
})(MobileMapTools);
