/* eslint-disable react/jsx-no-bind */
import React, { Fragment, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeLegend } from '@actions/ui-actions';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { getVisibleMapDataList } from '@selectors/map';
import { getLegend } from '@selectors/ui';
import MobileCarousel from './mobile-carousel';
import styles from './mobile.scss';

const MobileMapLegend = () => {
  const dispatch = useDispatch();

  const legend = useSelector(getLegend);
  const visibleLayers = useSelector(getVisibleMapDataList);

  const onCloseLegend = () => dispatch(closeLegend);

  useEffect(() => {
    dispatch(closeLegend);
  }, [dispatch]);

  if (legend.visible) {
    const layers = visibleLayers.filter(layer => layer.uiStyle.showInLegend);
    return (
      <Fragment>
        <div className={styles.legendTitle}>
          Legend
          <IconButton className={styles.closeButton} onClick={onCloseLegend}>
            <Icon>close</Icon>
          </IconButton>
        </div>
        <div className={styles.legendContent}>
          <MobileCarousel layers={layers} />
        </div>
      </Fragment>
    );
  }
  return null;
};

export default memo(MobileMapLegend);
