import React, { Fragment, memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Polyline } from '@react-google-maps/api';
import { getStyle } from '@selectors/site';

const LineSegment = ({
  highlighted,
  options,
  ...passThroughProps
}) => {
  const style = useSelector(getStyle);

  const { strokeWeight, ...passThroughOptions } = options;

  if (highlighted) {
    const highlightedStrokeWeight = strokeWeight + 2 || 4;
    const innerStrokeWeight = highlightedStrokeWeight + 3;
    const outerStrokeWeight = innerStrokeWeight + 5;
    const lineOptions = {
      ...passThroughOptions,
      strokeWeight: highlightedStrokeWeight,
      zIndex: 202
    };
    const innerOptions = {
      clickable: false,
      strokeWeight: innerStrokeWeight,
      zIndex: 201,
      strokeColor: 'white'
    };
    const outerOptions = {
      clickable: false,
      strokeWeight: outerStrokeWeight,
      zIndex: 200,
      strokeColor: style.root.secondaryColor,
      strokeOpacity: 0.6
    };
    return (
      <Fragment>
        <Polyline {...passThroughProps} options={lineOptions} />
        <Polyline {...passThroughProps} options={innerOptions} />
        <Polyline {...passThroughProps} options={outerOptions} />
      </Fragment>
    );
  }
  return (
    <Polyline {...passThroughProps} options={{strokeWeight, ...passThroughOptions}} />
  );
};

LineSegment.propTypes = {
  highlighted: PropTypes.bool,
  options: PropTypes.object
};

export default memo(LineSegment);
