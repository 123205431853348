import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import map from './map-reducer';
import ui from './ui-reducer';
import filters from './filter-reducer';
import search from './search-reducer';
import site from './site-reducer';
import snackbar from './snackbar-reducer';
import notifications from './notification-reducer';

// Read: https://github.com/supasate/connected-react-router/blob/master/FAQ.md#how-to-migrate-from-v4-to-v5v6
//
const createRootReducer = history => combineReducers({
  router: connectRouter(history),
  map,
  ui,
  filters,
  search,
  site,
  snackbar,
  notifications
});

const rootReducer = history => (state, action) => createRootReducer(history)(state, action);

export default rootReducer;
