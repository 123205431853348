import axios from 'axios';
import deepmerge from 'deepmerge';
import { setCenter, setZoom, requestAuthSecret } from '@actions/map-actions';
import { SITE_DATA_FETCH } from '@constants/action-types';
import { buildFullUrl } from '@constants/endpoints';
import { getDevSecretParams, getViewport } from '@selectors/map';
import { layerDataConfig } from '@utils/map-data-config';

const FALLBACK_SITE_DATA = {
  logo: '',
  contrastLogo: '',
  style: {
    root: {
      primaryColor: '#000000',
      secondaryColor: '#888888'
    }
  },
  map: {
    defaultCenter: { lat: 0, lng: 0 },
    defaultZoom: 2,
    localityString: ''
  }
};

export const getSiteDataAction = (config) => ({
  type: SITE_DATA_FETCH,
  config
});

const consumeSiteData = data => (dispatch, getState) => {
  const {zoom, center} = getViewport(getState());
  if (!zoom) {
    dispatch(setZoom(data.map.defaultZoom));
  }
  if (!center) {
    dispatch(setCenter(data.map.defaultCenter));
  }
  return dispatch(getSiteDataAction(data));
};

export const loadSiteData = () => (dispatch, getState) => {
  axios.get(
    buildFullUrl('/site/config.json'),
    {...layerDataConfig, params: getDevSecretParams(getState())}
  ).then(
    ({ data }) => {
      return dispatch(consumeSiteData(deepmerge(FALLBACK_SITE_DATA, data || {})));
    },
    error => {
      if (error.response.status === 403) {
        return dispatch(requestAuthSecret());
      }
      return dispatch(consumeSiteData({...FALLBACK_SITE_DATA}));
    }
  );
};
