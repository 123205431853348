/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-return-assign */
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import DOMPurify from 'dompurify';
import memoize from 'memoize-one';

import Icon from '@material-ui/core/Icon';

import ThemeStyle, {SECONDARY_TEXT} from '../../../../theme-style';

import styles from './detail-text.scss';

export default class DetailText extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {expanded: false, shouldExpand: true};
    this.sanitize = memoize(DOMPurify.sanitize);
  }

  componentDidMount() {
    this.setShouldExpand();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.label !== this.props.label || prevProps.value !== this.props.value) {
      this.setState({expanded: false, shouldExpand: true});  // eslint-disable-line react/no-did-update-set-state
      setTimeout(() => this.setShouldExpand(), 0);
    }
  }

  setShouldExpand = () => {
    if (this.divElement && this.innerElement) {
      const shouldExpand = (
        this.innerElement.clientHeight > this.divElement.clientHeight &&
        !this.props.flags.has('nocollapse')
      );
      this.setState({shouldExpand});
    }
  };

  toggleShowMore = () => {
    this.setState({expanded: !this.state.expanded});
  };

  render() {
    const { flags, label, value, ...passThrough} = this.props;
    const { shouldExpand, expanded } = this.state;

    if (value || !flags.has('noblank')) {
      let valueComponent;
      if (value) {
        if (value.href) {
          const contentDisposition = value['Content-Disposition'] || value['content-disposition'] || '';
          const match = /filename="([^"]*)"/.exec(contentDisposition);
          const filename = (match && match[1]) || value.label || value.href;
          valueComponent = (
            <a href={value.href} target="_blank" rel="noopener noreferrer" className={styles.detailLink}>
              {filename}
            </a>
          );
        } else {
          valueComponent = (
            <Fragment>
              <div className={styles.truncatedWrap}>
                <div
                  ref={divElement => this.divElement = divElement}
                  className={classNames({ [styles.truncated]: shouldExpand && !expanded })}
                >
                  <p
                    ref={innerElement => this.innerElement = innerElement}
                    className={styles.detailData}
                    dangerouslySetInnerHTML={{__html: this.sanitize(value)}}
                  />
                  { shouldExpand && !expanded &&
                      <div className={styles.gradient}>&nbsp;</div>
                  }
                </div>
              </div>
              { shouldExpand &&
                <div className={styles.showMore} onClick={this.toggleShowMore} role="presentation">
                  <div>{!expanded ? 'READ MORE' : 'SHOW LESS'}</div>
                </div>
              }
            </Fragment>
          );
        }
      } else {
        valueComponent = <div className={styles.detailData}>&ndash;</div>;
      }

      if (label) {
        if (label.icon) {
          return (
            <div {...passThrough}>
              <div className={styles.iconDetail}>
                <ThemeStyle style={SECONDARY_TEXT}>
                  <Icon className={styles.icon} >{label.icon}</Icon>
                </ThemeStyle>
                { valueComponent }
              </div>
            </div>
          );
        }
        return (
          <div {...passThrough}>
            <div className={styles.detailHeader}>{label}</div>
            { valueComponent }
          </div>
        );
      }
      return (
        <div {...passThrough}>
          <div className={styles.textBlock}>
            {valueComponent}
          </div>
        </div>
      );
    }
    return null;
  }
}

DetailText.propTypes = {
  flags: PropTypes.instanceOf(Set),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
