import { createSelector } from 'reselect';

import { getAreaDisplay, getLengthDisplay } from '@utils/map-utils';

import { getFilters, getFiltersFlatList, getFiltersFlat, getSelectedDateRange, getCustomDateRange } from './filters';

import { getDisclaimerText } from './site';

const getData = state => state.map.data;
const getHighlight = state => state.map.highlight || {};
export const getViewport = state => state.map.viewport;
export const getStreetView = state => state.map.streetView;
export const getMapTypeId = state => state.map.mapTypeId;
export const getTraffic = state => state.map.traffic;
export const getDrawingMode = state => state.map.drawing.mode;
export const getDrawingComplete = state => state.map.drawing.complete;
export const getDrawingArea = state => state.map.drawing.area;
const getDrawingSize = state => state.map.drawing.size;
export const getMeasureToolActive = state => state.map.measureTool.active;
const getMeasureToolLength = state => state.map.measureTool.length;
export const getUserLocation = state => state.map.userLocation;
export const getDevSecret = state => state.map.devSecret;
export const getRequiresDevSecret = state => state.map.requiresDevSecret;
export const getDisclaimer = state => state.map.disclaimer;
export const getError404Visible = state => state.map.error404Visible;

export const getDevSecretParams = createSelector(
  [getDevSecret, getRequiresDevSecret],
  (secret, required) => required ? {secret} : {}
);

export const getZoom = createSelector(
  [getViewport],
  viewport => viewport.zoom
);

export const getBbox = createSelector(
  [getViewport],
  viewport => viewport.bbox
);

const annotateStyleAndFilterData = (mapData, filtersFlat, dateRange, customDateRange) => {
  const annotatedData = {};
  Object.values(mapData).forEach(layerData => {
    const {requestUrl, mapStyle, uiStyle, ...filter} = filtersFlat[layerData.id];

    annotatedData[layerData.id] = {
      ...layerData,
      ...filter,
      url: requestUrl,
      mapStyle: {...mapStyle, clickable: false}, // with radius select, we don't need the map items to be clickable
      uiStyle,
      list: layerData.list.filter(item => {
        return (
          !item.date_ranges ||
          (dateRange && !dateRange.custom && item.date_ranges[dateRange.id]) ||
          (
            dateRange &&
            dateRange.custom &&
            customDateRange.start && customDateRange.end &&
            (!item.start || Date.parse(item.start) <= customDateRange.end) &&
            (!item.end || Date.parse(item.end) >= customDateRange.start)
          )
        );
      })
    };
  });
  return annotatedData;
};

const annotateVisibilityForTree = (mapData, filterTree) => {
  let annotatedData = {};
  filterTree.forEach(filter => {
    const data = mapData[filter.id];
    if (data.checked) {
      annotatedData[filter.id] = {...data, visible: true};
      if (filter.children) {
        annotatedData = {...annotatedData, ...annotateVisibilityForTree(mapData, filter.children)};
      }
    }
  });
  return annotatedData;
};

export const getMapData = createSelector(
  [getData, getSelectedDateRange, getCustomDateRange, getFilters, getFiltersFlat],
  (mapData, dateRange, customDateRange, filters, filtersFlat) => {
    if (mapData && filters && filtersFlat) {
      const styledData = annotateStyleAndFilterData(mapData, filtersFlat, dateRange, customDateRange);
      return {...styledData, ...annotateVisibilityForTree(styledData, filters)};
    }
    return {};
  }
);

export const getVisibleMapDataList = createSelector(
  [getMapData, getFiltersFlatList],
  (mapData, filtersList) => {
    const sortedMapData = filtersList.map(({id}) => mapData[id]);
    return sortedMapData.filter(data => data.visible);
  }
);

export const getHighlightedElements = createSelector(
  [getHighlight],
  highlights => {
    const layers = {};
    Object.values(highlights).forEach(({layerId, itemId}) => {
      if (!layers[layerId]) {
        layers[layerId] = {};
      }
      layers[layerId][itemId] = true;
    });
    return layers;
  }
);

export const getLoading = createSelector(
  [getVisibleMapDataList, getStreetView],
  (mapData, streetView) => {
    let loading = false;
    mapData.forEach(dataType => {
      if (dataType.checked && dataType.url && dataType.loading && !streetView.visible) {
        loading = true;
      }
    });
    return loading;
  }
);

export const getMouseSelectionEnabled = createSelector(
  [getDrawingMode, getDrawingComplete, getMeasureToolActive],
  (drawingMode, drawingComplete, measureActive) => !((drawingMode && !drawingComplete) || measureActive)
);

export const getDrawingActive = createSelector(
  [getDrawingMode, getDrawingComplete],
  (mode, complete) => Boolean(mode) && !complete
);

export const getDrawingSizeDisplay = createSelector(
  [getDrawingSize],
  size => size && getAreaDisplay(size) || null
);

export const getMeasureToolLengthDisplay = createSelector(
  [getMeasureToolLength],
  length => length && getLengthDisplay(length) || null
);


export const getDisclaimerContents = createSelector(
  [getDisclaimer, getDisclaimerText],
  (showDisclaimer, text) => showDisclaimer && text || null
);
