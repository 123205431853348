import React, { Component } from 'react';

import styles from './mobile-no-results.scss';

class MobileNoResults extends Component {
  render() {
    return (
      <div className={styles.container}>No results within the boundaries</div>
    );
  }
}

export default MobileNoResults;
