import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';

const LargeMedia = ({ children }) => (
  <MediaQuery minDeviceWidth={768}>
    {matches => matches ? (children || null) : null}
  </MediaQuery>
);

LargeMedia.propTypes = {
  children: PropTypes.node.isRequired
};

export default LargeMedia;
