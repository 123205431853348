/* eslint-disable react/jsx-no-bind */
import React, { Fragment, memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { closeLegend } from '@actions/ui-actions';
import Drawer from '@material-ui/core/Drawer';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import LargeMedia from '@shared/large-media';
import SmallMedia from '@shared/small-media';
import MobileCarousel from './mobile-carousel';
import styles from './sub-legend.scss';

const itemsAsLayers = items => items.map(([label, icon], index) => ({
  id: index,
  uiStyle: {
    showInLegend: true,
    legendIcon: icon,
    legendLabel: label
  }
}));

const SubLegend = ({ items, label }) => {
  const dispatch = useDispatch();

  const [popover, setPopover] = useState(null);

  const onCloseLegend = () => dispatch(closeLegend());

  const onInfoClick = event => {
    event.stopPropagation();
    event.preventDefault();
    setPopover(event.currentTarget);
  };

  const splitItems = [[], []];
  if (items) {
    let [...remaining] = items;
    while (remaining && remaining.length > 0) {
      const [even, odd, ...rest] = remaining;
      remaining = rest;
      splitItems[0].push(even);
      splitItems[1].push(odd);
    }
    if (!splitItems[1]) {
      splitItems[1].pop();
    }
  }

  return (
    <Fragment>
      <Icon onClick={onInfoClick} classes={{root: styles.subLegendIcon}}>
        help
      </Icon>
      <LargeMedia>
        <Popover
          open={Boolean(popover)}
          anchorEl={popover}
          onClose={() => setPopover(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <div className={styles.mapLegend} >
            <div className={styles.title}>{label}</div>
            <div className={styles.legend}>
              {splitItems.map((listItems, listIndex) => (
                <List key={listIndex} classes={{root: styles.legendColumn}}>
                  {listItems.map(([itemLabel, icon], index) => (
                    <ListItem key={index} classes={{root: styles.legendItem}}>
                      <ListItemIcon>
                        {icon && <img src={icon} className={styles.legendIcon} alt="" />}
                      </ListItemIcon>
                      <ListItemText classes={{root: styles.legendText}} primary={itemLabel} />
                    </ListItem>
                  ))}
                </List>
              ))}
            </div>
          </div>
        </Popover>
      </LargeMedia>
      <SmallMedia>
        <Drawer
          open={Boolean(popover)}
          anchor="bottom"
          onClose={() => setPopover(null)}
          ModalProps={{
            classes: {root: styles.subModal},
            hideBackdrop: true,
            disableBackdropClick: true
          }}
          classes={{paper: styles.subDrawer}}
        >
          <div className={styles.slideHeader}>
            <IconButton className={styles.slideBackButton} onClick={() => setPopover(null)}>
              <Icon>arrow_back</Icon>
            </IconButton>
            <div className={styles.slideTitle}>
              {label}
            </div>
            <IconButton className={styles.slideCloseButton} onClick={onCloseLegend}>
              <Icon>close</Icon>
            </IconButton>
          </div>
          <MobileCarousel layers={itemsAsLayers(items)} />
        </Drawer>
      </SmallMedia>
    </Fragment>
  );
};

SubLegend.propTypes = {
  items: PropTypes.array,
  label: PropTypes.string
};

export default memo(SubLegend);
